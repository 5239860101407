import { EventHandler } from '@n7-frontend/core';

export class DownloadModelSimulationLayoutEH extends EventHandler {

  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'download-model-simulation-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'model-simulation-download-setup-form.inputChange':
          this.dataSource.onSetupFormInputChange(event.payload);
          break;

        case 'model-simulation-download-setup-form.datePickerChange':
            event.payload.id='currentSelectedDate';
            this.dataSource.onSetupFormInputChange(event.payload);
            break;

        case 'model-simulation-download-params-selection.element-click':
          this.dataSource.onParamElementClick(event.payload);
          break;

        case 'model-simulation-download-static-simulation-form.inputChange':
            this.dataSource.onStaticFormInputChange(event.payload);
            break;

        case 'model-simulation-download-dynamic-simulation-table.inputChange':
            this.dataSource.onDynamicTableInputChange(event.payload);
            break;

        default:
          break;
      }
    });

  }

}