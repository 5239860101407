import { EventHandler } from '@n7-frontend/core';

export class ModalReorderWidgetsEH extends EventHandler {
  public listen(){
    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'modal-reorder-widgets.click':
          if(event.payload === 'reorder'){
            this.emitOuter('reorder-click');
          } else {
            this.dataSource.onDismiss();
          }
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'home-layout.open-reorder-widgets-modal':
          this.dataSource.onOpen();
          break;

        case 'home-layout.close-reorder-widgets-modal':
            this.dataSource.onDismiss();
            break;

        default:
          break;
      }
    });
  }
}