export const GET_CHART_COMPARE_DATA_MOCK = {
  "data": {
    "getCompareChartWithValues": {
      "id": "rbos-CHART-pipj",
      "name": null,
      "mode": "COMPARE",
      "startDate": "2019-08-10T00:00:00.000Z",
      "endDate": "2019-08-16T00:00:00.000Z",
      "compareStartDate": "2019-07-10T00:00:00.000Z",
      "compareEndDate": "2019-07-16T00:00:00.000Z",
      "parameters": [
        {
          "id": "ing-ph",
          "section": {
            "id": "ingresso_industriale"
          }
        }
      ],
      "paramValues": [
        {
          "date": "2019-08-10T00:00:00.000Z",
          "values": [
            1199.9649147249422
          ]
        },
        {
          "date": "2019-08-11T00:00:00.000Z",
          "values": [
            1777.3468797992498
          ]
        },
        {
          "date": "2019-08-12T00:00:00.000Z",
          "values": [
            2315.3424597071125
          ]
        },
        {
          "date": "2019-08-13T00:00:00.000Z",
          "values": [
            2973.3200695101896
          ]
        },
        {
          "date": "2019-08-14T00:00:00.000Z",
          "values": [
            3635.853565735266
          ]
        },
        {
          "date": "2019-08-15T00:00:00.000Z",
          "values": [
            4201.51076706491
          ]
        },
        {
          "date": "2019-08-16T00:00:00.000Z",
          "values": [
            4769.018371304981
          ]
        }
      ],
      "compareParamValues": [
        {
          "date": "2019-08-03T00:00:00.000Z",
          "values": [
            1199.9649147249422
          ]
        },
        {
          "date": "2019-08-04T00:00:00.000Z",
          "values": [
            1793.976444441024
          ]
        },
        {
          "date": "2019-08-05T00:00:00.000Z",
          "values": [
            2467.0437215851143
          ]
        },
        {
          "date": "2019-08-06T00:00:00.000Z",
          "values": [
            3006.9174015373324
          ]
        },
        {
          "date": "2019-08-07T00:00:00.000Z",
          "values": [
            3694.5236324619646
          ]
        },
        {
          "date": "2019-08-08T00:00:00.000Z",
          "values": [
            4250.220955634002
          ]
        },
        {
          "date": "2019-08-09T00:00:00.000Z",
          "values": [
            4769.018371304981
          ]
        }
      ]
    }
  }
};