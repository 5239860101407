export const GET_CHART_NORMAL_DATA_MOCK = {
  "data": {
    "getChartWithValues": {
      "id": "cin6-CHART-cmmz",
      "name": "Solidit totalmente sospesi",
      "startDate": "2019-08-10T00:00:00.000Z",
      "endDate": "2019-08-16T00:00:00.000Z",
      "mode": "NORMAL",
      "parameters": [
        {
          "id": "civ-ptot",
          "section": {
            "id": "ingresso_civile"
          }
        }
      ],
      "paramValues": [
        {
          "date": "2019-08-10T00:00:00.000Z",
          "values": [
            650.9043054816724
          ]
        },
        {
          "date": "2019-08-11T00:00:00.000Z",
          "values": [
            671.2483452760677
          ]
        },
        {
          "date": "2019-08-12T00:00:00.000Z",
          "values": [
            643.6835356671622
          ]
        },
        {
          "date": "2019-08-13T00:00:00.000Z",
          "values": [
            677.3855062812532
          ]
        },
        {
          "date": "2019-08-14T00:00:00.000Z",
          "values": [
            743.5312427949414
          ]
        },
        {
          "date": "2019-08-15T00:00:00.000Z",
          "values": [
            845.330091026741
          ]
        },
        {
          "date": "2019-08-16T00:00:00.000Z",
          "values": [
            948.3715132377187
          ]
        }
      ]
    }
  }
}