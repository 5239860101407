import { DataSource } from '@n7-frontend/core';

export class ModelSimulationDownloadSetupFormDS extends DataSource {

  private _datepicker;

  protected transform(state){
    if(!state) return;
    let data = state['setup-form-step'];
    data.datePickerConfig = {
      _setDatepicker: (datepicker) => this._datepicker = datepicker,
      _elementId: 'datepicker',
      icon: 'n7-icon-angle-down',
      options: {
        dateFormat: 'd F Y',
        defaultDate: data.inputs.currentSelectedDate
      },
    };
    return data;
  }
}
