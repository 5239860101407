import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutBuilder } from '@n7-frontend/core';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { ChartLayoutEH } from './chart-layout.eh';
import { ChartLayoutDS } from './chart-layout.ds';
import { Utils, Apollo } from '@app/providers';
import { combineLatest } from 'rxjs/operators';

@Component({
  selector: 'chart-layout',
  templateUrl: './chart-layout.html'
})
export class ChartLayoutComponent implements OnInit {
  public lb = new LayoutBuilder('chart-layout');
  private widgets = [
    { id: 'chart' },
    { id: 'chart-legend' },
    { id: 'chart-select-filter' },
    { id: 'chart-datepicker' },
    { id: 'compare-select-filter', hasStaticData: true },
    { id: 'chart-datepicker-compare' },
    { id: 'chart-actions', hasStaticData: true },
    { id: 'chart-alerts-table' },
  ];

  private labelKeys = {
    'model': 'i18n.model',
    'diff': 'i18n.diff',
    'alarm_text': 'i18n.chart_actions.alarm_text',
    'alarm_modal_title': 'i18n.alarm_create.title',
    'alarm_modal_description': 'i18n.alarm_create.description',
    'alarm_modal_save': 'i18n.alarm_create.save',
    'alarm_modal_success_msg': 'i18n.alarm_create.success_msg',
    'alarm_modal_error_msg': 'i18n.alarm_create.error_msg',
    'alerts_table': 'i18n.alerts_table',
  };

  constructor(
    private utils: Utils,
    private apollo: Apollo,
    private route: ActivatedRoute,
  ){}

  ngOnInit(){
    // on ready
    this.lb.ready$
      .pipe(
        combineLatest(
          this.route.paramMap, 
          this.route.data, 
          this.utils.getTranslations$(this.labelKeys)
        )
      ).subscribe(([, params, routeData, labels]) => {
      const sectionId = params.get('sectionId');
      const elementId = params.get('elementId');
      const chartId = params.get('chartId');
      const source = routeData.source;
      // and utils to make api calls
      this.lb.eventHandler.emitInner('init', {
        utils: this.utils,
        apollo: this.apollo,
        sectionId,
        elementId,
        chartId,
        source,
        labels,
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new ChartLayoutDS(),
      eventHandler: new ChartLayoutEH(),
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }
}