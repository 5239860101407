import { EventHandler } from '@n7-frontend/core';

export class ElementsAccordionEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({type, payload}) => {
      switch(type){
        case 'elements-accordion.click':
          this.dataSource.onClick(payload);
          break;

        case 'elements-accordion.element-click':
          this.dataSource.onElementClick(payload);
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(({type, payload}) => {
      switch(type){
        case 'customized-view-layout.modal-click':
          if(payload === 'dismiss'){
            this.dataSource.onModalDismiss();
          } else {
            this.emitOuter('save', this.dataSource.getSelectedElements());
          }
          break;

        default:
          break;
      }
    });
  }
}