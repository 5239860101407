import { EventHandler } from '@n7-frontend/core';

export class ModelSimulationDownloadDynamicSimulationTableEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'model-simulation-download-dynamic-simulation-table.inputChange':
          this.emitOuter('inputChange',event.payload);
          break;

        default: 
          break;
      }   
    });
  }
}
