import { Component, Input } from "@angular/core";

export interface ITitrimetroOldInputsData {
  date: number;
  author: string;
  sections: Array<{
    name: string,
    data: Array<{
      field: string,
      value: number,
      unit: string
    }>;
  }>;
 classes?: string;
 payload?: any;
 _meta?: any;
 icon?: string;
}

@Component({
  selector: "iwt-titrimetro-input-old-inputs",
  templateUrl: "./titrimetro-input-old-inputs.html"
})
export class IWTTitrimetroInputOldSettings {
  @Input() data: ITitrimetroOldInputsData;
  @Input() emit: any;

  onClick(payload: any) {
    if (!this.emit) return;
    this.emit("click", payload);
  }

  onIconClick(payload: any) {
    if (!this.emit) return;
    this.emit("iconClick", payload);
  }
}
