import { DataSource } from '@n7-frontend/core';

export class UserMenuDS extends DataSource {
  protected transform(data) {
    return {
      items: [{
        label: 'i18n.user_menu.alert_list',
        icon: 'n7-icon-file',
        payload: '/list-alerts'
      }, {
        label: 'i18n.user_menu.manage_alerts',
        icon: 'n7-icon-bell',
        payload: '/manage-alerts'
      }, {
        label: 'i18n.user_menu.model_simulation',
        icon: 'n7-icon-file',
        payload: '/model-simulations'
      }, /* {
        label: 'i18n.user_menu.titrimetro_form',
        icon: 'n7-icon-edit',
        payload: '/titrimetro-input'
      }, {
        label: 'i18n.user_menu.analysis_form',
        icon: 'n7-icon-file-excel',
        payload: '/upload-data-analysis'
      }, {
        label: 'i18n.user_menu.model_download',
        icon: 'n7-icon-download',
        payload: '/download-model-simulation'
      }, {
        label: 'i18n.user_menu.model_form',
        icon: 'n7-icon-upload',
        payload: '/upload-model-simulation'
      }, */ {
        label: 'i18n.user_menu.exit',
        icon: 'n7-icon-arrow-right',
        payload: 'logout'
      }]
    }
  }
}
