export const GET_TITRIMETO_INPUT_OLD_DATA_HISTORY_MOCK = {
  "data": {
    "getOldTitrimeterData": [
      {
        "id": "uifc-old_titri-aj5g",
        "date": "2018-07-31T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.6903786912035876,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.4682349563170196,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.495621390133932,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.297362531375014,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "f8zh-old_titri-i0mn",
        "date": "2018-07-30T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.856466081765108,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.721963421270031,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.106827783854264,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.0633968262549,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "exeo-old_titri-sdiq",
        "date": "2018-07-29T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.35461745352884,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.130563731113604,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.231293050140236,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.460600665732446,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "ddhy-old_titri-zhbk",
        "date": "2018-07-28T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.793495067015886,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.248976078041972,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.45221391549461,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.828556193712766,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "7exg-old_titri-yoe5",
        "date": "2018-07-27T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.682161767963969,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.100416990838982,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.122557362342034,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.405746207744848,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "pcsj-old_titri-fugq",
        "date": "2018-07-26T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.061518708587307,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.386887432779005,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.923168672692842,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.340178149325206,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "jyfd-old_titri-6n2z",
        "date": "2018-07-25T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.692286742034796,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.382402631034906,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.878472349204305,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.1878987761038813,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "vph8-old_titri-ap1k",
        "date": "2018-07-24T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.606216345162043,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.805569483148473,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.803921859312126,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.9630274632169913,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "wm3t-old_titri-byyg",
        "date": "2018-07-23T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.003642770944793,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.705706524537923,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.012450444568266,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.39604086029767,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "uuup-old_titri-u3vc",
        "date": "2018-07-22T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.700846036082195,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.231535053430841,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.652084091888032,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.763021090294519,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "yhof-old_titri-j8bt",
        "date": "2018-07-21T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.64851799877444,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.883282578910038,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.1759880523209,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.9724530664805848,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "aay0-old_titri-i9wi",
        "date": "2018-07-20T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.418483416140344,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.475618629933907,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.119744249108187,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.422037041589306,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "lp4v-old_titri-1a7q",
        "date": "2018-07-19T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.90372261598006,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.179469066149945,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.356544985375406,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.6669231236066935,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "eud2-old_titri-ara1",
        "date": "2018-07-18T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.460581412190487,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.231267553451623,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.633198003193542,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.5988839581874075,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "yegy-old_titri-znp9",
        "date": "2018-07-17T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.342022821032528,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.101195188272264,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.849980944539269,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.115824804286259,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "87rj-old_titri-qx9d",
        "date": "2018-07-16T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.189893140926754,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.3282216628358263,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.992246831478125,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.769916261067996,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "av4x-old_titri-8cby",
        "date": "2018-07-15T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.017994355028552,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.6080836496320141,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.476297387862942,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.628104723954124,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "j2il-old_titri-wd1x",
        "date": "2018-07-14T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.862127765152679,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.8329814617357,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.766530271673158,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.824467815996561,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "42k2-old_titri-jefi",
        "date": "2018-07-13T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.309329836593584,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.81144615090755,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.136213506347459,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.042758947757871,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "uxt5-old_titri-7b3p",
        "date": "2018-07-12T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.2901880051883534,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.448602248520283,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.381772794895813,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.409749389073413,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "x03j-old_titri-cchy",
        "date": "2018-07-11T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.998541667973956,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.124420417751947,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.528888271832197,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.518727983166733,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "y09l-old_titri-q6tu",
        "date": "2018-07-10T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.149774897394419,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.70210632316265,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.512146837149736,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.9174898938079,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "axkm-old_titri-jhgp",
        "date": "2018-07-09T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.474233418177619,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.7023753908103574,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.94382430396655,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.269910416437106,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "cndk-old_titri-rryh",
        "date": "2018-07-08T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.744001654360106,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.305247197562146,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.896786403250491,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.387213313562876,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "sbu6-old_titri-dn40",
        "date": "2018-07-07T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.913236772679134,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.577085048309309,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.833321404570254,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.017479320465412,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "edi2-old_titri-k27j",
        "date": "2018-07-06T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.270884030070532,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.470038809090106,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.845366662060407,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.36105951440738,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "smat-old_titri-aivr",
        "date": "2018-07-05T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.038646023890154,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.4831376262059,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.3051081622018295,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.691684192548642,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "e5xn-old_titri-w7cm",
        "date": "2018-07-04T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.553125621310814,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.464084767914354,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.84352785945697,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.5373058965422155,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "etlf-old_titri-lnhq",
        "date": "2018-07-03T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.9110437728868899,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.738042628202685,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.770243539524204,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.43492796732749,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "nmgj-old_titri-gfnl",
        "date": "2018-07-02T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.411065199154983,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.918837451564686,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.7759754573457074,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.215598815793228,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "jo9l-old_titri-w9o7",
        "date": "2018-07-01T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.406017300888944,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.809199345372853,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.595937006672495,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.110414655217879,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "pw00-old_titri-wims",
        "date": "2018-06-30T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.956486694545668,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.41045666350458,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.946384853242868,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.23365459286481,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "9a7m-old_titri-p0ce",
        "date": "2018-06-29T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.7547928320531554,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.185881736637874,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.3875420734222188,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.901056947311318,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "47jz-old_titri-y8vm",
        "date": "2018-06-28T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.464923713616951,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.349160814423055,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.099046716365865,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.4241335602469696,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "oi56-old_titri-ixei",
        "date": "2018-06-27T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.095616890119212,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.99483728591185,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.886327020515056,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.286123714374394,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "unbf-old_titri-ygoy",
        "date": "2018-06-26T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.60341739446114,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.733951380539304,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.327946534477886,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.189213908234722,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "y77j-old_titri-iq7x",
        "date": "2018-06-25T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.7987608690039676,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.739021301551318,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.77358239371537,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.5736055754564373,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "li2p-old_titri-q2ul",
        "date": "2018-06-24T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.162119625958434,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.544207546419232,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.725405703208565,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.8655677435292475,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "wnr7-old_titri-vrlm",
        "date": "2018-06-23T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.926980978023439,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.863109947973007,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.1966338901414963,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.9280782541588044,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "qpyl-old_titri-4ksl",
        "date": "2018-06-22T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.5322132661045598,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.154732766753762,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.85036922188197,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.602669319658842,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "d709-old_titri-cr2g",
        "date": "2018-06-21T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.62182099688287,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.881186342870059,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.71821682647386,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.934535786086428,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "eyky-old_titri-yglq",
        "date": "2018-06-20T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.0799893980734256,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.6850067202228534,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.408997033000306,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.751333816835345,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "79rb-old_titri-hwd2",
        "date": "2018-06-19T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.572745340403843,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.269595472069032,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.875501900413993,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.397417393379406,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "ahfj-old_titri-fev0",
        "date": "2018-06-18T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.6768963794074176,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.510016264478054,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.0304680715462666,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.969849831144163,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "ve0s-old_titri-thll",
        "date": "2018-06-17T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.842719031040485,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.6341059754682257,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.425752368024403,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.010696521395849,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "zgiz-old_titri-fx57",
        "date": "2018-06-16T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.165575686026679,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.533480610659796,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.11671698654649,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.519199509491315,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "p9ax-old_titri-6hbh",
        "date": "2018-06-15T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.67460992031658,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.306241062481906,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.268412810330105,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.694177764910244,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "0i8n-old_titri-kp0a",
        "date": "2018-06-14T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.897542812082207,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.603306315793828,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.514758443112043,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.023554567339726,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "3vza-old_titri-8m40",
        "date": "2018-06-13T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.3829255232850155,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.979329929006285,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.45079794215436,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.336259755122747,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "y2zw-old_titri-ep91",
        "date": "2018-06-12T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.2399981792070385,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.47966895466566,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.315774430093855,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.898848355889513,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "lqee-old_titri-ue6f",
        "date": "2018-06-11T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.863314514377237,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.795516961115048,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.062697483235537,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.591017171792942,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "zoia-old_titri-dkvr",
        "date": "2018-06-10T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.762407254622038,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.1731024073968976,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.254674515375875,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.591911302250752,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "pag8-old_titri-f3gl",
        "date": "2018-06-09T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.401268637420618,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.03524724420926,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.434783923497431,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.2236764408879,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "3pib-old_titri-1652",
        "date": "2018-06-08T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.239591299805845,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.137943667555534,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.113158312105933,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.593355246461601,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "wk16-old_titri-l7kz",
        "date": "2018-06-07T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.1276813164624637,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.656551191761094,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.335411405958702,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.880230502395417,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "e93o-old_titri-wtma",
        "date": "2018-06-06T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.604586031826876,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.351523917309443,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.663668634688571,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.445565215597355,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "s1s7-old_titri-5eg7",
        "date": "2018-06-05T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.626985349803007,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.288617482507802,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.652871839428284,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.174487918570573,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "xrep-old_titri-8at8",
        "date": "2018-06-04T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.587160138255882,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.270118570987375,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.995135142658227,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.42061695380135,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "fugf-old_titri-uvag",
        "date": "2018-06-03T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.128607131787358,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.865879068709662,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.474255393095666,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.14396938791767,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "488f-old_titri-qd2b",
        "date": "2018-06-02T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.24106629855733,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.706786956730934,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.57118199447876,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.813528660845646,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "q0zu-old_titri-a7us",
        "date": "2018-06-01T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.431724377538956,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.40342951176278,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.972529289188728,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.828643843957408,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "wn4a-old_titri-nkrx",
        "date": "2018-05-31T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.272038547411496,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.3000788423312408,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.4729225298106683,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.442921927735723,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "do74-old_titri-xffc",
        "date": "2018-05-30T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.86938226090995,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.46186006477458,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.384836558989933,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.128480166609021,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "12kb-old_titri-8q6q",
        "date": "2018-05-29T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.553014223307886,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.671133294237396,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.081548120267515,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.748638821586427,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "3fww-old_titri-az2w",
        "date": "2018-05-28T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.778317253061303,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.025626667808744,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.774606412152152,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.401550668193851,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "2jzs-old_titri-fojv",
        "date": "2018-05-27T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.2477361079876887,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.183240018642095,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.297300783570226,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.7234711927455577,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "wbgm-old_titri-lvrd",
        "date": "2018-05-26T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.30539148741372,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.6827657578359467,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.690994785809677,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.120408076255537,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "eael-old_titri-m26f",
        "date": "2018-05-25T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.841236925027137,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.948197862437347,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.389332524055773,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.0155910341305,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "5yt2-old_titri-hdez",
        "date": "2018-05-24T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.14557128509683,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.858454396723891,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.742595556182798,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.783248513518946,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "0cyw-old_titri-mb9p",
        "date": "2018-05-23T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.736111635330051,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.547697755490656,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.942569182738914,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.234645787064521,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "80zb-old_titri-lb5r",
        "date": "2018-05-22T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.366120826585722,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.492702688955941,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.221780442866106,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.343548151114693,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "w9zy-old_titri-la6m",
        "date": "2018-05-21T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.214892927968503,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.6788242865774543,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.460694453152368,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.940445302785598,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "365f-old_titri-gidx",
        "date": "2018-05-20T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.000843423563943,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.942662052488055,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.8772480653249684,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.1834847343912465,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "wdos-old_titri-hk9m",
        "date": "2018-05-19T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.2672950643126715,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.76473278134666,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.6282331399031573,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.452588393642,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "g2tl-old_titri-4800",
        "date": "2018-05-18T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.44716659722508,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.652176583102746,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.829980623955524,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.7766753064320446,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "mgmu-old_titri-clbq",
        "date": "2018-05-17T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.291734911196599,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.667010241829399,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.661506751496752,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.382494378084121,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "m6i3-old_titri-zc4c",
        "date": "2018-05-16T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.53867191136318,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.692905681275489,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.320339219264719,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.38844271502507,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "lywd-old_titri-cdkn",
        "date": "2018-05-15T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.136901152063855,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.975022127031709,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.294788662775114,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.81974456545774,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "b4p6-old_titri-7ezx",
        "date": "2018-05-14T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.451456429548884,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.245603232138029,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.568237919584681,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.979640204654999,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "xrz4-old_titri-di1g",
        "date": "2018-05-13T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.934457972917109,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.833696433308203,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.34750084784892,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.5420983504219326,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "m9po-old_titri-1in4",
        "date": "2018-05-12T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.105213763530672,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.709779695051719,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.52496645645838,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.92460546829438,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "xiq4-old_titri-b05j",
        "date": "2018-05-11T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.3143023868328396,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.0093932143552,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.1904411391503924,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.99683040941381,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "nyhb-old_titri-6unv",
        "date": "2018-05-10T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.151589804445376,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.70302628787846,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.046004500731357,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.964859174310595,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "77wk-old_titri-hpa9",
        "date": "2018-05-09T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.788630585690797,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.528483691631415,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.556385088491135,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.664311161841563,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "0955-old_titri-gboa",
        "date": "2018-05-08T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.873431353376986,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.149249572621116,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.1214789131123277,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.791786658274694,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "twj3-old_titri-x5vi",
        "date": "2018-05-07T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.581793205713029,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.812844034565021,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.705750540367124,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.78107838559426,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "3zbg-old_titri-o4vy",
        "date": "2018-05-06T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.732248872713194,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.447484456414216,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.849263839658583,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.174950390997195,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "psii-old_titri-u9bm",
        "date": "2018-05-05T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.234002516603434,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.74861341192843,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.590077332890875,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.35106152892873,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "59wl-old_titri-xcyd",
        "date": "2018-05-04T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.027744337528238,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.033357057201046,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.778277438339353,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.845542296864458,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "wwo6-old_titri-zfpw",
        "date": "2018-05-03T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.618502289524642,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.032656144226639,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.55640338786604,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.089434421643945,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "oaj3-old_titri-lkcq",
        "date": "2018-05-02T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.530370824660869,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.6964460517343807,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.31427194856051,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.405757695617611,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "5sx2-old_titri-zmd0",
        "date": "2018-05-01T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.190954211425709,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.0175618719263078,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.637884807215469,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.599446605843701,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "bqye-old_titri-ytdn",
        "date": "2018-04-30T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.9587351512815405,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.095568740156011,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.9957277659298325,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.380442039921164,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "cojw-old_titri-ub8b",
        "date": "2018-04-29T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.34580740104353,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.9986002846131377,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.720300560981265,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.35149310439267,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "thzw-old_titri-ry7n",
        "date": "2018-04-28T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.977923883144708,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.635567084222277,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.978377396208721,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.917730476210899,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "uhp0-old_titri-1o7c",
        "date": "2018-04-27T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.344103654564984,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.244485721403136,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.0048336473027217,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.861307582831968,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "f4n4-old_titri-50s6",
        "date": "2018-04-26T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.531490353719031,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.5987632748542024,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.229460172005711,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.586398765990243,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "99iu-old_titri-hp7k",
        "date": "2018-04-25T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.457869434078255,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.547168820555634,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.528572529834383,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.094390074152379,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "4cd7-old_titri-4dkh",
        "date": "2018-04-24T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.28170433034515,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.037805388082334,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.2484186133989237,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.824291773348111,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "0558-old_titri-dl9i",
        "date": "2018-04-23T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.356208548791178,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.142129147493194,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.66905398667605,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.687451692375635,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "c4cl-old_titri-neqz",
        "date": "2018-04-22T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.012577548292487,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.379685737636047,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.5886338800605975,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.761268448885993,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "c21h-old_titri-zz0j",
        "date": "2018-04-21T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.7699242916216331,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.052154508345906,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.600958256419672,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.9654361608461772,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "fd5p-old_titri-v63o",
        "date": "2018-04-20T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.7704270608652908,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.493314722066948,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.559663506158842,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.2976662452390504,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "yl8r-old_titri-af3k",
        "date": "2018-04-19T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.333399063995694,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.290584994366979,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.6074906946378178,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.267129414807535,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "39ku-old_titri-85sm",
        "date": "2018-04-18T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.3989548814869806,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.530932096160464,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.172247748079272,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.136543395937153,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "ibo9-old_titri-s7ac",
        "date": "2018-04-17T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.111578785545856,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.862611120609957,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.9007666164183945,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.478371672597023,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "1ywk-old_titri-u3j1",
        "date": "2018-04-16T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.6281654679067228,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.424253507184876,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.775908365665944,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.7602837728314182,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "439m-old_titri-7x9m",
        "date": "2018-04-15T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.824919723091575,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.80282226626127,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.259951310029951,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.5777100647926288,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "rfip-old_titri-n72m",
        "date": "2018-04-14T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.369546405023124,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.7500581348382855,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.505384103968963,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.363223589721909,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "aixz-old_titri-5tn2",
        "date": "2018-04-13T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.782449666558772,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.083019590473345,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.09404134896498,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.810125432091546,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "skk9-old_titri-ncx9",
        "date": "2018-04-12T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.7174375067846235,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.257794546904485,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.292824214711283,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.54371737290647,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "pthp-old_titri-69wm",
        "date": "2018-04-11T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.245801720665306,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.414305837308993,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.54597766688792,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.905169072896278,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "yxvr-old_titri-98tn",
        "date": "2018-04-10T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.234401999116313,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.238548412295959,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.50588125124444,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.940131449928398,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "vu97-old_titri-djp6",
        "date": "2018-04-09T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.5678237771046994,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.546656483440382,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.025825877325587,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.24150862338919,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "4l2m-old_titri-5qhi",
        "date": "2018-04-08T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.68418483832116,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.229316363204829,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.77410518297677,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.209344549599459,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "srwf-old_titri-sebw",
        "date": "2018-04-07T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.684201155438654,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.12543669915987,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.887365195756718,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.482080342120245,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "wy1u-old_titri-x0yh",
        "date": "2018-04-06T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.165107959747667,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.983739046313093,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.026568857453606,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.602595891058362,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "jp9p-old_titri-0k0w",
        "date": "2018-04-05T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.073094556594883,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.439790348362766,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.2033470913718,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.605373104987118,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "9mfz-old_titri-o240",
        "date": "2018-04-04T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.235742900341629,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.900605649161523,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.930481148965252,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.5048077643026065,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "1wvt-old_titri-mpyw",
        "date": "2018-04-03T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.404920128528991,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.204871784477869,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.523514292263986,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.520476463139805,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "z6eg-old_titri-al60",
        "date": "2018-04-02T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.4109537791034,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.140278089827513,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.315026511556194,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.726379979812081,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "vedn-old_titri-kovx",
        "date": "2018-04-01T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.761390970088972,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.037061496827038,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.20160642196863,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.781173187381196,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "gm6b-old_titri-2y1r",
        "date": "2018-03-31T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.319981298650973,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.669499221027246,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.519395395253401,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.536365086235127,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "sonc-old_titri-a13t",
        "date": "2018-03-30T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.384412408619705,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.52107389105652,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.607022046754274,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.2522205118787495,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "4ahb-old_titri-cqxe",
        "date": "2018-03-29T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.9819529525865542,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.509177124228284,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.365999869230972,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.446270620260574,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "sl7v-old_titri-kems",
        "date": "2018-03-28T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.107560630297879,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.751658276690833,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.069021258443385,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.486649853822001,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "2t2y-old_titri-mjsi",
        "date": "2018-03-27T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.40440550264759,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.331499365483449,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.557075163783529,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.1293856374894182,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "t532-old_titri-7sbz",
        "date": "2018-03-26T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.26166920274892,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.733158092327914,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.1603622616409264,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.6249811832980057,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "qwz9-old_titri-h5k3",
        "date": "2018-03-25T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.83810238035365,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.30352667653052,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.4266220396922038,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.55541745865588,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "4fy2-old_titri-1u6l",
        "date": "2018-03-24T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.371684234816451,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.105655252697625,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.459589611297514,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.150047384376983,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "y4z2-old_titri-p0an",
        "date": "2018-03-23T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.558954863247443,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.420637182141792,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.997588177588183,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.678578175068707,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "pxzo-old_titri-g3hs",
        "date": "2018-03-22T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.570650268951795,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.07160159520795,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.975163508124965,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.085682507479987,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "snb8-old_titri-o6tb",
        "date": "2018-03-21T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.360364137193898,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.418237760387818,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.386507165858553,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.653960788365784,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "jy84-old_titri-27ch",
        "date": "2018-03-20T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.947099609398983,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.454398879400397,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.511866470946456,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.035015755658197,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "99y9-old_titri-yjuk",
        "date": "2018-03-19T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.5851802311214875,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.317810434442311,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.0185201134804944,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.751674107720336,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "caoq-old_titri-69kn",
        "date": "2018-03-18T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.952676772471406,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.031881421354104,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.864451706624324,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.535863765133732,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "isbm-old_titri-3xa8",
        "date": "2018-03-17T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.632257116191322,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.262862315248948,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.449716857033676,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.343213979453561,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "wt5m-old_titri-1zbn",
        "date": "2018-03-16T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.971659049149013,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.567621796569082,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.6726022758350796,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.861210828947179,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "g7z6-old_titri-7xl0",
        "date": "2018-03-15T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.713976684200404,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.499034735678348,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.478293691417964,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.127488367470352,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "n5rp-old_titri-j98h",
        "date": "2018-03-14T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.286819573376505,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.375984193232741,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.014748492681694,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.9429303721589175,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "u1wh-old_titri-vhe4",
        "date": "2018-03-13T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.200855142948573,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.151915213241871,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.0190577960005167,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.38924491375677,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "y49z-old_titri-u794",
        "date": "2018-03-12T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.8522223893361582,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.241570557756738,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.688046851783488,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.0139519758517346,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "xim5-old_titri-tvb6",
        "date": "2018-03-11T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.246606435756673,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.041131276170859,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.730304857130227,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.528029541753316,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "s9kz-old_titri-2tga",
        "date": "2018-03-10T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.757658634995646,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.231386345976011,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.7524755732986286,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.992286132956585,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "epvp-old_titri-cd5i",
        "date": "2018-03-09T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.6165214049830765,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.658424401652022,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.904223931616972,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.1165708464893913,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "kmce-old_titri-eayp",
        "date": "2018-03-08T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.7097785526353562,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.062062957328507,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.654537904701131,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.206760184118574,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "uynr-old_titri-2z0w",
        "date": "2018-03-07T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.806762106233625,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.420695543934196,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.321506903365444,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.40755445699129,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "ocwc-old_titri-k9o8",
        "date": "2018-03-06T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.532488655632582,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.049391035144681,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.2952777324580595,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.097801218270805,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "tfox-old_titri-hwnw",
        "date": "2018-03-05T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.795136555826595,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.732506622079972,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.8751543087786144,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.1447769645127286,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "ywzf-old_titri-4ow0",
        "date": "2018-03-04T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.4292523992169475,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.192162847606521,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.3012479053989496,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.337756558400717,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "nan5-old_titri-ykgz",
        "date": "2018-03-03T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.99523458975185,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.820647586275983,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.1078633327922445,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.220305467750197,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "38so-old_titri-s9ak",
        "date": "2018-03-02T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.668138482315895,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.302457284687208,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.268546966779525,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.008785950552564,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "h6vx-old_titri-x9ez",
        "date": "2018-03-01T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.080172511335032,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.0861389341740844,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.094470686664138,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.34961063434504,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "h73c-old_titri-z8u0",
        "date": "2018-02-28T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.32687801626829,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.9084135753652833,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.09165825809948,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.212687484968445,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "ffu3-old_titri-6aiw",
        "date": "2018-02-27T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.150954807277104,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.396356162284759,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.2762135295044459,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.437327588295497,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "cnu5-old_titri-xvk8",
        "date": "2018-02-26T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.074553223680596,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.172906235670837,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.027848054325995,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.748855177583053,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "z1ar-old_titri-om1e",
        "date": "2018-02-25T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.191744263585069,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.523335675431577,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.388275185737214,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.945580675364077,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "2f3f-old_titri-3ly1",
        "date": "2018-02-24T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.974900767938798,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.099866743131425,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.09330155018741,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.905425567095193,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "av85-old_titri-z997",
        "date": "2018-02-23T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.836031182254885,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.220193469377362,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.023714690771943,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.259949394131297,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "94tf-old_titri-ggnj",
        "date": "2018-02-22T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.627462305129136,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.7647752209712895,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.927980179434762,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.8923779339314515,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "iokm-old_titri-yuza",
        "date": "2018-02-21T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.104232049767361,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.740828659386382,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.93021120949661,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.670827953616705,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "gyvo-old_titri-ruc1",
        "date": "2018-02-20T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.8257579643401067,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.905489541984014,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.455704484057149,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.728486444919806,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "t5c9-old_titri-tm98",
        "date": "2018-02-19T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.80434200546947,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.61851583638369,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.492844218705915,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.300066921741785,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "zcq8-old_titri-za2o",
        "date": "2018-02-18T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.371317051143526,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.816862703692064,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.377259526921101,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.32270975698805,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "xana-old_titri-zko0",
        "date": "2018-02-17T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 10.334782610520765,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.24665862265119,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.856732499052059,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.56360050589578,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "mtru-old_titri-cm8h",
        "date": "2018-02-16T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.04171527548592,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 12.870125721638946,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.688013524411195,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.5550891267331295,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "8to7-old_titri-cmjq",
        "date": "2018-02-15T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.026461012743757,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.248670651566005,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.479219748598572,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.972073167384986,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "tc2i-old_titri-n1i9",
        "date": "2018-02-14T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.752627319194863,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.082901223872089,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.975663841520206,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.2528949762713375,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "ve50-old_titri-19ko",
        "date": "2018-02-13T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.786910616986296,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 4.340332176782818,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.420339428892648,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.360479979595915,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "6nna-old_titri-rs80",
        "date": "2018-02-12T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.013252915261619,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.874468840730518,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.7707258228231915,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 13.848472098180519,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "qxh4-old_titri-h1xa",
        "date": "2018-02-11T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 5.819721913534391,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.134191928250274,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.58602692933272,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 5.469951633900749,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "fk8f-old_titri-5288",
        "date": "2018-02-10T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 11.850090417072039,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.3991105721713564,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.2207270102572374,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.999330548351006,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "z2tf-old_titri-je5e",
        "date": "2018-02-09T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 6.887613781464509,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.088472017466943,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.8949307422406982,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.922175942460368,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "lf67-old_titri-f9f3",
        "date": "2018-02-08T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.29879325116914,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.1817819162794692,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.8678690544244034,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.062954224827548,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "leoo-old_titri-c0ne",
        "date": "2018-02-07T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 3.166459648131683,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 7.166966595315572,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 9.013207198657826,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.942914813319241,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "to0r-old_titri-izcm",
        "date": "2018-02-06T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 2.495730066657191,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 3.617216318182036,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.774719136968676,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.247129265924684,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "yv7d-old_titri-dqx2",
        "date": "2018-02-05T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 7.393782192887035,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 2.0122600675785054,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.683520430605068,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.498584482844121,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "fwdx-old_titri-toi9",
        "date": "2018-02-04T00:00:00.000Z",
        "author": "Alberto Mannucci",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 8.197377187829455,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 6.80858094175116,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 12.280893877558706,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.659335292933555,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "saf3-old_titri-75ck",
        "date": "2018-02-03T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.004667304923554,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 14.89512213482541,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.525219587523145,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 9.243921324233675,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "ap9j-old_titri-l6v4",
        "date": "2018-02-02T00:00:00.000Z",
        "author": "Giulio Andreini",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 4.3859632869984555,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 11.013813982949166,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 14.662054219826754,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 10.919595503054566,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      },
      {
        "id": "3bh3-old_titri-hbbq",
        "date": "2018-02-01T00:00:00.000Z",
        "author": "Francesco Spennati",
        "sections": [
          {
            "id": "ingresso_industriale",
            "label": "Ingresso industriale",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 13.388212226598977,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 8.136097156543213,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "label": "Ingresso civile",
            "fields": [
              {
                "id": "azoto_ammoniacale",
                "label": "Azoto ammoniacale",
                "value": 1.5484082031849549,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              },
              {
                "id": "azoto_totale_filtrato",
                "label": "Azoto totale filtrato",
                "value": 1.2641557620914572,
                "unit": {
                  "id": "MILLIGRAM_LITER",
                  "label": "mg/l"
                }
              }
            ]
          }
        ]
      }
    ]
  }
};