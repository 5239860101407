import { EventHandler } from '@n7-frontend/core';

export class ModelSimulationDownloadParamsSelectionEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'model-simulation-download-params-selection.element-click':
          this.emitOuter('element-click',event.payload);
          break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'download-model-simulation-layout.forward':
          if(event.payload && event.payload.sectionIdx==1)
            this.emitOuter('submit',this.dataSource.generateSubmitData());
          break;

          default:
          break;
      }
    });

  }
}
