import { LayoutDataSource } from '@n7-frontend/core';
import { map } from 'rxjs/operators';


export class UploadModelSimulationLayoutDS extends LayoutDataSource {
  private apollo: any;
  private translate: any;
  protected preloadedLabels = {
    upload_form_title: 'i18n.components.upload_model_simulation_form.title',
    upload_form_choose_button: 'i18n.components.upload_model_simulation_form.choose_button',
    upload_form_submit_button: 'i18n.components.upload_model_simulation_form.submit_button',
    upload_form_field_name_label: 'i18n.components.upload_model_simulation_form.field_name.label',
    upload_form_field_name_placeholder: 'i18n.components.upload_model_simulation_form.field_name.placeholder',
    upload_form_field_date_label: 'i18n.components.upload_model_simulation_form.field_date.label',
    upload_form_field_description_label: 'i18n.components.upload_model_simulation_form.field_description.label',
    upload_form_field_description_placeholder: 'i18n.components.upload_model_simulation_form.field_description.placeholder'
  };

  onInit(payload){
    this.apollo = payload.apollo;
    this.translate = payload.translate;
    this._loadTranslatedLabels();
  }




  protected _loadTranslatedLabels(){
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe(labels => {
      this.all().updateOptions({labels});
      this.one('model-simulation-upload-form').update({fileFormat: "tsv"});
    });
  }

  onUploadFormSubmit(payload){
    var fileName = payload.chosenFileName;
    if((typeof(fileName)=='undefined')||(fileName == null)||(fileName == '')) {
      this.showAlert(false,'',`Non è stato scelto alcun file, sceglierne uno e riprovare.`);
    } else {
      const file = payload.fileString.split(',')[1]
      const base64String = file.toString('base64');
      console.log("File", base64String)
      console.log('uploading...', {
        file: base64String,
        filename: 'sumo-model-simulation_' + payload.chosenFile.lastModified
      });
      // console.log("Payload:", payload)
      this.apollo.request$('uploadModelSimulationFile', {
        file: base64String,
        filename: 'sumo-model-simulation_' + payload.chosenFile.lastModified,
        simulationDate: payload.selectedDate.toISOString()
      }).subscribe(response => {
        console.log('file uploaded', response);
        this.showAlert(true,payload.chosenFileName);
      });
    }
  }


  showAlert(success:boolean,fileName: string,message?:any){
    var data;
    if(success){
      data = {
        text: `il file <strong>${fileName}</strong> è stato caricato correttamente.`,
        hasCloseButton: true,
        payload: "close",
        classes: "is-success"
      }
    } else {
      var text;
      if(message) text = message;
      else text = `
      Errore durante il caricamento del file <strong>${fileName}</strong>.`
      data = {
        text: text,
        hasCloseButton: true,
        payload: "close",
        classes: "is-error"
      }
    }
    this.one('model-simulation-upload-alert').update(data);
    //if(this.closeAlertTimeout!=null){
    //  clearTimeout(this.closeAlertTimeout);
    //  this.closeAlertTimeout=null;
    //}
    //this.closeAlertTimeout = setTimeout(() => {
    //  this.closeAlert();
    //  this.closeAlertTimeout = null;
    //},this.alertCloseTimeOutTime);
  }

  closeAlert(){
    this.one('model-simulation-upload-alert').update(null);
  }


}
