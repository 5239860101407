import { EventHandler } from '@n7-frontend/core';

export class DataAnalysisUploadFormEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'data-analysis-upload-form.click':
          this.emitOuter('click', event.payload);
          break;

        case 'data-analysis-upload-form.submit':
          var submitData = this.dataSource.createDataToSubmit();
          this.emitOuter('submit', submitData);
          break;

        case 'data-analysis-upload-form.inputChange':
            this.dataSource.onInputChange(event.payload);
            break;

        case 'data-analysis-upload-form.fileChange':
            this.dataSource.chosenFileToUpload(event.payload);
            break;

        case 'data-analysis-upload-form.selectChange':
            this.dataSource.onSelectChange(event.payload);
            break;
        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      // TODO
    });
  }
}
