import { EventHandler } from '@n7-frontend/core';

export class ModelSimulationsListEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({type, payload}) => {
      switch(type){
        case 'model-simulations-list.click':
          if(payload.source === 'header'){
            this.dataSource.onClick(payload);
          } else {
            this.emitGlobal('navigate', ['plant-section', payload.group, payload.id]);
          }
          break;
        default:
          break;
      }
    });
  }
}
