import { EventHandler } from '@n7-frontend/core';

export class ChartLegendEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      if(event.type.indexOf('legend.click') !== -1){
        const legendState = this.dataSource.onClick(event);
        this.emitOuter('change', legendState);
      }
    });

    this.outerEvents$.subscribe(event => {
      if(event.type === 'chart-layout.compare-chart-request'){
        this.dataSource.onChartRequest(event);  
      }

      if(event.type === 'chart-layout.chart-request'){
        this.dataSource.onChartRequest(event);  
      }
    });
  }
}