import { Component, Input } from '@angular/core';

export interface IChartLegendItemData {
  label: string,
  left?: {icon: string, color: string};
  right?: {icon: string, color: string, tooltip: string};
  classes?: string;
  payload?: any;
  _meta?: any;
}

export interface IChartLegendData {
  items: IChartLegendItemData[],
  classes?: string;
  _meta?: any;
}

@Component({
  selector: 'iwt-chart-legend',
  templateUrl: './chart-legend.html'
})
export class ChartLegend {
  @Input() data: IChartLegendData;
  @Input() emit: any;

  onClick(type, payload){
    if(!this.emit) return;
    this.emit(`click_${type}`, payload);
  }
}