import { Component, Input } from "@angular/core";

interface IMenuItem {
  label: string;
  icon: string;
  
  classes?: string;
  payload: any;
  _meta?: any;
}

export interface IPopoverMenuData {
  items: IMenuItem[];
  classes?: string;
}

@Component({
  selector: "iwt-popover-menu",
  templateUrl: "./popover-menu.html"
})
export class PopoverMenuComponent {
  @Input() data: IPopoverMenuData;
  @Input() emit: any;

  onClick(payload: any) {
    if (!this.emit) return;
    this.emit("click", payload);
  }
}
