export const getData = (mode?: 'overview' | 'single' | 'compare' | 'model') => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'];
  let elements = ["ph", "sed", "ss105", "ssv", "svi", "cod_tq", "cod_filtr"];
  if(mode === 'single'){
    elements = ["ph"];
  } else if(mode === 'compare' || mode === 'model'){
    elements = ["ph", `ph-${mode}`];
  }

  let values = [];
  months.forEach((month, monthIndex) => {
    let monthData = [];
    elements.forEach((el, index) => {
      if(mode === 'model' && index === 1 && monthIndex > 3) return;
      monthData.push({
        key: el,
        value: Math.round(Math.random() * (100 * (index+1)))
      });
    })
    values.push({
      x: month,
      data: monthData
    })
  });

  return { values };
}

export const GET_CHART_MULTIPLE_DATA_MOCK = {
  status: 'ok',
  mode: 'multiple',
  start_date: '2019-06-14',
  end_date: '2019-06-20',
  ...getData()
}