import { Component, Input } from '@angular/core';

export interface IModalAction {
  label: string;
  isDisabled?: boolean;
  buttonClasses?: string;
  payload?: any;
}

export interface IModalData {
  isVisible: boolean;
  header: {
    label: string,
    closeButton?: {
      payload: any
    }
  };
  footer?: { 
    actions: IModalAction[] 
  }
  classes?: string;
}

@Component({
  selector: 'iwt-modal',
  templateUrl: './modal.html'
})
export class IWTModal {
  @Input() data: IModalData;
  @Input() emit: any;

  onClick(payload) {
    if(!this.emit) return;
    this.emit('click', payload);
  }
}