import { EventHandler } from '@n7-frontend/core';

export class FormAlarmCreateEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch(type){
        case 'form-alarm-create.change':
          this.dataSource.onChange(payload);
          break;

        default: 
          break;
      }
    });
  }
}