import { EventHandler } from '@n7-frontend/core';

export class ChartEH extends EventHandler {
  public listen() {

    /* this.innerEvents$.subscribe(event => {
      // TODO
    }); */

    this.outerEvents$.subscribe(event => {
      // chart legend change
      if(event.type.indexOf('legend.change') !== -1){
        this.dataSource.onLegendChange(event);
      }

      switch(event.type){
        /* case 'chart-layout.chart-legend.change':
          this.dataSource.onLegendChange(event);
          break; */

        case 'chart-layout.chart-request':
          this.dataSource.onChartRequest(event);  
          break;

        case 'chart-layout.compare-chart-request':
          this.dataSource.onChartRequest(event);  
          break;

        default: 
          break;
      }
    });
  }
}