import { Component , OnInit } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';
import { TranslateService } from '@ngx-translate/core';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { TitrimetroInputLayoutDS } from './titrimetro-input-layout.ds';
import { TitrimetroInputLayoutEH } from './titrimetro-input-layout.eh';
import { ActivatedRoute } from '@angular/router';

import { Utils } from '@app/providers';
import { Apollo } from '@app/providers';

@Component({
  selector: 'titrimetro-input-layout',
  templateUrl: './titrimetro-input-layout.html'
})
export class TitrimetroInputLayoutComponent implements OnInit {

  public lb = new LayoutBuilder('titrimetro-input-layout');
  private widgets = [
    { id: 'titrimetro-input-alert' },
    { id: 'titrimetro-input-datepicker' },
    { id: 'titrimetro-input-form' },
    { id: 'titrimetro-input-old-inputs' },
    { id: 'titrimetro-input-modal' },
    { id: 'titrimetro-input-modal-edit-form' },
    { id: 'pagination' }
  ];

  constructor(
    private utils: Utils,
    private translate: TranslateService,
    private apollo: Apollo,
    private route: ActivatedRoute,
  ){}

  ngOnInit(){
    // on ready
    this.lb.ready$.subscribe(() => {
      // and utils to make api calls
      this.lb.eventHandler.emitInner('init', {
        utils: this.utils,
        apollo: this.apollo,
        translate: this.translate,
        route: this.route,
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new TitrimetroInputLayoutDS(),
      eventHandler: new TitrimetroInputLayoutEH()
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }
}