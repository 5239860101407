import { DataSource } from '@n7-frontend/core';
import { helpers } from '@app/helpers';

export class TitrimetroInputModalEditFormDS extends DataSource {

  formData = {};
  
  protected transform(data){
    this.formData = {};
    console.log('transform in modal edit form!!!!',data);
    if(!data) return;

    data.sections.forEach(section => {
      console.log('sectionid = '+section.id);
      this.formData[section.id] = {};
      section.fields.forEach(field => {
        this.formData[section.id][field.id] = {'wasEdited':false};
        if( helpers.isNumeric(field.value) ){
          this.formData[section.id][field.id]['value'] = parseFloat(field.value);
          this.formData[section.id][field.id]['originalValue'] = parseFloat(field.value);
        }
        if(typeof(field['placeholder'])=='undefined'){
            field['placeholder']=this.options.labels.placeholderPrefix + ' ' + field.unit;
        }
        field['payload'] = {
          sectionId: section.id,
          fieldId: field.id
        };
      });
    });

    return data;
  }

  onInputChange(obj){
    var sectionId = obj.sectionId;
    var fieldId = obj.fieldId;
    var value = obj.value;
    if(helpers.isNumeric(value))
      this.formData[sectionId][fieldId]['value'] = parseFloat(value);
    this.formData[sectionId][fieldId]['wasEdited'] = true;
    console.log('current formData ',this.formData);
  }

  createSubmitData(): any {
    // TO DO : filter the formatData based on the API
    return this.formData;
  }

}

