import { DataSource } from '@n7-frontend/core';

export class DataAnalysisUploadFormDS extends DataSource {

  private data;
  private file: any;

  protected transform(data){
    if(!data) data = {};

    data['fileFormat'] = ( data.fileFormat ? data.fileFormat : 'excel' );

    var title = null;
    var choose_button_text = null;
    var submit_button_text = null;

    if(this.options.labels){
      title = this.options.labels.upload_form_title;
      title = title.replace('__FILE_FORMAT__',data.fileFormat);

      choose_button_text = this.options.labels.upload_form_choose_button;
      choose_button_text = choose_button_text.replace('__FILE_FORMAT__',data.fileFormat);

      submit_button_text = this.options.labels.upload_form_submit_button;
      submit_button_text = submit_button_text.replace('__FILE_FORMAT__',data.fileFormat);
    }

    // TO DO : replace the fields.data array with data present in config or something related to apollo
    this.data = {
      fileFormat: data.fileFormat,
      icon: 'n7-icon-upload',
      title: title,
      fields: [{
        type: 'select',
        selected: 'File di analisi',
        data: ['File di analisi','File di controllo','File di evoluzione','File di validazione','analisi solidi','analisi liquidi'],
        label: 'i18n.components.upload_data_analysis_form.type_of_file',
        placeholder: 'i18n.components.upload_data_analysis_form.type_of_file_placeholder'
      }],
      chooseFileButtonText: choose_button_text,
      chosenFile: ( data.chosenFile ? data.chosenFile : null ),
      chosenFileName: ( ( data.chosenFile && data.chosenFile.name )
                         ? data.chosenFile.name : '' ),
      submitButtonText: submit_button_text
    }

    return this.data;
  }

  chosenFileToUpload(payload){
    if(this.data){
      this.data.chosenFile = payload.target.files[0];
      // To Read the file's content :
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.file = reader.result;
      }
      // reader.readAsText(this.data.chosenFile);
      reader.readAsDataURL(this.data.chosenFile);
      // reader.readAsBinaryString(this.data.chosenFile);

      this.update(this.data);
    }
  }

  onSelectChange(payload){
    this.data.fields[0].selected = payload.value;
  }

  createDataToSubmit(){
    if(!this.data) return null;
    return { ...this.data, fileString: this.file };
  }


}
