export const GET_CHART_MODEL_DATA_MOCK = {
  "data": {
    "getModelChartWithValues": {
      "id": "0xyp-CHART-8bof",
      "name": null,
      "mode": "MODEL",
      "startDate": "2019-08-10T00:00:00.000Z",
      "endDate": "2019-08-16T00:00:00.000Z",
      "parameters": [
        {
          "id": "ing-ph",
          "section": {
            "id": "ingresso_industriale"
          }
        }
      ],
      "paramValues": [
        {
          "date": "2019-08-10T00:00:00.000Z",
          "values": [
            14.446928286492664
          ]
        },
        {
          "date": "2019-08-11T00:00:00.000Z",
          "values": [
            21.697088042549204
          ]
        },
        {
          "date": "2019-08-12T00:00:00.000Z",
          "values": [
            29.110575615888404
          ]
        },
        {
          "date": "2019-08-13T00:00:00.000Z",
          "values": [
            36.26584086119782
          ]
        },
        {
          "date": "2019-08-14T00:00:00.000Z",
          "values": [
            44.23136698129508
          ]
        },
        {
          "date": "2019-08-15T00:00:00.000Z",
          "values": [
            52.00930133601513
          ]
        },
        {
          "date": "2019-08-16T00:00:00.000Z",
          "values": [
            60.73202761059298
          ]
        }
      ],
      "modelParamValues": [
        {
          "date": "2019-08-10T00:00:00.000Z",
          "values": [
            15.227893813966329
          ]
        },
        {
          "date": "2019-08-11T00:00:00.000Z",
          "values": [
            23.076430456238683
          ]
        },
        {
          "date": "2019-08-12T00:00:00.000Z",
          "values": [
            30.21656093439688
          ]
        },
        {
          "date": "2019-08-13T00:00:00.000Z",
          "values": [
            37.83733680626894
          ]
        },
        {
          "date": "2019-08-14T00:00:00.000Z",
          "values": [
            46.81308392660141
          ]
        },
        {
          "date": "2019-08-15T00:00:00.000Z",
          "values": [
            52.00930133601513
          ]
        },
        {
          "date": "2019-08-16T00:00:00.000Z",
          "values": [
            60.575382750388975
          ]
        }
      ]
    }
  }
};