import { EventHandler } from '@n7-frontend/core';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { AuthService } from '@app/services';

export class MainLayoutEH extends EventHandler {
  private auth: AuthService;
  private destroyed$: Subject<any> = new Subject();

  public listen() {
    // listen to inner (layout) events
    this.innerEvents$.subscribe(({type, payload}) => {
      switch(type){
        case 'main-layout.init':
          this.dataSource.onInit(payload);
          this.auth = payload.auth;
          // logged control
          if(this.auth.loggedIn) {
            this.emitOuter('updateuseravatar', this.dataSource.getUserAvatar()); 
          }
          break;

        case 'main-layout.destroy':
            this.destroyed$.next();
            break;

        case 'main-layout.tools-click':
          this.emitGlobal('tools-click', payload);
          break;

        default: 
          break;
      }
    });
    
    // listen to outer (widgets) events
    this.outerEvents$.subscribe(({type, payload}) => {
      switch(type){  
        case 'subnav.click':
          this.dataSource.onSubnavClick(payload);
          break;

        case 'header.click':
          this.dataSource.onHeaderClick(payload);
          break;

        case 'user-menu.click':
          this.dataSource.onUserMenuClick(payload);
          break;

        case 'modal-alarm-create.click':
          if(payload.source === 'save'){
            const req$ = this.dataSource.onModalAlarmClick(payload);
            req$.pipe(
              filter((response: any) => response)
            ).subscribe(response => {
              this.dataSource.onAlarmCreated(response, payload);
              this.emitOuter('closeAlertModal');
              if(payload.isUpdate){
                this.emitGlobal('alertSettingsUpdated');
              }
            });
          }
          break;

        default: 
          break;
      }
    });
    
    // listen to global events
    EventHandler.globalEvents$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(({type, payload}) => {
      switch(type){  
        case 'global.loadChart':
          this.dataSource.onLoadChart(payload);
          break;
        case 'global.breadcrumbClick':
          this.dataSource.onBreadcrumbClick(payload);
          break;
        case 'global.routerChange':
          this.dataSource.onRouterChange(payload);
          break;
        case 'global.openAlertModal':
          this.dataSource.onOpenAlertModal(payload);

          // open modal signal
          setTimeout(() => this.emitOuter('openAlertModal'));
          break;
        case 'global.navigate':
          this.dataSource.onNavigate(payload);
          break;
        case 'global.updateuserinfo':
          this.emitOuter('updateuseravatar', this.dataSource.getUserAvatar());
          break;
          
        default: 
          break;
      }
    });
  }

}