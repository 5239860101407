import { EventHandler } from '@n7-frontend/core';

export class ModelSimulationDownloadStepsEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        default: 
          break;
      }
    });

  }
}
