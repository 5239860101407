import { Component } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';
import { TranslateService } from '@ngx-translate/core';
import tippy from 'tippy.js';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { HomeLayoutEH } from './home-layout.eh';
import { HomeLayoutDS } from './home-layout.ds';
import { Apollo } from '@app/providers';
import { ChartLayoutDS } from '../chart-layout/chart-layout.ds';


@Component({
  selector: 'home-layout',
  templateUrl: './home-layout.html'
})
export class HomeLayoutComponent {
  public lb = new LayoutBuilder('home-layout');
  private widgets: any[] = [
    { id: 'chart-menu', hasStaticData: true },
    { id: 'modal-delete-widget', hasStaticData: true },
    { id: 'modal-reorder-widgets', hasStaticData: true },
    { id: 'sortable-widgets' }
  ];

  constructor(
    private apollo: Apollo,
    private translate: TranslateService,
  ){}

  ngOnInit(){
    let result = [];
    this.apollo.request$('getDashboardCharts').subscribe(response => {
      response.forEach(chartResponse => {
        result.push(ChartLayoutDS.normalizeChartData(chartResponse));
      });
      this._handleResponse({ charts: result });
    });
  }

  private _handleResponse(response){
    // added widgets dinamically
    response.charts.forEach((chartData, index) => {
      this.widgets.push({
        id: `chart-${index}`,
        dataSource: DS.ChartDS,
        eventHandler: EH.ChartEH,
      });
      this.widgets.push({
        id: `chart-${index}-title`,
        dataSource: DS.ChartTitleDS,
      });
      this.widgets.push({
        id: `chart-${index}-legend`,
        dataSource: DS.ChartLegendDS,
        eventHandler: EH.ChartLegendEH,
        options: {
          chartId: chartData.id,
          isDashboardChart: true,
          hasSectionLabel: true
        }
      });
    });

    // on ready
    this.lb.ready$.subscribe(() => {
      this.lb.eventHandler.emitInner('init', {
        apollo: this.apollo,
        translate: this.translate,
        tippy: tippy,
        response
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new HomeLayoutDS(),
      eventHandler: new HomeLayoutEH(),
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }

}