import { EventHandler } from '@n7-frontend/core';

export class UploadDataAnalysisLayoutEH extends EventHandler {

  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'upload-data-analysis-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        case 'list-alerts-layout.destroy':
          this.dataSource.destroy$.next();
          break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'data-analysis-upload-form.click':
          this.dataSource.onUploadFormClick(event.payload);
          break;

        case 'data-analysis-upload-form.submit':
          this.dataSource.onUploadFormSubmit(event.payload);
          break;

        case 'list-old-data-analysis-uploads.cellClick':
          this.dataSource.onTableCellClick(event.payload);
          break;

        case 'list-old-data-analysis-uploads.cellDblClick':
          this.dataSource.onTableCellDoubleClick(event.payload);
          break;

        case 'data-analysis-upload-alert.close':
          this.dataSource.closeAlert();
          break;

        case 'pagination.change':
          this.dataSource.loading = true;
          break;

        default:
          break;
      }
    });
  }

}