import { EventHandler } from '@n7-frontend/core';

export class ModalDeleteWidgetEH extends EventHandler {
  public listen(){
    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'modal-delete-widget.click':
          if(event.payload === 'remove'){
            const chartId = this.dataSource.getChartId();
            this.emitOuter('remove-click', { chartId });
          }
          this.dataSource.onDismiss();
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'home-layout.open-modal-delete-widget':
          this.dataSource.onOpen(event.payload);
          break;

        default:
          break;
      }
    });
  }
}