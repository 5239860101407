import { Component , OnInit } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';
import { TranslateService } from '@ngx-translate/core';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { UploadModelSimulationLayoutDS } from './upload-model-simulation-layout.ds';
import { UploadModelSimulationLayoutEH } from './upload-model-simulation-layout.eh';

import { Apollo } from '@app/providers';

@Component({
  selector: 'upload-model-simulation-layout',
  templateUrl: './upload-model-simulation-layout.html'
})
export class UploadModelSimulationLayoutComponent implements OnInit {

  public lb = new LayoutBuilder('upload-model-simulation-layout');
  private widgets = [
    { id: 'model-simulation-upload-alert' },
    { id: 'model-simulation-upload-form' }
  ];

  constructor(
    private apollo: Apollo,
    private translate: TranslateService
  ){}

  ngOnInit(){
    // on ready
    this.lb.ready$.subscribe(() => {
      // and utils to make api calls
      this.lb.eventHandler.emitInner('init', {
        apollo: this.apollo,
        translate: this.translate
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new UploadModelSimulationLayoutDS(),
      eventHandler: new UploadModelSimulationLayoutEH()
    });

  }


}