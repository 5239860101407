import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LayoutBuilder } from '@n7-frontend/core';
import tippy from 'tippy.js';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { MainLayoutEH } from './main-layout.eh';
import { MainLayoutDS } from './main-layout.ds';
import { filter, map, delay } from 'rxjs/operators';
import { Apollo } from '@app/providers';
import { UserService, AuthService } from '@app/services';

@Component({
    selector: 'main-layout',
    templateUrl: './main-layout.html'
})
export class MainLayoutComponent implements OnInit {
  public lb = new LayoutBuilder('main-layout');
  private widgets = [
    { id: 'footer', hasStaticData: true },
    { id: 'subnav', hasStaticData: true },
    { id: 'header', hasStaticData: true },
    { id: 'breadcrumbs', hasStaticData: true },
    { id: 'user-menu', hasStaticData: true },
    { id: 'list-alerts-table' },
    { id: 'modal-alarm-create' },
    { id: 'form-alarm-create' },
  ];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private apollo: Apollo,
    private user: UserService,
    private auth: AuthService
  ){ }

  ngOnInit(){
    // on ready
    this.lb.ready$.subscribe(() => {
      this.lb.eventHandler.emitInner('init', {
        router: this.router,
        translate: this.translate,
        tippy: tippy,
        apollo: this.apollo,
        user: this.user,
        auth: this.auth,
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new MainLayoutDS(),
      eventHandler: new MainLayoutEH(),
    });

    // listen router changes
    this._listenRouter();

    // tooltips
    this._activateTooltips();
  }

  private _listenRouter(){
    // listen to router changes
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route.snapshot),
      map(route => {
        while (route['firstChild']) {
          route = route['firstChild'];
        }
        return route;
      }),
      delay(10) // fix for async subscribes
    ).subscribe((route: ActivatedRouteSnapshot) => {
      this.lb.eventHandler['emitGlobal']('routerChange', {
        params: route.params,
        data: route.data,
        url: route.url,
      });
    });
  }

  private _activateTooltips(){
    // activate tooltips
    tippy('#main-layout', {
      appendTo: document.getElementById('main-layout'),
      target: '.has-tooltip',
      arrow: true,
      onShow(instance) {
        tippy.hideAll({ exclude: instance });
      }
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }
}
