import { Component, Input } from '@angular/core';

/**
 * Interface for the footer
 * 
 * @property logo (required)
 * @property classes (optional)
 * @property payload (optional)
 * @property _meta (optional)
 */
export interface IFooterData {
  logo: string;
  
  /**
   * additional html classes
   */
  classes?: string;
  /**
   * action click's payload
   */
  payload?: any;
  /**
   * additional info
   */
  _meta?: any;
}

@Component({
  selector: 'iwt-footer',
  templateUrl: './footer.html'
})
export class FooterComponent {
  @Input() data: IFooterData;
  @Input() emit: any;

  onAction(payload) {
    if (!this.emit) return;

    this.emit('footerEmit', payload);
  }
}
