import { EventHandler } from '@n7-frontend/core';

export class ModelSimulationDownloadSetupFormEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'model-simulation-download-setup-form.datePicker.change':
          this.emitOuter('datePickerChange',event.payload);
          break;
        case 'model-simulation-download-setup-form.click':
          this.emitOuter('click', event.payload);
          break;

        case 'model-simulation-download-setup-form.inputChange':
            this.emitOuter('inputChange',event.payload);
            break;

        default:
          break;
      }
    });
  }
}
