import { DataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';

export class HeaderDS extends DataSource {
  private activeId: string | null;
  private avatar: string;

  protected transform(data) {
    const labels = (this.options && this.options.labels) ? this.options.labels : {},
      sections = Config.get('sections'),
      firstSection = Object.keys(sections)[0];

    let items = [
      { text: labels.home, payload: { source: 'nav', path: '/' }, icon: 'n7-icon-dashboard', _meta: { id: 'home' } },
      { text: labels.overview, payload: { source: 'nav', path: '/chart/overview' }, icon: 'n7-icon-chart-line', _meta: { id: 'chart-overview' } },
      { text: labels.plant_section, payload: { source: 'nav', path: `/plant-section/${firstSection}` }, icon: 'n7-icon-chart-line' , _meta: { id: 'plant-section' }},
      { text: labels.customized_view, payload: { source: 'nav', path: '/customized-view' }, icon: 'n7-icon-chart-line' , _meta: { id: 'customized-view' }},
      { text: labels.model, payload: { source: 'nav', path: `/model/${firstSection}` }, icon: 'n7-icon-chart-lines' , _meta: { id: 'model' }},
    ];

    // active control
    this._setActive(items);

    return {
      logo: {
        image: Config.get('assetsPath') + '/img/Logo-header.png',
        title: 'I-SWAT',
        payload: {
          source: 'nav',
          path: '/'
        }
      },
      nav: { items },
      actions: [
        { 
          icon: 'n7-icon-bell', 
          payload: {
            source: 'alertsButton',
          }, 
          classes: 'header-action-alerts',
          badge: {} 
        },
      ],
      user: {
        img: this.avatar || Config.get('assetsPath') + '/img/User-Avatar-Default.jpg',
        payload: {
          source: 'userButton',
        }
      },
      menuToggle: {
        open: {
          payload: {
            source: 'mobileButton',
            action: 'open'
          }
        },
        close: {
          payload: {
            source: 'mobileButton',
            action: 'close'
          }
        }
      }
    }
  }

  onRouterChange(payload){
    const paths = payload.url.map(segment => segment.path);
    if(paths[0] === 'custom-chart'){
      this.activeId = 'customized-view';
    } else if(paths[0] !== 'chart'){
      this.activeId = paths[0];
    } else if(paths[1] === 'overview') {
      this.activeId = paths.join('-');
    }

    if(this.output) this._setActive(this.output.nav.items);
  }

  updateUserAvatar(avatar){
    this.avatar = avatar;
    if(this.output && avatar){
      this.output.user.img = avatar;
    }
  }

  private _setActive(items){
    items.forEach(item => {
      if(this.activeId === item._meta.id){
        item.classes = 'is-current';
      } else {
        item.classes = '';
      }
    });
  }
}
