import { Component, Input } from '@angular/core';

/**
 * Interface for row's cells
 * 
 * @property content (required)
 * @property type (optional)
 * @property classes (optional)
 * @property payload (optional)
 * @property _meta (optional)
 */
export interface ICell {
    /*
     * inner html or hint for the input text, depending on "type"
    */
    content: any;
    /**
     * content type
     */
    type?: 'html' | 'input:text' | 'input:number' ;
    /**
     * additional html classes
     */
    classes?: string;
    /**
     * action click's payload
     */
    payload?: any;
    /**
     * additional info
     */
    _meta?: any;
}

/**
 * Interface for table's rows (head, body or foot rows)
 * 
 * @property cells (required)
 * @property classes (optional)
 * @property payload (optional)
 * @property _meta (optional)
 */
export interface IRow {
    /*
     * row's cells
    */
    cells: ICell[];
    /**
     * additional html classes
     */
    classes?: string;
    /**
     * additional info
     */
    _meta?: any;
}

/**
 * Interface for TableComponent's "data"
 * 
 * @property body (required)
 * @property head (optional)
 * @proprty foot (optional)
 * @property classes (optional)
 * @property payload (optional)
 * @property _meta (optional)
 */
export interface ITableData {
    /**
     * table's head
     */
    head?: IRow[];
    /**
     * table's body (the table its self)
     */
    body: IRow[];
    /**
     * table's foot
     */
    foot?: IRow[];
    /**
     * additional html classes
     */
    classes?: string;
    /**
     * additional info
     */
    _meta?: any;
}


@Component({
  selector: "iwt-download-model-simulation-table-form",
  templateUrl: "./download-model-simulation-table-form.html"
})
export class IWTDownloadModelSimulationTableForm {
    @Input() data: ITableData;
    @Input() emit: any;


    onCellClick(cell_payload) {
        if (!this.emit) return;

        this.emit('cellClick', cell_payload);
    }

    onCellDblClick(cell_payload) {
        if (!this.emit) return;

        this.emit('cellDblClick', cell_payload);
    }

    onInputChange(payload:any,value:string) {
        if (!this.emit) return;
        this.emit('inputChange', { cell_payload: payload, value } );
    }
}