import { EventHandler } from '@n7-frontend/core';

export class ChartDatepickerEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'chart-datepicker.change': 
          this.emitOuter('change', event.payload);
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      if(event.type === 'chart-layout.custom-date-selected'){
        this.dataSource.open();
      }
    });
  }
}