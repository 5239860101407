import { EventHandler } from '@n7-frontend/core';

export class ModelSimulationDownloadStaticSimulationFormEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'model-simulation-download-static-simulation-form.click':
          this.emitOuter('click', event.payload);
          break;

        case 'model-simulation-download-static-simulation-form.inputChange':
            this.emitOuter('inputChange',event.payload);
            break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'download-model-simulation-layout.forward':
          if(event.payload && event.payload.sectionIdx==2)
            this.emitOuter('submit',this.dataSource.generateSubmitData());
          break;

          default:
          break;
      }
    });
  }
}
