import { DataSource } from '@n7-frontend/core';

export class ChartMenuDS extends DataSource {
  protected transform(data) {
    return {
      items: [{
        label: 'i18n.chart_menu.rename',
        icon: 'n7-icon-edit',
        payload: 'rename'
      }, {
        label: 'i18n.chart_menu.remove',
        icon: 'n7-icon-trash',
        payload: 'remove'
      }]
    }
  }
}
