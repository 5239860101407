import { EventHandler } from '@n7-frontend/core';

export class SortableWidgetsEH extends EventHandler {
  public listen() {

    this.outerEvents$.subscribe(({ type, payload }) => {
      switch(type){
        case 'home-layout.get-current-widgets-order':
          const widgetsOrder = this.dataSource.getWidgetsOrder();
          this.emitOuter('current-widgets-order', widgetsOrder);
          break;

        default:
          break;
      }

    });
  }
}