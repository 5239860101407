import { LayoutDataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';
import { Apollo } from '@app/providers';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

export class ModelSimulationsLayoutDS extends LayoutDataSource {
  private apollo: Apollo;
  private route: any;
  public destroy$: Subject<any> = new Subject();
  public loading: boolean = false;
  private translate: any;
  private labels;
  protected preloadedLabels = {
    header_date: 'i18n.components.model_simulations_table.header_date',
    header_simulation_name: 'i18n.components.model_simulations_table.header_simulation_name',
    header_simulation_num_of_params: 'i18n.components.model_simulations_table.header_simulation_num_of_params'
  };

  onInit(payload){
    this.translate = payload.translate;
    this.apollo = payload.apollo;
    this.route = payload.route;

    // listen to router changes
    this._listenRouteChanges();
    this._loadTranslatedLabels();
  }

  private _listenRouteChanges(){
    this.route.params.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      const page = params.page ? +params.page : 1,
        limit = Config.get('paginationLimit'),
        offset = page === 1 ? 1 : (page * limit) + 1;

      const apolloRequest$ = this.apollo.request$('getSimulations', {
        pagination: { limit, offset }
      });
      apolloRequest$.subscribe(response => this._handleRequest(response));
  
      // set pagination path
      this.one('pagination').updateOptions({ basePath: 'model-simulations/', current: page });
    });
  }

  private _handleRequest({ totalCount, items }){
    this.one('pagination').update({ total: totalCount });
    this.one('model-simulations-list').update(items);

    this.loading = false;
  }

  protected _loadTranslatedLabels(){
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe(labels => {
      this.labels = labels;
      this.one('model-simulations-list').updateOptions({labels});
    });
  }

}
