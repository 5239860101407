import { LayoutDataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';
import { map, takeUntil } from 'rxjs/operators';
import { Apollo } from '@app/providers';
import { Subject } from 'rxjs';
import { Base64 } from 'js-base64';

export class UploadDataAnalysisLayoutDS extends LayoutDataSource {
  private apollo: Apollo;
  private route: any;
  private translate: any;
  protected preloadedLabels = {
    upload_form_title: 'i18n.components.upload_data_analysis_form.title',
    upload_form_choose_button: 'i18n.components.upload_data_analysis_form.choose_button',
    upload_form_submit_button: 'i18n.components.upload_data_analysis_form.submit_button',
    header_date: 'i18n.components.upload_data_analysis_table.header_date',
    header_file_name: 'i18n.components.upload_data_analysis_table.header_file_name',
    header_file_type: 'i18n.components.upload_data_analysis_table.header_file_type'
  };
  public destroy$: Subject<any> = new Subject();
  public loading: boolean = false;

  onInit(payload){
    this.apollo = payload.apollo;
    this.route = payload.route;
    this.translate = payload.translate;
    this._loadTranslatedLabels();

    // listen to router changes
    this._listenRouteChanges();

    /* TO DO : check if file extension and/or file type from QUERY or CONFIG
    Attenzione che ApiRequest nn esiste più come modulo del core!!!
    // data request
    let dataURL = Config.get('apiUrl');
    const body = {};
    // mocked request
    if ( Config.get('useMock') === true ) {
      dataURL = dataURL + '/upload-data-analysis-file-format';
    }
    const request$ = new ApiRequest(
      this.utils.request$('GET', dataURL, body)
    );

    request$.out$.subscribe(response => this._handleDataRequest(response));
    request$.run();
    */

  }


  onUploadFormClick(payload){
    // console.log('uploadFormClicked with following payload : ' , payload);
  }

  onUploadFormSubmit(payload){
    var fileName = payload.chosenFileName;
    if((typeof(fileName)=='undefined')||(fileName == null)||(fileName == '')) {
      this.showAlert(false,'',`Non è stato scelto alcun file, sceglierne uno e riprovare.`);
    } else {
      // const base64String = btoa(unescape(encodeURIComponent(payload.fileString)));
      const file = payload.fileString.split(',')[1]
      const base64String = file.toString('base64');
      // const base64String = Base64.encode(payload.fileString);
      // const base64String = payload.fileString;
      console.log('uploading...', {
        file: base64String,
        filename: 'data-analysis_' + payload.chosenFile.lastModified
      });
      this.apollo.request$('uploadExcelAnalysisFile', {
        file: base64String,
        filename: 'data-analysis_' + payload.chosenFile.lastModified,
        fileType: (payload.fields[0].selected)? payload.fields[0].selected : 'File di analisi',
      }).subscribe(response => {
        console.log('file uploaded', response);
        this.showAlert(true, payload.chosenFileName);
      });
    }
  }



  showAlert(success:boolean,fileName: string,message?:any){
    var data;
    if(success){
      data = {
        text: `il file <strong>${fileName}</strong> è stato caricato correttamente.`,
        hasCloseButton: true,
        payload: "close",
        classes: "is-success"
      }
    } else {
      var text;
      if(message) text = message;
      else text = `
      Errore durante il caricamento del file <strong>${fileName}</strong>.`
      data = {
        text: text,
        hasCloseButton: true,
        payload: "close",
        classes: "is-error"
      }
    }
    this.one('data-analysis-upload-alert').update(data);
    //if(this.closeAlertTimeout!=null){
    //  clearTimeout(this.closeAlertTimeout);
    //  this.closeAlertTimeout=null;
    //}
    //this.closeAlertTimeout = setTimeout(() => {
    //  this.closeAlert();
    //  this.closeAlertTimeout = null;
    //},this.alertCloseTimeOutTime);

  }

  closeAlert(){
    this.one('data-analysis-upload-alert').update(null);
  }

  onTableCellClick(payload){
    console.log('tableCellClicked with following payload : ' , payload);
  }

  onTableCellDoubleClick(payload){
    console.log('tableCellDoubleClicked with following payload : ' , payload);
  }

  protected _loadTranslatedLabels(){
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe(labels => {
      this.exclude(['pagination']).updateOptions({labels});
      this.one('data-analysis-upload-form').update(null);
    });
  }

  private _listenRouteChanges(){
    this.route.params.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      const page = params.page ? +params.page : 1,
        limit = Config.get('paginationLimit'),
        offset = page === 1 ? 1 : (page * limit) + 1;

      const apolloRequest$ = this.apollo.request$('getOldAnalysisUploadData', {
        // FIXME: controllare startDate, endDate
        // da dove provengono? servono?
        startDate: "2019-07-25",
        endDate: "2019-08-20",
        pagination: { limit, offset }
      });
      apolloRequest$.subscribe(response => this._handleDataRequest(response));

      // set pagination path
      this.one('pagination').updateOptions({ basePath: 'upload-data-analysis/', current: page });
    });
  }

  private _handleDataRequest({ totalCount, items }){
    const limit = Config.get('paginationLimit');

    if (limit < totalCount) {
      this.one('pagination').update({ total: totalCount });
    }

    if (Array.isArray(items) && items.length) {
      this.one('list-old-data-analysis-uploads').update(items);
    }

    this.loading = false;
  }
}
