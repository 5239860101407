import { EventHandler } from '@n7-frontend/core';

export class ManageAlertsEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(({ type, payload }) => {
      if(type === 'manage-alerts.click'){
        if(payload.source==="edit"){
          const clickPayload = payload.payload;
          this.emitGlobal('openAlertModal', {
            ...clickPayload,
            elementId: clickPayload.element,
            isUpdate: true
          });
        } else if(payload.source==="delete"){
          this.emitOuter('click', payload);
        }
      }
    });
  }
}
