import { GET_PLANT_SECTION_DATA_MOCK } from './get-plant-section-data.mock';

export const GET_ELEMENTS_LIST_MOCK = {
  status: 'ok',
  values: _getValues()
}

function _getValues(){
  const headers = [
    'ingresso_industriale',
    'ingresso_civile',
    'preaccumulo',
    'sedimentazione_primaria',
    'denitrificazione',
    'sedimentazione_biologica'
  ];

  return headers.map(header => ({
    id: header,
    items: GET_PLANT_SECTION_DATA_MOCK.values
  }));
}