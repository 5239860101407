import { EventHandler } from '@n7-frontend/core';

export class ParameterCardsEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'parameter-cards.click':
          this.emitOuter('click', event.payload);
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'elements-list-layout.parameter-filter-change':
          this.dataSource.onParameterFilterChange(event.payload);
          break;

        default:
          break;
      }

    });
  }
}