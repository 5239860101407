export const GET_HOME_CHARTS_DATA_MOCK = {
  "data": {
    "getDashboardCharts": [
      {
        "id": "ncr7-CHART-b2i0",
        "name": " Dashboard Chart 1",
        "uiPosition": 0,
        "mode": "NORMAL",
        "chartPeriod": "FIXED_DATES",
        "startDate": "2019-07-10T00:00:00.000Z",
        "endDate": "2019-07-17T00:00:00.000Z",
        "parameters": [
          {
            "id": "pre-ph",
            "section": {
              "id": "preaccumulo"
            }
          }
        ],
        "paramValues": [
          {
            "date": "2019-07-10T00:00:00.000Z",
            "values": [
              17.92258075435328
            ]
          },
          {
            "date": "2019-07-11T00:00:00.000Z",
            "values": [
              17.787378545199697
            ]
          },
          {
            "date": "2019-07-12T00:00:00.000Z",
            "values": [
              18.784945433224227
            ]
          },
          {
            "date": "2019-07-13T00:00:00.000Z",
            "values": [
              20.56508698388627
            ]
          },
          {
            "date": "2019-07-14T00:00:00.000Z",
            "values": [
              21.96765675711904
            ]
          },
          {
            "date": "2019-07-15T00:00:00.000Z",
            "values": [
              23.470679804910464
            ]
          },
          {
            "date": "2019-07-16T00:00:00.000Z",
            "values": [
              23.826371108330083
            ]
          },
          {
            "date": "2019-07-17T00:00:00.000Z",
            "values": [
              24.009624280088904
            ]
          }
        ],
        "compareParamValues": null,
        "modelParamValues": null
      },
      {
        "id": "h67g-CHART-elz7",
        "name": " Dashboard Chart 2",
        "uiPosition": 1,
        "mode": "NORMAL",
        "chartPeriod": "FIXED_DATES",
        "startDate": "2019-08-09T00:00:00.000Z",
        "endDate": "2019-08-25T00:00:00.000Z",
        "parameters": [
          {
            "id": "civ-uptk",
            "section": {
              "id": "ingresso_civile"
            }
          },
          {
            "id": "civ-ptot",
            "section": {
              "id": "ingresso_civile"
            }
          },
          {
            "id": "civ-nichl",
            "section": {
              "id": "ingresso_civile"
            }
          }
        ],
        "paramValues": [
          {
            "date": "2019-08-09T00:00:00.000Z",
            "values": [
              304.0816931435054,
              87.75600714104786,
              7.605669687642397
            ]
          },
          {
            "date": "2019-08-10T00:00:00.000Z",
            "values": [
              316.55082437514386,
              110.64301258966032,
              7.843202343926054
            ]
          },
          {
            "date": "2019-08-11T00:00:00.000Z",
            "values": [
              328.3727929996299,
              79.24209400841568,
              8.317316107786008
            ]
          },
          {
            "date": "2019-08-12T00:00:00.000Z",
            "values": [
              341.9489141761892,
              124.36534553523735,
              8.403699928336382
            ]
          },
          {
            "date": "2019-08-13T00:00:00.000Z",
            "values": [
              353.101759538754,
              91.13371137989152,
              8.859449416382372
            ]
          },
          {
            "date": "2019-08-14T00:00:00.000Z",
            "values": [
              352.32164654163,
              105.68351585961244,
              9.229003964287731
            ]
          },
          {
            "date": "2019-08-15T00:00:00.000Z",
            "values": [
              366.50946100891264,
              112.21676365279401,
              9.404158307639236
            ]
          },
          {
            "date": "2019-08-16T00:00:00.000Z",
            "values": [
              375.295680195671,
              91.0042207744266,
              9.59312883172856
            ]
          },
          {
            "date": "2019-08-17T00:00:00.000Z",
            "values": [
              374.29394547177276,
              94.53643301799194,
              9.917484951202084
            ]
          },
          {
            "date": "2019-08-18T00:00:00.000Z",
            "values": [
              376.54003715777185,
              122.51679391960316,
              9.998313576477976
            ]
          },
          {
            "date": "2019-08-19T00:00:00.000Z",
            "values": [
              385.1317801088785,
              91.3812490524973,
              10.383486069845093
            ]
          },
          {
            "date": "2019-08-20T00:00:00.000Z",
            "values": [
              391.5550076176747,
              108.78867234582177,
              10.703104842037526
            ]
          },
          {
            "date": "2019-08-21T00:00:00.000Z",
            "values": [
              399.3088600398765,
              141.174061185659,
              11.18433846118294
            ]
          },
          {
            "date": "2019-08-22T00:00:00.000Z",
            "values": [
              402.0475972244949,
              122.29262182240845,
              11.52412319685401
            ]
          },
          {
            "date": "2019-08-23T00:00:00.000Z",
            "values": [
              400.80020946633437,
              176.3797629582122,
              11.808609162388004
            ]
          },
          {
            "date": "2019-08-24T00:00:00.000Z",
            "values": [
              401.81989371709244,
              210.920668716504,
              11.879461577230979
            ]
          },
          {
            "date": "2019-08-25T00:00:00.000Z",
            "values": [
              404.5038472722481,
              212.95989944705593,
              12.004024715921355
            ]
          }
        ],
        "compareParamValues": null,
        "modelParamValues": null
      },
      {
        "id": "wj39-CHART-mkla",
        "name": null,
        "uiPosition": 2,
        "mode": "COMPARE",
        "chartPeriod": "FIXED_DATES",
        "startDate": "2019-07-09T00:00:00.000Z",
        "endDate": "2019-08-05T00:00:00.000Z",
        "parameters": [
          {
            "id": "den-sed",
            "section": {
              "id": "denitrificazione"
            }
          }
        ],
        "paramValues": [
          {
            "date": "2019-07-09T00:00:00.000Z",
            "values": [
              45.382121945528894
            ]
          },
          {
            "date": "2019-07-10T00:00:00.000Z",
            "values": [
              67.12674810106662
            ]
          },
          {
            "date": "2019-07-11T00:00:00.000Z",
            "values": [
              89.40038296490054
            ]
          },
          {
            "date": "2019-07-12T00:00:00.000Z",
            "values": [
              111.7655497673247
            ]
          },
          {
            "date": "2019-07-13T00:00:00.000Z",
            "values": [
              136.04420562627223
            ]
          },
          {
            "date": "2019-07-14T00:00:00.000Z",
            "values": [
              157.04914045292978
            ]
          },
          {
            "date": "2019-07-15T00:00:00.000Z",
            "values": [
              180.9529912717563
            ]
          },
          {
            "date": "2019-07-16T00:00:00.000Z",
            "values": [
              203.0657013940057
            ]
          },
          {
            "date": "2019-07-17T00:00:00.000Z",
            "values": [
              224.77599158655516
            ]
          },
          {
            "date": "2019-07-18T00:00:00.000Z",
            "values": [
              248.4455232318668
            ]
          },
          {
            "date": "2019-07-19T00:00:00.000Z",
            "values": [
              272.62668130891274
            ]
          },
          {
            "date": "2019-07-20T00:00:00.000Z",
            "values": [
              296.01970925413116
            ]
          },
          {
            "date": "2019-07-21T00:00:00.000Z",
            "values": [
              317.593322175085
            ]
          },
          {
            "date": "2019-07-22T00:00:00.000Z",
            "values": [
              338.0584242145216
            ]
          },
          {
            "date": "2019-07-23T00:00:00.000Z",
            "values": [
              359.5353358293482
            ]
          },
          {
            "date": "2019-07-24T00:00:00.000Z",
            "values": [
              383.1460324884703
            ]
          },
          {
            "date": "2019-07-25T00:00:00.000Z",
            "values": [
              403.7437615722003
            ]
          },
          {
            "date": "2019-07-26T00:00:00.000Z",
            "values": [
              424.35317457157737
            ]
          },
          {
            "date": "2019-07-27T00:00:00.000Z",
            "values": [
              447.4986791753742
            ]
          },
          {
            "date": "2019-07-28T00:00:00.000Z",
            "values": [
              471.6455055391682
            ]
          },
          {
            "date": "2019-07-29T00:00:00.000Z",
            "values": [
              495.3769447481563
            ]
          },
          {
            "date": "2019-07-30T00:00:00.000Z",
            "values": [
              519.3734814142584
            ]
          },
          {
            "date": "2019-07-31T00:00:00.000Z",
            "values": [
              543.1261474361623
            ]
          },
          {
            "date": "2019-08-01T00:00:00.000Z",
            "values": [
              563.2110461818752
            ]
          },
          {
            "date": "2019-08-02T00:00:00.000Z",
            "values": [
              588.1887513643285
            ]
          },
          {
            "date": "2019-08-03T00:00:00.000Z",
            "values": [
              610.5064612988177
            ]
          },
          {
            "date": "2019-08-04T00:00:00.000Z",
            "values": [
              633.6184592550543
            ]
          },
          {
            "date": "2019-08-05T00:00:00.000Z",
            "values": [
              655.6257759379339
            ]
          }
        ],
        "compareParamValues": [
          {
            "date": "2019-06-08T00:00:00.000Z",
            "values": [
              42.73089851015419
            ]
          },
          {
            "date": "2019-06-09T00:00:00.000Z",
            "values": [
              63.16056395464072
            ]
          },
          {
            "date": "2019-06-10T00:00:00.000Z",
            "values": [
              87.9289072912176
            ]
          },
          {
            "date": "2019-06-11T00:00:00.000Z",
            "values": [
              111.94058224688177
            ]
          },
          {
            "date": "2019-06-12T00:00:00.000Z",
            "values": [
              132.2145408484642
            ]
          },
          {
            "date": "2019-06-13T00:00:00.000Z",
            "values": [
              153.944426101534
            ]
          },
          {
            "date": "2019-06-14T00:00:00.000Z",
            "values": [
              176.21067058307432
            ]
          },
          {
            "date": "2019-06-15T00:00:00.000Z",
            "values": [
              198.35544922773946
            ]
          },
          {
            "date": "2019-06-16T00:00:00.000Z",
            "values": [
              221.05021310442532
            ]
          },
          {
            "date": "2019-06-17T00:00:00.000Z",
            "values": [
              248.4455232318668
            ]
          },
          {
            "date": "2019-06-18T00:00:00.000Z",
            "values": [
              272.62668130891274
            ]
          },
          {
            "date": "2019-06-19T00:00:00.000Z",
            "values": [
              295.94007839499335
            ]
          },
          {
            "date": "2019-06-20T00:00:00.000Z",
            "values": [
              318.90366058400605
            ]
          },
          {
            "date": "2019-06-21T00:00:00.000Z",
            "values": [
              342.99721428772597
            ]
          },
          {
            "date": "2019-06-22T00:00:00.000Z",
            "values": [
              366.493938247813
            ]
          },
          {
            "date": "2019-06-23T00:00:00.000Z",
            "values": [
              383.1460324884703
            ]
          },
          {
            "date": "2019-06-24T00:00:00.000Z",
            "values": [
              403.7437615722003
            ]
          },
          {
            "date": "2019-06-25T00:00:00.000Z",
            "values": [
              427.2898718272397
            ]
          },
          {
            "date": "2019-06-26T00:00:00.000Z",
            "values": [
              450.7840157476713
            ]
          },
          {
            "date": "2019-06-27T00:00:00.000Z",
            "values": [
              471.0407601297878
            ]
          },
          {
            "date": "2019-06-28T00:00:00.000Z",
            "values": [
              495.06879695171847
            ]
          },
          {
            "date": "2019-06-29T00:00:00.000Z",
            "values": [
              517.1444040201549
            ]
          },
          {
            "date": "2019-06-30T00:00:00.000Z",
            "values": [
              537.7206722864199
            ]
          },
          {
            "date": "2019-07-01T00:00:00.000Z",
            "values": [
              560.7050622384256
            ]
          },
          {
            "date": "2019-07-02T00:00:00.000Z",
            "values": [
              584.3795428307635
            ]
          },
          {
            "date": "2019-07-03T00:00:00.000Z",
            "values": [
              608.5620114107937
            ]
          },
          {
            "date": "2019-07-04T00:00:00.000Z",
            "values": [
              633.6184592550543
            ]
          },
          {
            "date": "2019-07-05T00:00:00.000Z",
            "values": [
              654.8621480053139
            ]
          }
        ],
        "modelParamValues": null
      },
      {
        "id": "e50r-CHART-zaav",
        "name": null,
        "uiPosition": 3,
        "mode": "MODEL",
        "chartPeriod": "FIXED_DATES",
        "startDate": "2019-08-01T00:00:00.000Z",
        "endDate": "2019-08-17T00:00:00.000Z",
        "parameters": [
          {
            "id": "civ-ptot",
            "section": {
              "id": "ingresso_civile"
            }
          }
        ],
        "paramValues": [
          {
            "date": "2019-08-01T00:00:00.000Z",
            "values": [
              650.6589348594953
            ]
          },
          {
            "date": "2019-08-02T00:00:00.000Z",
            "values": [
              964.2866344908396
            ]
          },
          {
            "date": "2019-08-03T00:00:00.000Z",
            "values": [
              1285.018287144039
            ]
          },
          {
            "date": "2019-08-04T00:00:00.000Z",
            "values": [
              1609.8248538761077
            ]
          },
          {
            "date": "2019-08-05T00:00:00.000Z",
            "values": [
              1928.1346269123694
            ]
          },
          {
            "date": "2019-08-06T00:00:00.000Z",
            "values": [
              2239.252505709566
            ]
          },
          {
            "date": "2019-08-07T00:00:00.000Z",
            "values": [
              2567.553316537708
            ]
          },
          {
            "date": "2019-08-08T00:00:00.000Z",
            "values": [
              2868.3546668587237
            ]
          },
          {
            "date": "2019-08-09T00:00:00.000Z",
            "values": [
              3181.8057718361506
            ]
          },
          {
            "date": "2019-08-10T00:00:00.000Z",
            "values": [
              3507.3541870922836
            ]
          },
          {
            "date": "2019-08-11T00:00:00.000Z",
            "values": [
              3832.3010697659397
            ]
          },
          {
            "date": "2019-08-12T00:00:00.000Z",
            "values": [
              4141.769239830096
            ]
          },
          {
            "date": "2019-08-13T00:00:00.000Z",
            "values": [
              4450.789740479402
            ]
          },
          {
            "date": "2019-08-14T00:00:00.000Z",
            "values": [
              4760.750003037265
            ]
          },
          {
            "date": "2019-08-15T00:00:00.000Z",
            "values": [
              5082.707061283464
            ]
          },
          {
            "date": "2019-08-16T00:00:00.000Z",
            "values": [
              5402.755513699174
            ]
          },
          {
            "date": "2019-08-17T00:00:00.000Z",
            "values": [
              5712.435721903261
            ]
          }
        ],
        "compareParamValues": null,
        "modelParamValues": [
          {
            "date": "2019-08-01T00:00:00.000Z",
            "values": [
              649.639038001929
            ]
          },
          {
            "date": "2019-08-02T00:00:00.000Z",
            "values": [
              964.2866344908396
            ]
          },
          {
            "date": "2019-08-03T00:00:00.000Z",
            "values": [
              1285.018287144039
            ]
          },
          {
            "date": "2019-08-04T00:00:00.000Z",
            "values": [
              1591.7522346512985
            ]
          },
          {
            "date": "2019-08-05T00:00:00.000Z",
            "values": [
              1915.9929120649847
            ]
          },
          {
            "date": "2019-08-06T00:00:00.000Z",
            "values": [
              2230.8001591919383
            ]
          },
          {
            "date": "2019-08-07T00:00:00.000Z",
            "values": [
              2555.11916137351
            ]
          },
          {
            "date": "2019-08-08T00:00:00.000Z",
            "values": [
              2870.893872719468
            ]
          },
          {
            "date": "2019-08-09T00:00:00.000Z",
            "values": [
              3194.9105673007994
            ]
          },
          {
            "date": "2019-08-10T00:00:00.000Z",
            "values": [
              3505.9408060607725
            ]
          },
          {
            "date": "2019-08-11T00:00:00.000Z",
            "values": [
              3817.219505574745
            ]
          },
          {
            "date": "2019-08-12T00:00:00.000Z",
            "values": [
              4141.769239830096
            ]
          },
          {
            "date": "2019-08-13T00:00:00.000Z",
            "values": [
              4450.789740479402
            ]
          },
          {
            "date": "2019-08-14T00:00:00.000Z",
            "values": [
              4751.389884344803
            ]
          },
          {
            "date": "2019-08-15T00:00:00.000Z",
            "values": [
              5080.852101693725
            ]
          },
          {
            "date": "2019-08-16T00:00:00.000Z",
            "values": [
              5383.485961687603
            ]
          },
          {
            "date": "2019-08-17T00:00:00.000Z",
            "values": [
              5712.435721903261
            ]
          }
        ]
      }
    ]
  }
};