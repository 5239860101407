export const GET_ALERTSLIST_DATA_MOCK = {
  "data": {
    "getAlertNotifications": [
      {
        "date": "2019-08-06T13:14:08.921Z",
        "value": 12.08917887799402,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-08-06T08:11:24.367Z",
        "value": 7.281956859725522,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-08-06T03:17:56.084Z",
        "value": -3.9481166110968022,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-08-05T23:32:31.194Z",
        "value": 6.5239995199538825,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-08-05T16:05:34.271Z",
        "value": 4.997718060791339,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-08-05T05:29:36.136Z",
        "value": -13.556794137016658,
        "alert": {
          "id": "seolu-ALERT-gzyth",
          "vsModel": false,
          "parameter": {
            "id": "sedp-pmb",
            "label": "Piombo",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-08-05T04:44:43.479Z",
        "value": 0.9530245377259767,
        "alert": {
          "id": "ilk96-ALERT-kka52",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-08-04T22:54:14.746Z",
        "value": 16.98119865854418,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-08-04T22:12:28.991Z",
        "value": 4.862108337459894,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-08-04T21:41:45.284Z",
        "value": 17.287099575229796,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-08-03T19:24:03.910Z",
        "value": 17.006299121889356,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-08-03T18:01:31.225Z",
        "value": -3.4046477563521247,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-08-03T06:52:23.486Z",
        "value": 15.303030070747937,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-08-03T03:32:09.892Z",
        "value": -14.078190138716792,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-08-03T02:08:15.192Z",
        "value": 12.389279317021753,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-08-02T22:47:51.579Z",
        "value": 10.596069884673256,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-08-02T20:37:31.077Z",
        "value": -7.738198776106325,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-08-02T16:34:13.559Z",
        "value": 0.7208338026215664,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-08-02T15:42:23.800Z",
        "value": 8.613862685357695,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-08-02T03:46:20.868Z",
        "value": 4.637576472585863,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-08-01T22:26:59.894Z",
        "value": 8.838877313123291,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-08-01T16:32:05.901Z",
        "value": -6.456900924326909,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-08-01T14:05:49.629Z",
        "value": 2.854847133073232,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-08-01T06:09:06.784Z",
        "value": -7.100959770513545,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-31T17:40:16.355Z",
        "value": 0.5959207174987107,
        "alert": {
          "id": "1sfln-ALERT-pczye",
          "vsModel": false,
          "parameter": {
            "id": "ing-nno2",
            "label": "N_NO2",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-31T16:18:36.191Z",
        "value": 0.0411877346640388,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-31T11:54:08.259Z",
        "value": 1.7728394114441564,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-29T20:45:14.200Z",
        "value": 12.815477686669777,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-07-29T16:47:58.700Z",
        "value": -5.455265864817898,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-29T10:57:02.698Z",
        "value": -8.110283521680156,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-28T21:25:56.250Z",
        "value": 3.695133044708861,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-28T18:13:34.904Z",
        "value": 13.508508454560708,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-28T11:36:50.084Z",
        "value": -10.494503653151039,
        "alert": {
          "id": "ilk96-ALERT-kka52",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-28T07:44:16.961Z",
        "value": 17.587927673644828,
        "alert": {
          "id": "ilk96-ALERT-kka52",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-25T05:06:33.502Z",
        "value": -0.5629037941128274,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-25T04:00:52.777Z",
        "value": 15.513004841601674,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-25T00:54:19.152Z",
        "value": -5.585301813779505,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-24T23:38:20.046Z",
        "value": -6.086574392035818,
        "alert": {
          "id": "seolu-ALERT-gzyth",
          "vsModel": false,
          "parameter": {
            "id": "sedp-pmb",
            "label": "Piombo",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-07-23T20:45:41.782Z",
        "value": 3.710396113219822,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-07-23T17:08:05.924Z",
        "value": -14.806785265158464,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-23T14:09:36.758Z",
        "value": -12.886649368545765,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-23T13:35:00.053Z",
        "value": 5.087031200090159,
        "alert": {
          "id": "seolu-ALERT-gzyth",
          "vsModel": false,
          "parameter": {
            "id": "sedp-pmb",
            "label": "Piombo",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-07-23T05:03:58.821Z",
        "value": -10.679714653580952,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-23T05:00:43.666Z",
        "value": 16.411700725547995,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-23T02:28:01.407Z",
        "value": -4.1629248953164755,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-22T19:43:42.828Z",
        "value": 17.742722399305585,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-22T16:46:16.517Z",
        "value": -8.633256247886726,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-22T03:16:15.270Z",
        "value": -11.733806273945932,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-21T21:33:39.721Z",
        "value": -10.720087716134454,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-21T18:50:22.993Z",
        "value": 6.547220428872631,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-07-21T18:10:36.376Z",
        "value": -9.954864023057937,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-21T13:00:02.226Z",
        "value": 5.73375970826978,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-21T07:36:22.643Z",
        "value": 15.45782106893115,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-21T07:18:44.434Z",
        "value": 5.54771508991886,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-07-21T06:54:02.375Z",
        "value": 7.364655321717208,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-07-20T05:27:33.162Z",
        "value": 5.051455137162055,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-20T04:52:58.871Z",
        "value": 9.628077622857191,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-07-20T04:40:06.602Z",
        "value": 14.478014007060764,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-07-19T17:08:35.166Z",
        "value": 5.620727992577848,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-07-19T07:47:43.393Z",
        "value": 16.566358025415067,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-17T22:05:14.388Z",
        "value": 13.309019894994915,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-17T15:52:36.565Z",
        "value": 17.171125205468478,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-17T08:35:03.883Z",
        "value": -4.2040668749228765,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-17T03:54:01.944Z",
        "value": -4.926337803274489,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-16T21:43:29.579Z",
        "value": 2.945105903678872,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-16T00:29:14.871Z",
        "value": 10.943376448196574,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-14T11:11:28.780Z",
        "value": 11.345386798292353,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-14T08:57:16.782Z",
        "value": 18.6681929427681,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-13T17:35:05.420Z",
        "value": -12.664904726413088,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-07-13T00:33:23.876Z",
        "value": -0.051459364332300694,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-07-12T17:11:49.435Z",
        "value": 5.08764463865095,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-12T13:06:36.237Z",
        "value": -6.8750954243808575,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-11T21:45:57.126Z",
        "value": -9.90668274188748,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-11T16:57:35.158Z",
        "value": 15.499170885394243,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-11T14:59:36.515Z",
        "value": 3.124941954872927,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-11T06:28:19.990Z",
        "value": 19.227211701462807,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-11T06:22:50.689Z",
        "value": 1.8562150914228148,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-11T05:38:24.068Z",
        "value": -11.440068327527175,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-10T20:17:43.849Z",
        "value": 12.728785865249073,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-10T19:56:27.308Z",
        "value": -1.0248090057341628,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-07-10T19:12:31.312Z",
        "value": 2.638251002013,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-07-10T13:32:25.967Z",
        "value": 4.740270385303425,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-07-09T23:04:58.024Z",
        "value": 3.4487252306474474,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-09T10:13:47.657Z",
        "value": 5.464092021203342,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-09T10:01:13.550Z",
        "value": 19.357134766265396,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-08T10:38:42.357Z",
        "value": 15.033056933611373,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-08T07:20:13.553Z",
        "value": -14.967133673004833,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-07T15:48:59.239Z",
        "value": -13.764894625411767,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-07T05:34:31.727Z",
        "value": 6.6088965816040215,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-06T22:40:47.904Z",
        "value": 5.105519492632318,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-05T22:33:19.529Z",
        "value": -8.720767734142093,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-04T20:27:10.669Z",
        "value": -4.360393072294942,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-04T07:17:56.831Z",
        "value": -11.849689882835024,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-03T10:59:34.067Z",
        "value": -14.949747002666147,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-03T09:35:52.763Z",
        "value": -5.734495481779799,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-02T23:40:25.306Z",
        "value": -4.733392081709397,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-07-02T22:24:51.358Z",
        "value": 19.308788929836815,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-02T18:16:33.886Z",
        "value": -10.921114581165998,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-07-02T15:11:49.446Z",
        "value": -7.471073253068087,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-02T09:11:36.802Z",
        "value": -6.769607355134154,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-02T04:35:12.212Z",
        "value": 17.246233272647594,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-07-02T01:49:38.997Z",
        "value": 17.103440144257682,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-07-01T20:26:19.281Z",
        "value": 1.879448056949908,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-07-01T19:50:20.459Z",
        "value": -5.08659674227294,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-30T19:40:00.350Z",
        "value": 0.591115445913605,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-30T13:48:58.054Z",
        "value": -1.8220669151576256,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-06-30T11:48:12.459Z",
        "value": 2.817551047533911,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-29T22:23:22.653Z",
        "value": 0.7452448978421664,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-28T21:26:31.631Z",
        "value": -5.6485468515738795,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-27T03:35:06.216Z",
        "value": 15.710470283421493,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-06-27T01:30:01.405Z",
        "value": 5.547362201592367,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-26T16:54:40.810Z",
        "value": 3.6787135370115465,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-26T03:51:48.050Z",
        "value": -11.024546135639095,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-06-26T00:53:30.627Z",
        "value": -4.408759108750948,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-25T15:34:31.584Z",
        "value": 3.0701447713862073,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-25T14:08:38.266Z",
        "value": 4.307379737693459,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-25T12:43:52.274Z",
        "value": -11.113210885576276,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-25T05:18:25.846Z",
        "value": 17.372650503136335,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-25T03:55:00.309Z",
        "value": -11.798941750135246,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-25T01:43:03.370Z",
        "value": -9.020482761467221,
        "alert": {
          "id": "ilk96-ALERT-kka52",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-06-25T00:11:22.731Z",
        "value": 15.553353933104091,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-06-24T19:54:28.177Z",
        "value": -9.211005474983384,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-24T10:27:19.602Z",
        "value": -4.717205332748552,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-23T09:29:54.982Z",
        "value": -12.880006496539902,
        "alert": {
          "id": "seolu-ALERT-gzyth",
          "vsModel": false,
          "parameter": {
            "id": "sedp-pmb",
            "label": "Piombo",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-22T16:30:19.052Z",
        "value": 15.449382488424163,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-22T11:25:25.726Z",
        "value": 4.562352669567758,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-21T21:24:36.159Z",
        "value": -1.3730012676370897,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-06-21T12:47:41.585Z",
        "value": -7.242325023653006,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-21T06:38:35.680Z",
        "value": 10.178693832867758,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-21T01:40:38.014Z",
        "value": -5.753585802370562,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-20T09:27:48.642Z",
        "value": 19.76813207008651,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-20T03:08:28.778Z",
        "value": 16.484554719854536,
        "alert": {
          "id": "1sfln-ALERT-pczye",
          "vsModel": false,
          "parameter": {
            "id": "ing-nno2",
            "label": "N_NO2",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-06-19T20:38:55.133Z",
        "value": 11.86639616357224,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-19T06:34:42.577Z",
        "value": 10.50965804140328,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-18T22:31:20.432Z",
        "value": 8.929746541009266,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-06-18T19:33:18.916Z",
        "value": 6.445560526095534,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-18T01:04:07.010Z",
        "value": -4.8681658669727295,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-17T14:47:51.634Z",
        "value": -4.639315944355491,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-16T23:29:52.130Z",
        "value": -8.554875638660878,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-06-16T21:12:54.097Z",
        "value": -9.893558909656793,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-16T16:27:00.535Z",
        "value": -10.995739177689957,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-16T16:25:04.615Z",
        "value": 2.69298083808744,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-06-16T15:49:29.120Z",
        "value": 15.40335316481271,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-16T14:35:37.589Z",
        "value": -3.778740850261636,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-16T00:35:08.328Z",
        "value": 1.725656931225366,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-15T01:13:57.115Z",
        "value": -2.9003405711815997,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-14T22:53:17.474Z",
        "value": 6.706357232687349,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-06-14T20:33:46.734Z",
        "value": -11.049138485149022,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-14T20:12:23.326Z",
        "value": -3.458132126260754,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-14T10:41:45.644Z",
        "value": 3.165294676169161,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-06-14T06:26:38.683Z",
        "value": -10.066355207139456,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-13T15:05:35.038Z",
        "value": 11.673162669544947,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-12T20:47:51.259Z",
        "value": -4.756859620080441,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-12T14:07:17.746Z",
        "value": 8.861356589169436,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-12T09:06:24.658Z",
        "value": -7.354594219592685,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-12T08:59:21.876Z",
        "value": -13.164729055648637,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-11T19:00:27.418Z",
        "value": 16.432726249277525,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-06-11T06:16:31.287Z",
        "value": 12.783869674819751,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-11T04:42:38.178Z",
        "value": 0.8112172407061529,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-11T01:34:49.181Z",
        "value": -3.9186869704474816,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-06-11T00:30:51.687Z",
        "value": -9.175416964007297,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-10T21:28:44.776Z",
        "value": -9.665860866029064,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-10T21:00:30.997Z",
        "value": 11.348043090128574,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-10T17:54:23.990Z",
        "value": 5.510765158286482,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-10T13:48:39.190Z",
        "value": -0.15134319896641912,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-10T11:36:58.470Z",
        "value": 14.344166740426878,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-10T04:09:20.787Z",
        "value": -7.727326354963461,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-06-10T01:55:07.061Z",
        "value": 15.943818208073907,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-10T01:27:00.070Z",
        "value": 19.34001785295692,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-09T22:06:46.520Z",
        "value": -8.126866964400458,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-06-09T18:12:29.369Z",
        "value": -3.0770453749761124,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-09T17:25:21.649Z",
        "value": -14.081256369099993,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-09T10:03:41.287Z",
        "value": 4.338085038058178,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-09T05:33:21.549Z",
        "value": 19.559942190485266,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-06-08T07:58:43.900Z",
        "value": -9.174267866104152,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-08T03:29:20.625Z",
        "value": 17.897423941555175,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-07T21:02:13.103Z",
        "value": 11.84729890380067,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-07T17:32:50.914Z",
        "value": 7.521955091770284,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-07T12:00:42.574Z",
        "value": 14.933261479285424,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-07T01:53:45.496Z",
        "value": -12.20649512527831,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-06T08:17:34.306Z",
        "value": -11.738715079511866,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-06T01:03:57.113Z",
        "value": -5.370408439202734,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-06-05T19:13:39.121Z",
        "value": -7.158595724480587,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-05T11:31:15.330Z",
        "value": -5.190874793596699,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-06-04T22:50:51.245Z",
        "value": 3.414547903812011,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-04T13:48:55.629Z",
        "value": 1.4508836534100453,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-04T04:19:02.756Z",
        "value": -7.925268035360694,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-03T22:54:31.319Z",
        "value": -9.483723492776548,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-03T20:05:28.721Z",
        "value": -0.06113874020203269,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-03T12:04:36.329Z",
        "value": 7.600597357949578,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-03T08:30:25.235Z",
        "value": -6.250099357073909,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-06-03T05:03:44.294Z",
        "value": -14.184872521863344,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-02T15:16:52.512Z",
        "value": -5.003177358762693,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-06-02T10:03:08.325Z",
        "value": -6.25499251888712,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-06-02T04:30:51.141Z",
        "value": 9.00274546665327,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-06-01T18:16:57.394Z",
        "value": -6.7360363231675375,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-06-01T12:37:44.758Z",
        "value": 10.022642087766354,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-31T14:56:40.496Z",
        "value": 16.307917714718386,
        "alert": {
          "id": "1sfln-ALERT-pczye",
          "vsModel": false,
          "parameter": {
            "id": "ing-nno2",
            "label": "N_NO2",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-31T01:44:13.837Z",
        "value": 1.4557704970494534,
        "alert": {
          "id": "ilk96-ALERT-kka52",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-30T17:05:43.880Z",
        "value": 16.439682546543658,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-05-28T22:14:06.844Z",
        "value": 16.24324931684787,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-28T19:31:53.797Z",
        "value": 1.6249163057857068,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-28T16:01:51.258Z",
        "value": 17.81642422012657,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-28T06:19:17.591Z",
        "value": 6.909736038763246,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-28T05:10:03.698Z",
        "value": 6.7041125431013135,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-27T22:20:02.096Z",
        "value": -2.442751125700511,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-27T18:38:33.678Z",
        "value": -14.000619700871246,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-27T18:17:35.018Z",
        "value": -11.015597412055117,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-26T23:35:22.431Z",
        "value": -2.642241850912134,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-05-26T21:42:38.648Z",
        "value": 7.983981718990336,
        "alert": {
          "id": "ilk96-ALERT-kka52",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-26T14:46:26.157Z",
        "value": -13.539777719175373,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-05-26T04:18:42.903Z",
        "value": -7.003526498892796,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-26T02:51:42.939Z",
        "value": 0.7677028425069103,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-05-25T20:47:33.728Z",
        "value": 19.287341288658965,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-25T19:51:10.287Z",
        "value": -11.992900732726438,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-05-25T11:06:15.805Z",
        "value": -10.28805127166187,
        "alert": {
          "id": "seolu-ALERT-gzyth",
          "vsModel": false,
          "parameter": {
            "id": "sedp-pmb",
            "label": "Piombo",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-25T02:00:32.098Z",
        "value": -5.71546737978306,
        "alert": {
          "id": "1sfln-ALERT-pczye",
          "vsModel": false,
          "parameter": {
            "id": "ing-nno2",
            "label": "N_NO2",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-24T22:05:46.882Z",
        "value": -3.3203366268838312,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-24T17:57:23.485Z",
        "value": -5.649165604194511,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-24T15:29:00.056Z",
        "value": 6.03483588110992,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-05-22T23:21:25.239Z",
        "value": 4.9331197626033045,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-22T19:32:13.716Z",
        "value": -11.808772241498504,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-22T05:40:40.742Z",
        "value": 5.603137152340555,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-22T05:25:26.698Z",
        "value": 4.5686283731588695,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-05-21T12:43:34.338Z",
        "value": 7.353988812284761,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-21T10:06:50.373Z",
        "value": 19.383568947176308,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-21T08:37:18.213Z",
        "value": 16.78495495606259,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-20T08:05:25.945Z",
        "value": -13.270265654103653,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-19T20:57:58.621Z",
        "value": 10.548636500986955,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-05-19T18:43:16.439Z",
        "value": 3.9914514943962374,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-05-19T14:26:57.322Z",
        "value": -11.016023366625603,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-19T11:50:24.512Z",
        "value": -1.1865719283526808,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-19T11:01:33.056Z",
        "value": 12.102025648975768,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-19T07:02:55.326Z",
        "value": 17.106522030647888,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-05-18T14:31:13.939Z",
        "value": 17.732715547102742,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-17T18:46:06.750Z",
        "value": 9.269042561275835,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-17T12:21:36.730Z",
        "value": -1.7365915192637313,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-05-17T08:42:23.820Z",
        "value": 19.136213553187822,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-17T06:23:37.846Z",
        "value": 5.690929021180498,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-17T00:18:53.098Z",
        "value": -1.2849737604385467,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-16T23:56:11.440Z",
        "value": -14.58350924856758,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-16T21:39:44.546Z",
        "value": -6.5425962476252995,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-16T12:37:28.686Z",
        "value": 0.4268645829650204,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-16T10:43:00.432Z",
        "value": 9.726624751716852,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-16T09:57:41.490Z",
        "value": 7.562949738208204,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-16T04:25:30.008Z",
        "value": 10.675371118212766,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-16T03:32:40.753Z",
        "value": -13.712467651555928,
        "alert": {
          "id": "t88lb-ALERT-v0r18",
          "vsModel": false,
          "parameter": {
            "id": "ing-ss105",
            "label": "SS105",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-15T08:33:22.394Z",
        "value": -5.933825666318205,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-15T08:27:18.385Z",
        "value": -11.467574975932001,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-14T22:24:54.338Z",
        "value": 3.021910727664288,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-14T16:15:52.124Z",
        "value": 10.907229064602241,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-05-14T07:32:58.632Z",
        "value": -2.085369158739267,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-14T01:28:23.782Z",
        "value": 16.014964411701992,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-13T23:43:28.626Z",
        "value": 17.34230215188377,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-13T17:27:07.766Z",
        "value": 2.3988480273534236,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-13T14:23:11.636Z",
        "value": 0.4019947619312543,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-13T13:33:46.462Z",
        "value": 17.378024525448645,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-12T23:41:13.466Z",
        "value": -12.315460113238771,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-11T19:40:29.875Z",
        "value": 8.08951530482883,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-11T17:14:09.818Z",
        "value": 12.164422980346924,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-11T08:32:28.943Z",
        "value": -9.618726320912323,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-05-10T20:58:29.320Z",
        "value": -8.578985125855649,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-10T12:19:57.730Z",
        "value": -9.990070432216042,
        "alert": {
          "id": "9di22-ALERT-xfc29",
          "vsModel": false,
          "parameter": {
            "id": "den-sed",
            "label": "Sed.",
            "section": {
              "id": "denitrificazione"
            }
          }
        }
      },
      {
        "date": "2019-05-10T06:45:30.405Z",
        "value": 16.977918691756287,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-09T12:57:58.412Z",
        "value": -3.0528189458593893,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-09T09:03:24.628Z",
        "value": -9.690488379135465,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-09T05:55:03.567Z",
        "value": -3.865114691722791,
        "alert": {
          "id": "r92x8-ALERT-ggci2",
          "vsModel": true,
          "parameter": {
            "id": "sedp-svi",
            "label": "SVI",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-08T16:04:59.097Z",
        "value": -10.504527562009379,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-05-08T13:38:25.397Z",
        "value": 12.608672938611718,
        "alert": {
          "id": "seolu-ALERT-gzyth",
          "vsModel": false,
          "parameter": {
            "id": "sedp-pmb",
            "label": "Piombo",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-08T11:33:48.568Z",
        "value": 13.20861487425367,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-08T09:57:56.216Z",
        "value": 13.551886899696413,
        "alert": {
          "id": "1sfln-ALERT-pczye",
          "vsModel": false,
          "parameter": {
            "id": "ing-nno2",
            "label": "N_NO2",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-08T02:09:32.944Z",
        "value": -5.525398864152047,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-05-07T06:54:20.198Z",
        "value": 4.525412910204764,
        "alert": {
          "id": "ilk96-ALERT-kka52",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-06T17:55:00.774Z",
        "value": -1.0595257250693724,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-05T22:48:08.901Z",
        "value": -1.344665103696025,
        "alert": {
          "id": "sphoy-ALERT-xsbqb",
          "vsModel": false,
          "parameter": {
            "id": "sedb-bod",
            "label": "BOD",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-05T18:00:23.676Z",
        "value": 14.734665235843675,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-05-05T05:27:01.072Z",
        "value": 16.99698284549463,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-04T23:11:10.135Z",
        "value": 0.4690278935376053,
        "alert": {
          "id": "4shio-ALERT-3ojax",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-04T18:15:55.737Z",
        "value": -9.873005045523461,
        "alert": {
          "id": "gj0s8-ALERT-hwc1r",
          "vsModel": false,
          "parameter": {
            "id": "pre-solvclor",
            "label": "Solv. Clorurati",
            "section": {
              "id": "preaccumulo"
            }
          }
        }
      },
      {
        "date": "2019-05-04T05:04:14.814Z",
        "value": 7.372365471332898,
        "alert": {
          "id": "seolu-ALERT-gzyth",
          "vsModel": false,
          "parameter": {
            "id": "sedp-pmb",
            "label": "Piombo",
            "section": {
              "id": "sedimentazione_primaria"
            }
          }
        }
      },
      {
        "date": "2019-05-04T00:50:35.238Z",
        "value": 4.847189711916563,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-03T20:23:25.671Z",
        "value": 13.254961184548776,
        "alert": {
          "id": "wfq51-ALERT-e4xvo",
          "vsModel": false,
          "parameter": {
            "id": "civ-nichl",
            "label": "Nichel",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-03T15:26:42.848Z",
        "value": 0.0036483423089030964,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-03T10:00:22.829Z",
        "value": -0.5590735948047705,
        "alert": {
          "id": "3xe7q-ALERT-efs9x",
          "vsModel": false,
          "parameter": {
            "id": "civ-br",
            "label": "BORO",
            "section": {
              "id": "ingresso_civile"
            }
          }
        }
      },
      {
        "date": "2019-05-02T23:35:48.259Z",
        "value": -9.307318312478337,
        "alert": {
          "id": "ilk96-ALERT-kka52",
          "vsModel": false,
          "parameter": {
            "id": "ing-toc",
            "label": "TOC",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      },
      {
        "date": "2019-05-02T22:19:38.304Z",
        "value": -13.501965155737848,
        "alert": {
          "id": "huce5-ALERT-drudn",
          "vsModel": false,
          "parameter": {
            "id": "sedb-oss",
            "label": "Ossigeno",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-02T11:01:54.360Z",
        "value": -11.135632164798688,
        "alert": {
          "id": "th54z-ALERT-lnwxd",
          "vsModel": false,
          "parameter": {
            "id": "sedb-rme",
            "label": "Rame",
            "section": {
              "id": "sedimentazione_biologica"
            }
          }
        }
      },
      {
        "date": "2019-05-01T17:31:55.582Z",
        "value": -7.164950365089439,
        "alert": {
          "id": "1sfln-ALERT-pczye",
          "vsModel": false,
          "parameter": {
            "id": "ing-nno2",
            "label": "N_NO2",
            "section": {
              "id": "ingresso_industriale"
            }
          }
        }
      }
    ]
  }
};