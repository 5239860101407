import { LayoutDataSource } from '@n7-frontend/core';
import { map, takeUntil } from 'rxjs/operators';

import { Apollo } from '@app/providers';
import { Subject } from 'rxjs';
import { Config } from '@app/constants';

export class ListAlertsLayoutDS extends LayoutDataSource {
  private translate: any;
  private apollo: Apollo;
  private route: any;
  public destroy$: Subject<any> = new Subject();
  public loading: boolean = false;
  public hasData = true;

  protected preloadedLabels = {
    'model': 'i18n.model',
    'ingresso_industriale' : 'i18n.headers.ingresso_industriale',
    'ingresso_civile' : 'i18n.headers.ingresso_civile',
    'preaccumulo' : 'i18n.headers.preaccumulo',
    'sedimentazione_primaria' : 'i18n.headers.sedimentazione_primaria',
    'denitrificazione' : 'i18n.headers.denitrificazione',
    'sedimentazione_biologica': 'i18n.headers.sedimentazione_biologica',
  };

  onInit(payload){
    this.translate = payload.translate;
    this.apollo = payload.apollo;
    this.route = payload.route;

    // preloaded translations
    this._loadTranslatedLabels();

    // listen to router changes
    this._listenRouteChanges();
  }

  private _listenRouteChanges(){
    this.route.params.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      const page = params.page ? +params.page : 1,
        limit = Config.get('paginationLimit'),
        offset = page === 1 ? 1 : (page * limit) + 1;

      const apolloRequest$ = this.apollo.request$('getAlertNotifications', {
        pagination: { limit, offset }
      });
      apolloRequest$.subscribe(response => this._handleDataRequest(response));
  
      // set pagination path
      this.one('pagination').updateOptions({ basePath: 'list-alerts/', current: page });
    });
  }

  private _handleDataRequest({ totalCount, items }){
    this.hasData = !!totalCount;

    this.one('pagination').update({ total: totalCount });
    this.one('list-alerts-table').update(items);

    this.loading = false;
  }
  
  protected _loadTranslatedLabels(){
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe(labels => {
      // update widgets that need preloaded labels
      this.one('list-alerts-table').updateOptions({ labels });
    });
  }

  onTableCellClick(payload){
    console.log('tableCellClicked with following payload : ' , payload);
  }

  onTableCellDoubleClick(payload){
    console.log('tableCellDoubleClicked with following payload : ' , payload);
  }
}