import { EventHandler } from '@n7-frontend/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeLayoutDS } from '../home-layout/home-layout.ds';
import { ChartLayoutDS } from './chart-layout.ds';

export class ChartLayoutEH extends EventHandler {
  private destroyed$: Subject<any> = new Subject();

  public listen() {
    // listen to inner (layout) events
    this.innerEvents$.subscribe(event => {
      let requestPayload;
      switch(event.type){
        case 'chart-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        case 'chart-layout.destroy':
            this.destroyed$.next();
            break;

        case 'chart-layout.datepicker_submit':
          requestPayload = this.dataSource.onDatepickerSubmit();
          this._doChartRequest(requestPayload, 'FIXED_DATES');
          break;

        case 'chart-layout.compareCheckboxChange':
          requestPayload = this.dataSource.onCompareCheckboxChange(event.payload);
          if(event.payload) {
            this._doCompareChartRequest(requestPayload);
          } else {
            requestPayload = this.dataSource.getCurrentDates();
            this._doChartRequest(requestPayload);
          }
          this.emitOuter('compareCheckboxChange', event.payload);
          break;

        case 'chart-layout.datepicker_compare_submit':
          requestPayload = this.dataSource.onCompareDatepickerSubmit();
          this._doCompareChartRequest(requestPayload);
          break;

        case 'chart-layout.loadAlertModal':
          this.dataSource.onLoadAlertModal();
          this.emitGlobal('openAlertModal', {
            elementId: this.dataSource.getElementId(),
            isModel: this.dataSource.isModel(),
          });
          break;

        default: 
          break;
      }
    });
    
    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'chart-legend.change':
          this.emitOuter(event.type, event.payload);
          break;

        case 'chart-select-filter.click':
          if(event.payload._id !== 'custom'){
            this._doChartRequest(event.payload, 'LAST_N_DAYS');
          } else {
            this.dataSource.openDatepicker();

            this.emitOuter('custom-date-selected');
          }
          break;

        case 'compare-select-filter.click':
          if(event.payload._id !== 'custom'){
            this._doCompareChartRequest(event.payload);
          } else {
            this.dataSource.openCompareDatepicker();
            this.emitOuter('custom-date-compare-selected');
          }
          break;

        case 'chart-datepicker.change':
          this.dataSource.onDatepickerChange(event);
          break;

        case 'chart-datepicker-compare.change':
          this.dataSource.onCompareDatepickerChange(event);
          break;

        case 'chart-actions.click':
          if(event.payload === 'alarm'){
            if(this.dataSource.hasAlerts()){
              this.dataSource.toggleAlertsTablePopover();
            } else {
              this.emitGlobal('openAlertModal', {
                elementId: this.dataSource.getElementId(),
                isModel: this.dataSource.isModel()
              });
            }
          } else if(event.payload === 'dashboard'){
            const mode = this.dataSource.chartMode, 
              chartPeriod = this.dataSource.chartPeriod,
              numOfDays = this.dataSource.numOfDays,
              { startDate, endDate } = this.dataSource.getCurrentDates(),
              { startDate: compareStartDate, endDate: compareEndDate } = this.dataSource.getCurrentCompareDates();

            let parameterIds = [];
            if(this.dataSource.getElementId()) {
              parameterIds = [this.dataSource.getElementId()];
            } else if(this.dataSource.source === 'overview') {
              parameterIds = this.dataSource.overviewParameterIds;
            } else if(this.dataSource.source === 'custom_chart') {
              parameterIds = ChartLayoutDS.customChartData.parameters.map(p => p.id);
            }

            const reqConfig = {
              chartInput: {
                // id: this.dataSource.getChartId() || null,
                '$mode' : mode || "NORMAL",
                '$chartPeriod' : chartPeriod,
                numOfDays,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                parameterIds
              }
            };

            this.dataSource.addToDashboardRequest(reqConfig).subscribe(chart => {
              this.emitGlobal('navigate', ['/']);

              // update new chart id
              HomeLayoutDS.newChartId = chart.id;
            });
          }
          break;

        default: 
          break;
      }
    });

    EventHandler.globalEvents$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(({ type, payload }) => {
      switch(type){
        case 'global.tools-click':
          if(payload.source === 'create-custom-chart'){
            this.dataSource.deleteCustomChart$(payload.id).subscribe(() => {
              this.emitGlobal('navigate', ['/customized-view']);
            });
          } else if(payload.source === 'open-reorder-widgets-modal'){
            console.log(type, payload);
          }
          break;

        default:
          break;
      }
    });
  }

  private _doChartRequest(payload, chartPeriod?){
    if(chartPeriod) this.dataSource.chartPeriod = chartPeriod;

    const chartRequest$ = this.dataSource.chartRequest(payload); 
    chartRequest$.subscribe(response => {
      this.dataSource.loading = false;
      if(Array.isArray(response.values) && response.values.length){
        this.emitOuter('chart-request', response);
      }

      // update empty text
      this.dataSource.updateEmptyText(response);
    });
  }

  private _doCompareChartRequest(payload){
    const chartRequest$ = this.dataSource.compareChartRequest(payload); 
    chartRequest$.subscribe(response => {
      this.dataSource.loading = false;
      if(Array.isArray(response.values) && response.values.length){
        this.emitOuter('compare-chart-request', response);
      }
    });
  }
}