import { EventHandler } from '@n7-frontend/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class ManageAlertsLayoutEH extends EventHandler {
  private destroyed$: Subject<any> = new Subject();

  public listen() {
    // listen to inner (layout) events
    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'manage-alerts-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        case 'manage-alerts-layout.destroy':
            this.destroyed$.next();
            break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(({ type, payload: evPayload }) => {
      if ( type === 'manage-alerts.click') {
        const { payload, source } = evPayload;

        switch (source) {
          case "edit":
            this.emitOuter (event.type, evPayload);
            break;

          case "delete":
            this.dataSource.onDeleteClick(payload);
            break;

          default:
            break;
        }

      } else if(type === 'pagination.change'){
        this.dataSource.loading = true;
      } else if(type === 'manage-alerts-delete-confirmation-modal.click' ){
        switch (evPayload) {
          case "submit":
            this.dataSource.onDeleteSubmitClick();
            break;

          case "dismiss":
            this.dataSource.closeDeleteConfirmationModal();
            break;

          default:
            break;
        }
      } else if(type === 'manage-alerts-alert.click' && evPayload === 'close'){
        this.dataSource.closeAlert();
      }
    });

    EventHandler.globalEvents$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(({ type, payload }) => {
      switch(type){
        case 'global.alertSettingsUpdated':
          this.dataSource.onAlertSettingsUpdated();
          break;

        default:
          break;
      }
    });
  }

}