import { DataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';

export class ElementsAccordionDS extends DataSource {
  private _selectedElements: string[] = [];
  public modalDescription: string = '';

  protected transform(data) {
    if(!this.options || !this.options.labels) return;

    const elementsConfig = Config.get('elements'),
      sectionsConfig = Config.get('sections'),
      filteredElements = Object.keys(elementsConfig)
        .filter(key => !elementsConfig[key].mode)
        .map(key => elementsConfig[key]);

    let items = [];
    Object.keys(sectionsConfig).forEach(key => {
      const section = sectionsConfig[key],
        sectionElements = filteredElements.filter(el => el.section.id === key);

      items.push({
        payload: section.id,
        header: {
          label: section.label, 
          sublabel: '', 
          icon: 'n7-icon-angle-down'
        },
        content: {
          classes: '',
          elements: sectionElements.map(el => ({
            color: el.color, 
            label: el.label,
            icon: 'n7-icon-circle-full',
            payload: el.id,
            classes: '',
            _meta: {
              id: el.id
            }
          }))
        },
        _meta: {
          id: section.id,
          isOpen: false
        }
      });
    });

    // update modal description
    this._updateModalDescription();

    return { items };
  }

  getSelectedElements(){
    return this._selectedElements;
  }

  onClick(payload){
    let { items } = this.output;
    items.forEach(item => {
      // update / show
      if(item._meta.id === payload && !item._meta.isOpen){
        item._meta.isOpen = true;
        item.content.classes = 'is-open';
        item.header.icon = 'n7-icon-angle-up';
      
      // reset / hide
      } else {
        item._meta.isOpen = false;
        item.content.classes = '';
        item.header.icon = 'n7-icon-angle-down';
      }
    });
  }

  onModalDismiss(){
    let { items } = this.output;
    this._selectedElements = [];

    items.forEach(item => {
      // reset elements
      item.content.elements.forEach(el => {
        el._meta.isSelected = false;
        el.classes = '';
      });

      // reset item
      item._meta.isOpen = false;
      item.content.classes = '';
      item.header.icon = 'n7-icon-angle-down';

      // update parent sublabel
      this._updateParentSelectedLabel(item._meta.id);
    });

    // update modal description
    this._updateModalDescription();
  }

  onElementClick(payload){
    let { items } = this.output;
    const selectedLength = this._selectedElements.length;

    items.forEach(item => {
      item.content.elements.forEach(element => {
        const elementId = element._meta.id;
        // update / selected
        if(elementId === payload){
          if(!element._meta.isSelected && Config.get('selectedElementsLimit') > selectedLength){
            element._meta.isSelected = true;
            element.classes = 'is-selected';
            this._selectedElements.push(elementId);
          } else if(element._meta.isSelected) {
            element._meta.isSelected = false;
            element.classes = '';
            this._selectedElements.splice(this._selectedElements.indexOf(elementId), 1);
          } 

          // update parent sublabel
          this._updateParentSelectedLabel(item._meta.id);

          // update modal description
          this._updateModalDescription();
        }
      });
    });
  }

  private _updateParentSelectedLabel(parentId){
    let { items } = this.output;
    const parentItem = items.filter(item => item._meta.id === parentId)[0];

    let parentSelected = [];

    parentItem.content.elements.forEach(el => el._meta.isSelected ? parentSelected.push(el) : null);
    parentItem.header.sublabel = parentSelected.map(el => el.label).join(', ');
  }

  private _updateModalDescription(){
    const number = this._selectedElements.length,
      limit = Config.get('selectedElementsLimit'),
      diff = limit - number, 
      { labels } = this.options; 

    let label = labels.multi;
    if(diff === 0){
      label = labels.noparams;
    } else if(diff === 1) {
      label = labels.single;
    }

    this.modalDescription = label.replace('__NUMBER__', diff);
  }
}
