import { EventHandler } from '@n7-frontend/core';

export class ChartSelectFilterEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'chart-select-filter.click': 
          if(event.payload.isSelected || this.dataSource.isCompareActive()) return;
          this.dataSource.onClick(event);

          this.emitOuter('click', event.payload);
          break;
        
        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      if(event.type === 'chart-layout.compareCheckboxChange'){
        this.dataSource.onCompareCheckboxChange(event.payload);
      }
    });
  }
}