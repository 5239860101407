import { LayoutDataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';
import { dateHelpers, helpers } from '@app/helpers';
import { map, takeUntil, catchError } from 'rxjs/operators';
import { Apollo } from '@app/providers';
import { Subject } from 'rxjs';


export class TitrimetroInputLayoutDS extends LayoutDataSource {
  private apollo: Apollo;
  private route: any;
  private inputDate: Date;
  private editingData: any = {};
  private translate: any;
  private labels;
  protected preloadedLabels = {
    editModalTitlePrefix: 'i18n.components.titrimetro_input_edit_form.title_prefix',
    editModalSubTitlePrefix: 'i18n.components.titrimetro_input_edit_form.sub_title_prefix',
    placeholderPrefix: 'i18n.layouts.titrimetro_input.insert_value_in'
  };
  public destroy$: Subject<any> = new Subject();
  public loading: boolean = true;
  public errorText: string = '';

  private dateFormatRLong = Config.get('dateStringFormatReverseLong');

  private closeAlertTimeout = null;
  private alertCloseTimeOutTime:number = 3500;

  onInit(payload){
    this.apollo = payload.apollo;
    this.route = payload.route;
    this.translate = payload.translate;
    this._loadTranslatedLabels();
    this.getAndSetAvailableFields();
    // this.getAndSetOldDataHistory();

    var today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    this.inputDate = today;
    this.one('titrimetro-input-datepicker').update(this.inputDate);

    // listen to router changes
    this._listenRouteChanges();
  }



  getAndSetAvailableFields(){
    const apolloRequest$ = this.apollo.request$('getTitrimeterCurrentData');
    apolloRequest$.subscribe(response => this.one('titrimetro-input-form').update(response));
  }


  /* getAndSetOldDataHistory() {
    const apolloRequest$ = this.apollo.request$('getOldTitrimeterData', { startDate: "2017-06", endDate: "2018-07"} );
    apolloRequest$.subscribe(response => this.one('titrimetro-input-old-inputs').update(response));
  } */


  onFormSubmit(submitData: any): void{
      if(submitData){

        var apolloSubmitData = {
          date: (this.inputDate).toISOString(),
          author: "Giulio Andreini", // TO DO: author from login/config
          sections: []
        };

        Object.keys(submitData).forEach(function(skey) {
          let section = { id: skey , fields: []};

          Object.keys(submitData[skey]).forEach(function(fkey) {
            if( submitData[skey][fkey].wasEdited ){
              section.fields.push({
                id: fkey,
                value: submitData[skey][fkey].value
              });
            }
          });

          if(section.fields.length>0) apolloSubmitData.sections.push(section);
        });

        // TO DO: sections and fields have only ID, ok? or label is also needed?

        const apolloRequest$ = this.apollo.request$('addTitrimeter', {"titrimeter": apolloSubmitData} );
        apolloRequest$.subscribe(response => {
          console.log('POST response: ',response);
          if (response.errorMessage)
            this.showAlert(false, this.inputDate)
          else
            this.showAlert(true,this.inputDate);
          //}
        });
      }
  }

  showAlert(success:boolean,date: Date,message?:any){
    helpers.scrollToDomElement(document.getElementById('titrimeterInputAlert'));
    var dateStr = dateHelpers.toString(new Date(date),this.dateFormatRLong);
    var data;
    if(success){
      data = {
        text: `
        I dati del <strong>${dateStr}</strong> sono stati inseriti,
        <br>
        puoi rivederli e modificarli dalla lista sottostante
        `,
        hasCloseButton: true,
        payload: "close",
        classes: "is-success"
      }
    } else {
      var text;
      if(message) text = message;
      else text = `
      Errore durante l'inserimento dei dati per il giorno <strong>${dateStr}</strong>
      <br>
      Dati gia' presenti, modificare il corrispondente item dalla lista in basso`
      data = {
        text: text,
        hasCloseButton: true,
        payload: "close",
        classes: "is-error"
      }
    }
    this.one('titrimetro-input-alert').update(data);
    if(this.closeAlertTimeout!=null){
      clearTimeout(this.closeAlertTimeout);
      this.closeAlertTimeout=null;
    }
    this.closeAlertTimeout = setTimeout(() => {
      this.closeAlert();
      this.closeAlertTimeout = null;
    },this.alertCloseTimeOutTime);
  }

  closeAlert(){
    this.one('titrimetro-input-alert').update(null);
  }

  onDatePickerChange(payload){
    // warning: the new date specified could be the same as the previous one!
    //          the datepicker change event triggers anyway!
    this.inputDate = payload.selectedDates[0];
  }

  onEditFormSubmit(submitData: any,editing: boolean): void{
    console.log('submitting edit',submitData);
    if(submitData){

      var apolloSubmitData = {
        date: this.editingData.date,
        id: this.editingData.id,
        author: "Giulio Andreini", // TO DO: author from login/config
        sections: []
      };

      Object.keys(submitData).forEach(function(skey) {
        let section = { id: skey , fields: []};

        Object.keys(submitData[skey]).forEach(function(fkey) {
          if( submitData[skey][fkey].wasEdited &&
            submitData[skey][fkey].value!==submitData[skey][fkey].originalValue){
            section.fields.push({
              id: fkey,
              value: submitData[skey][fkey].value
            });
          }
        });

        if(section.fields.length>0) apolloSubmitData.sections.push(section);
      });

      // TO DO: sections and fields have only ID, ok? or label is also needed?

      const apolloRequest$ = this.apollo.request$('addTitrimeter', {"titrimeter": apolloSubmitData} );
      const editDate = this.editingData.date;
      apolloRequest$.subscribe(response => {
        console.log('POST response: ',response);
        // TO DO , EDGAR : can we modify the apollo provider to
        //   get here the http errors (they contain the apollo error messages)
        // IF ERROR
        //if(response.status != 'ok'){
        //  this.showAlert(false,this.inputDate,response.message);
        //} else {
          this.showAlert(true,editDate);
        //}
      });
    }
    if(editing)
      this.hideModal();
  }


  showModal(payload){
    this.editingData = {
        date: payload.date,
        id: payload.payload.id,
        sections: payload.sections,
    };


    var modalData = {
      isVisible: true,
      header: {
        label: this.getEditModalTitle(),
        closeButton: {
          payload: 'dismiss'
        }
      },
      footer: {
        actions: [{
          label: 'i18n.cancel',
          payload: 'dismiss'
        }, {
          isDisabled: false,
          buttonClasses: 'n7dash-btn-ok',
          label: 'i18n.save',
          payload: 'submit'
        }]
      }
    };

    this.one('titrimetro-input-modal').update(modalData);
    var editData = this.convertOldDataForEditForm(payload);
    this.one('titrimetro-input-modal-edit-form').update(editData);
  }


  convertOldDataForEditForm(payload){
    var placeholder = ( this.labels ? this.labels.placeholderPrefix + ' ' : '' );
    var editData = {};
    editData['date'] = payload.date;
    editData['sections'] = [];
    payload.sections.forEach(section => {
      var newSection = {};
      newSection['id'] = section.id;
      newSection['name'] = section.name;
      newSection['fields'] = [];
      section.data.forEach(d => {
        newSection['fields'].push({
          id: d.id,
          label: d.field,
          placeholder: placeholder + d.unit,
          value: d.value,
          unit: d.unit
        });
      });
      editData['sections'].push(newSection);
    });
    editData['noSubmitButton'] = true;
    editData['hasCancelButton'] = false;
    return editData;
  }

  hideModal(){
    this.editingData = {};
    this.one('titrimetro-input-modal').update({});
  }

  makeEditingDataDateString(): string{
      return dateHelpers.toString(new Date(this.editingData.date),this.dateFormatRLong);
  }

  getEditModalTitle(){
    var text = ( this.labels ? this.labels.editModalTitlePrefix : '' );
    return `${text} ${this.makeEditingDataDateString()}`;
  }

  getEditModalDescription(){
    var text = ( this.labels ? this.labels.editModalSubTitlePrefix : '' );
    return `${text} ${this.makeEditingDataDateString()}.`;
  }

  protected _loadTranslatedLabels(){
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe(labels => {
      this.labels = labels;
      this.exclude(['pagination']).updateOptions({labels});
    });
  }

  private _listenRouteChanges(){
    this.route.params.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      const page = params.page || 1,
        limit = Config.get('paginationLimit'),
        offset = page === 1 ? 1 : (page * limit) + 1;

      // reset
      this.errorText = '';

      // set pagination path
      this.one('pagination').updateOptions({ basePath: 'titrimetro-input/', current: page });

      const apolloRequest$ = this.apollo.request$('getOldTitrimeterData', {
        // FIXME: startDate & endDate
        // da dove provengono? è da togliere?
        startDate: "2017-06",
        endDate: "2018-07",
        // pagination
        pagination: {
          limit,
          offset
        }
      }, (error: any) => {
        this._handleRequestError(error);
      });
      apolloRequest$
        .subscribe(response => this._handleRequest(response));
    });
  }

  private _handleRequest({ totalCount, items }){
    const limit = Config.get('paginationLimit');
    if (limit < totalCount) {
      this.one('pagination').update({ total: totalCount });
    }
    this.one('titrimetro-input-old-inputs').update(items);

    this.loading = false;
  }

  private _handleRequestError(error: any){
    this.errorText = 'C\'è stato un errore nel caricamento degli inserimenti precedenti';
    this.loading = false;
  }
}
