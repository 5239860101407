import { EventHandler } from '@n7-frontend/core';

export class ModalCustomChartEH extends EventHandler {
  public listen(){
    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'modal-custom-chart.click':
          if(event.payload === 'dismiss'){
            this.dataSource.onDismiss();
          }
          this.emitOuter('click', event.payload);
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'customized-view-layout.open-modal':
          this.dataSource.onOpen();
          break;

        case 'customized-view-layout.close-modal':
          this.dataSource.onDismiss();
          this.emitOuter('click', 'dismiss');
          break;

        default:
          break;
      }
    });
  }
}