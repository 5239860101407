import { DataSource } from '@n7-frontend/core';

export class TitrimetroInputDatepickerDS extends DataSource {
  private _datepicker: any = null;
  
  protected transform(today: Date){
    if(!today) return;
    return {
      _setDatepicker: (datepicker) => this._datepicker = datepicker,
      _elementId: 'datepicker',
      options: {
        dateFormat: 'd F Y',
        defaultDate: today,
        disable: [ (date) => date > today ]
      },
      icon: 'n7-icon-angle-down',
    };
  }


}

