import { EventHandler } from '@n7-frontend/core';

export class SignupEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'signup.change':
          this.dataSource.onChange(payload);
          break;
        case 'signup.focusout':
          this.dataSource.onFocusout(payload);
          break;
        case 'signup.enter':
        case 'signup.click':
          if(
            (type === 'signup.click' && payload === 'submit') || 
            (type === 'signup.enter')
          ){
            this.dataSource.onSubmit();
            if(!this.dataSource.hasErrors()){
              this.emitOuter('submit', this.dataSource.getFormState());

            }
          }
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(({ type, payload }) => {
      switch (type) {
        case 'login-layout.loginerror':
          this.dataSource.onLoginError();
          break;

        default:
          break;
      }
    });
  }
}