import { EventHandler } from '@n7-frontend/core';

export class TitrimetroInputModalEditFormEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      console.log('inner event',event);
      switch(event.type){
        case 'titrimetro-input-modal-edit-form.click':
          this.emitOuter('click', event.payload);
          break;

        case 'titrimetro-input-modal-edit-form.inputChange':
            this.dataSource.onInputChange(event.payload);
            break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'titrimetro-input-layout.titrimetro-input-modal.click':
          if(event.payload=="submit"){
            event.payload = this.dataSource.createSubmitData();
            this.emitOuter('submit', event.payload);
          } else {
            this.emitOuter('cancel', event.payload);
          }
          break;

        default:
          break;
      }
    });
  }
}
