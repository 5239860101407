
import { helpers } from '@app/helpers';


var oldUploads = [];


function generateRandomFakeOldDataAnalysisUpload(){
  var prefixes = ['File_','file-','','prova-','','File_','file-','','','',''];
  var prefixIdx = Math.floor(Math.random() * prefixes.length);
  var middles = ['analisi','analisi','analisi','Analisi','analisi','dataAnalisi','analysis','dataA'];
  var middleIdx = Math.floor(Math.random() * middles.length);
  var suffixes = ['0','1','2','_lab','','','','','_2019','','','','-01','-02','-03'];
  var suffixIdx = Math.floor(Math.random() * suffixes.length);
  var fileExtensions = ['xsl','xsl','xsl','xsl','csv'];
  var fileExtensionsIdx = Math.floor(Math.random() * fileExtensions.length);
  return {
    time: new Date(+(new Date()) - Math.floor(Math.random()*100000000000)).getTime(),
    fileType: helpers.randomPick(['File di analisi','File di controllo','File di evoluzione','File di validazione']),
    fileName: prefixes[prefixIdx] + middles[middleIdx] + suffixes[suffixIdx] + '.' + fileExtensions[fileExtensionsIdx]
  };
}


for(var i=0;i<50;i++)
  oldUploads.push(generateRandomFakeOldDataAnalysisUpload());

oldUploads.sort((a, b) => b.time - a.time);


//export const GET_UPLOAD_DATA_ANALYSIS_OLD_UPLOADS_DATA_MOCK =  {
//  oldUploads: oldUploads
//}

export const GET_UPLOAD_DATA_ANALYSIS_OLD_UPLOADS_DATA_MOCK = {
  "data": {
    "getOldAnalysisUploadData": [
      {
        "date": "2019-07-01T12:03:15.423Z",
        "fileType": "File di evoluzione",
        "fileName": "File_analisi.xsl"
      },
      {
        "date": "2019-07-02T19:08:37.939Z",
        "fileType": "analisi liquidi",
        "fileName": "Analisi.xsl"
      },
      {
        "date": "2019-07-03T12:28:27.730Z",
        "fileType": "File di controllo",
        "fileName": "prova_analisi.xsl"
      },
      {
        "date": "2019-07-04T17:27:58.791Z",
        "fileType": "File di evoluzione",
        "fileName": "file.xsl"
      },
      {
        "date": "2019-07-05T11:14:48.253Z",
        "fileType": "File di evoluzione",
        "fileName": "File_analisi.xsl"
      },
      {
        "date": "2019-07-06T08:41:43.329Z",
        "fileType": "File di controllo",
        "fileName": "file.xsl"
      },
      {
        "date": "2019-07-07T12:27:05.370Z",
        "fileType": "File di analisi",
        "fileName": "controllo.xsl"
      },
      {
        "date": "2019-07-08T01:03:45.249Z",
        "fileType": "File di evoluzione",
        "fileName": "Analisi.xsl"
      },
      {
        "date": "2019-07-08T13:43:55.602Z",
        "fileType": "File di evoluzione",
        "fileName": "aggiornamento_lab.xsl"
      },
      {
        "date": "2019-07-09T03:02:28.215Z",
        "fileType": "File di analisi",
        "fileName": "File_analisi.xsl"
      },
      {
        "date": "2019-07-10T01:27:21.160Z",
        "fileType": "File di validazione",
        "fileName": "An.xsl"
      },
      {
        "date": "2019-07-10T22:01:04.191Z",
        "fileType": "File di validazione",
        "fileName": "File_analisi.xsl"
      },
      {
        "date": "2019-07-11T11:37:44.320Z",
        "fileType": "analisi solidi",
        "fileName": "prova_analisi.xsl"
      },
      {
        "date": "2019-07-12T02:46:39.669Z",
        "fileType": "File di analisi",
        "fileName": "aggiornamento_lab.xsl"
      },
      {
        "date": "2019-07-13T09:32:00.263Z",
        "fileType": "File di controllo",
        "fileName": "prova_analisi.xsl"
      },
      {
        "date": "2019-07-13T21:32:22.511Z",
        "fileType": "File di controllo",
        "fileName": "controllo.xsl"
      },
      {
        "date": "2019-07-14T12:29:44.866Z",
        "fileType": "analisi solidi",
        "fileName": "check.xsl"
      },
      {
        "date": "2019-07-15T04:26:54.120Z",
        "fileType": "File di controllo",
        "fileName": "tmpA.xsl"
      },
      {
        "date": "2019-07-16T03:39:44.182Z",
        "fileType": "analisi solidi",
        "fileName": "file.xsl"
      },
      {
        "date": "2019-07-16T14:21:53.563Z",
        "fileType": "File di analisi",
        "fileName": "analysis.xsl"
      },
      {
        "date": "2019-07-17T16:16:53.665Z",
        "fileType": "analisi solidi",
        "fileName": "tmpA.xsl"
      },
      {
        "date": "2019-07-18T22:44:46.885Z",
        "fileType": "File di analisi",
        "fileName": "controllo.xsl"
      },
      {
        "date": "2019-07-19T08:06:20.265Z",
        "fileType": "File di validazione",
        "fileName": "analysis.xsl"
      },
      {
        "date": "2019-07-19T23:40:51.738Z",
        "fileType": "File di analisi",
        "fileName": "aggiornamento_lab.xsl"
      },
      {
        "date": "2019-07-20T10:44:39.515Z",
        "fileType": "File di controllo",
        "fileName": "aggiornamento_lab.xsl"
      },
      {
        "date": "2019-07-21T01:58:03.476Z",
        "fileType": "analisi liquidi",
        "fileName": "Analisi.xsl"
      },
      {
        "date": "2019-07-21T22:46:31.224Z",
        "fileType": "File di analisi",
        "fileName": "prova_analisi.xsl"
      },
      {
        "date": "2019-07-23T03:31:05.996Z",
        "fileType": "File di validazione",
        "fileName": "File_analisi.xsl"
      },
      {
        "date": "2019-07-23T15:37:50.969Z",
        "fileType": "File di validazione",
        "fileName": "tmpA.xsl"
      },
      {
        "date": "2019-07-24T12:13:24.356Z",
        "fileType": "File di analisi",
        "fileName": "aggiornamento_lab.xsl"
      },
      {
        "date": "2019-07-24T23:56:24.767Z",
        "fileType": "File di evoluzione",
        "fileName": "Analisi.xsl"
      },
      {
        "date": "2019-07-26T02:06:02.671Z",
        "fileType": "File di validazione",
        "fileName": "tmpA.xsl"
      },
      {
        "date": "2019-07-27T08:06:10.393Z",
        "fileType": "analisi solidi",
        "fileName": "File_analisi.xsl"
      },
      {
        "date": "2019-07-27T23:52:58.300Z",
        "fileType": "File di controllo",
        "fileName": "File_analisi.xsl"
      },
      {
        "date": "2019-07-28T19:25:33.248Z",
        "fileType": "File di validazione",
        "fileName": "An.xsl"
      },
      {
        "date": "2019-07-29T05:43:54.670Z",
        "fileType": "File di analisi",
        "fileName": "analysis.xsl"
      },
      {
        "date": "2019-07-29T17:13:27.290Z",
        "fileType": "File di controllo",
        "fileName": "Analisi.xsl"
      },
      {
        "date": "2019-07-30T11:03:35.616Z",
        "fileType": "File di validazione",
        "fileName": "check.xsl"
      },
      {
        "date": "2019-07-31T00:00:19.769Z",
        "fileType": "analisi liquidi",
        "fileName": "tmpA.xsl"
      },
      {
        "date": "2019-08-01T02:18:50.784Z",
        "fileType": "analisi solidi",
        "fileName": "Analisi.xsl"
      }
    ]
  }
}