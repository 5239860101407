import { EventHandler } from '@n7-frontend/core';

export class UploadModelSimulationLayoutEH extends EventHandler {

  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'upload-model-simulation-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'model-simulation-upload-form.submit':
          this.dataSource.onUploadFormSubmit(event.payload);
          break;

        case 'model-simulation-upload-alert.close':
            this.dataSource.closeAlert();
            break;

        default:
          break;
      }
    });

  }

}