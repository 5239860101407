import { Component, Input } from "@angular/core";

@Component({
  selector: "iwt-datafile-upload-form",
  templateUrl: "./datafile-upload-form.html"
})
export class IWTDataFileUploadForm {
  @Input() data: any;
  @Input() emit: any;


  onClick(payload: any) {
    if (!this.emit) return;
    this.emit("click", payload);
  }


  onSubmit(payload) {
    if (!this.emit) return;
    this.emit('submit',payload);
  }

  redirectClick(){
    let hiddenInputFile: HTMLElement = document.getElementById('hidden_input_file') as HTMLElement;
    hiddenInputFile.click();
  }

  onFileChange(event){
    if (!this.emit) return;
    this.emit('fileChange',event);
  }

  onInputChange(payload,value) {
    if (!this.emit) return;
    payload.value = value;
    this.emit("inputChange", payload);
  }

  onSelectChange(value) {
    if (!this.emit) return;
    this.emit("selectChange", {value});
  }

}
