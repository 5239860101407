import { Component, Input } from '@angular/core';

@Component({
  selector: 'iwt-parameters-pick',
  templateUrl: './parameters-pick.html'
})
export class IWTParametersPick {
  @Input() data: any;
  @Input() emit: any;

  constructor() {}

  onClick(type, payload){
    if(!this.emit) return;
    this.emit('click', payload);
  }

  elementClick(type, payload){
    if(!this.emit) return;
    this.emit('element-click', payload);
  }

}