export const BREADCRUMBS_MOCK = {
  items : [
    {
      label: 'Home',
      payload: '/'
    },
    {
      label: 'Costumi',
      payload: '/examples/loader-component'
    },
    {
      label: 'Giacche',
      payload: '/examples/list-component'
    },
    {
      label: 'Maniche',
      payload: '/examples/navbar-component'
    },
    {
      label: 'Manica',
      payload: '/examples/breadcrumbs-component'
    }
  ]
}