import { DataSource } from '@n7-frontend/core';

export class ModelSimulationDownloadDynamicSimulationTableDS extends DataSource {

  protected transform(state){

    let data = state;
    data.numOfDays = state['setup-form-step'].inputs['input_simu_duration'].payload.value;
    if(!data) return;

    var numOfDays = data.numOfDays;
    var params = [];

    let sectionId = state['param-sections']['current-section-id'];
    let sectionState = state['param-sections'][sectionId];
    if(sectionState['params'])
      Object.keys(sectionState['params']).forEach( key => {
        let p = sectionState['params'][key];
        if(p.selected){
          params.push({
            id: p.id,
            label: p.label,
            values: p.values,
            unit : p.unit,
            payload: {
              id: p.id,
              sectionId
            }
          });
        }
      });

    var tableHead = this.makeTableHead(params);
    var tableRows = [];
    for(var i=1;i<numOfDays+1;i++){
      tableRows.push(this.makeRow(i,params,sectionId));
    }

    return {
      classes: 'dynamic-model-simulation-table',
      head: tableHead,
      body: tableRows
    };
  }



  makeTableHead(params){
    var cells = [];
    cells.push({
      classes: 'CONTENT',
      content: ( (this.options && this.options.labels) ? this.options.labels.day : '' )
    });
    params.forEach(p => {
      cells.push({
        classes: 'CONTENT',
        content: p.label
      });
    });
    return [{
      classes: 'HEADER',
        cells: cells
    }];
  }


  makeRow(index:number,params: any[],sectionId:string){
    var cells = [];
    cells.push({
      content: index,
      type: 'html'
    });
    params.forEach(p => {
      cells.push({
        value: ( (p.values && p.values[index]) ? p.values[index] : null),
        type: 'input:number',
        placeholder: p.unit,
        payload: {
          rowIdx: index,
          id: p.id,
          sectionId: sectionId,
          values: p.values
        },
        _meta: { input_id: p.id }
      });
    });
    return { classes: 'BODY', cells: cells };
  }
}