import { EventHandler } from '@n7-frontend/core';

export class ListOldDataAnalysisUploadsEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'list-old-data-analysis-uploads.cellClick':
          this.emitOuter('cellClick', event.payload);
          break;

        case 'list-old-data-analysis-uploads.cellDblClick':
            this.emitOuter('cellDblClick', event.payload);
            break;

        default: 
          break;
      }   
    });

    this.outerEvents$.subscribe(event => {
      // TODO
    });
  }
}
