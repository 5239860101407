import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { of, throwError, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { helpers } from '@app/helpers';
import * as mock from './mocks';
import { Config } from '@app/constants';

@Injectable()
export class Utils {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {
    Object.assign(this, helpers);
  }

  mockRequest$(method, url, data?, headers?, params?){
    const apiPoints = {
      'getParameters': mock.GET_PARAMETERS_MOCK,
      'getSections': mock.GET_SECTIONS_MOCK,
      // to delete? 'chart-data/overview': mock.GET_CHART_MULTIPLE_DATA_MOCK,
      'getChartWithValues': mock.GET_CHART_NORMAL_DATA_MOCK,
      'getModelChartWithValues': mock.GET_CHART_MODEL_DATA_MOCK,
      'getCompareChartWithValues': mock.GET_CHART_COMPARE_DATA_MOCK,
      'getDashboardCharts': mock.GET_HOME_CHARTS_DATA_MOCK,
      'getAlertSettings': mock.GET_MANAGE_ALERTS_DATA_MOCK,
      'plant-section-data': mock.GET_PLANT_SECTION_DATA_MOCK,
      'model-data': mock.GET_PLANT_SECTION_DATA_MOCK,
      'elements-list': mock.GET_ELEMENTS_LIST_MOCK,
      'custom-chart': mock.POST_CUSTOM_CHART_MOCK,
      'getSimulations': mock.GET_MODEL_SIMULATIONS_LIST_MOCK,
      'getAlertNotifications': mock.GET_ALERTSLIST_DATA_MOCK,
      'getTitrimeterCurrentData': mock.GET_TITRIMETO_INPUT_AVAILABLE_FIELDS_MOCK,
      'getOldTitrimeterData': mock.GET_TITRIMETO_INPUT_OLD_DATA_HISTORY_MOCK,
      'addTitrimeter': mock.POST_TITRIMETRO_INPUT_FORM_MOCK,
      'editTitrimeter': mock.POST_TITRIMETRO_INPUT_EDIT_FORM_MOCK,
      'getOldAnalysisUploadData': mock.GET_UPLOAD_DATA_ANALYSIS_OLD_UPLOADS_DATA_MOCK,
      'getDownloadModelParameters': mock.DOWNLOAD_MODEL_SIMUL_START_FORM_DATA_MOCK,
      'alarm-save': mock.POST_ALERT_SAVE_MOCK,
      //to delete? 'chart-alerts': mock.GET_CHART_ALERTS_DATA_MOCK,
      'deleteAlertSettings': mock.DELETE_ALERT_MOCK,
    };
    let response;
    Object.keys(apiPoints).forEach(key => {
      if(data && data.query && data.query.indexOf(key) !== -1){
        response = apiPoints[key];
        return;
      } else if(url.indexOf(key) !== -1){
        response = apiPoints[key];
        return;
      }
    });
    if (response) {
      // clone
      response = JSON.parse(JSON.stringify(response));
      return of(response)
        .pipe(
          delay(500)
        );
    } else {
      return throwError('No mock for: ' + url);
    }
  }

  request$(method, url, data?, headers?, params?) {
    // controllo mock
    if (Config.get('useMock')) {
      return this.mockRequest$(method, url, data, headers, params);
    }

    let requestHeaders = {}
      , requestUrl = url;

    let parts = []
      , options;

    if (params) {
      for (let i in params) {
        if (params.hasOwnProperty(i)) {
          parts.push(encodeURI(i + '=' + params[i]));
        }
      }

      requestUrl += '?' + parts.join('&');
    }

    if (headers) {
      for (let n in headers) {
        if (headers.hasOwnProperty(n)) {
          requestHeaders[n] = headers[n];
        }
      }

      options = {
        'headers': new HttpHeaders(requestHeaders),
        'method': method
      };
    }

    return method === 'GET'
      ? this.http.get(requestUrl, options)
      : this.http.post(requestUrl, data, options);
  }

  post$(url, data, options) {
    return this.http.post(url, data, options);
  }

  getTranslations$(labelKeys): Observable<any> {
    const labelsKeys = Object.keys(labelKeys).map(e => labelKeys[e]);
    return this.translate.get(labelsKeys)
      .pipe(
        map(labels => {
          let updatedLabels = {};
          Object.keys(labelKeys).forEach(key => {
            const i18nKey = labelKeys[key];
            updatedLabels[key] = labels[i18nKey];
          });
          return updatedLabels;
        })
      );
  }

  deepAssign(...args: any[]) { }
  immutAssign(dest, source, value) { }
  isEmptyObject(obj) { }
  disabledEventPropagation(event) { }
  getObjectsByKey(key, values) { }
  getNewObjectInDeepCamelCase(oldObj, isArray?) { }
  getNewObjectInCamelCase(oldObj) { }
  isFunction(value) { }
  isObject(value) { }
  isArray(value) { }
  isWindow(obj) { }
  isPlainObject(obj) { }
  pascalCaseToCamelCase(str) { }
  camelCaseToPascalCase(str) { }
  camelCaseToSpaces(str) { }
  dashCaseToCamelCase(str) { }
  snakeCaseToCamelCase(str) { }
  dashCaseToSpaces(str) { }
  generateShortUID() { }
  generateUID(separator?) { }
  differenceInSecondsBetweensDates(date1, date2) { }
  mmssfromSecs(seconds) { }
}
