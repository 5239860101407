import { DataSource } from '@n7-frontend/core';
import { ChartSelectFilterData } from '@app/components/chart-select-filter/chart-select-filter';
import { dateHelpers } from '@app/helpers/date-helpers';

export class ChartSelectFilterDS extends DataSource {
  // last selected w/default
  private _lastSelected: string | number = 7;
  private _compareActive: boolean = false;

  protected transform(data): ChartSelectFilterData {
    if(!data) return;

    const endDate = new Date();
    let items: any[] = [];

    // days
    [3, 7, 15, 30].map(day => {
      const startDate = dateHelpers.subtract(endDate, day, 'days');
      const isSelected = day === this._lastSelected;

      items.push({
        label: `i18n.chart_select.options.day${day}`,
        isSelected,
        payload: {
          _id: day,
          numOfDays: day,
          startDate: dateHelpers.toString(startDate),
          endDate: dateHelpers.toString(endDate),
          isSelected
        },
        _meta: {
          id: day
        }
      })
    })

    // custom date
    items.push({
      label: 'i18n.chart_select.options.select_date',
      payload: {
        _id: 'custom',
        isCustomDate: true,
      },
      _meta: {
        id: 'custom'
      }
    })

    return { items };
  }

  public onClick({ type, payload }){
    // set selected
    this.output.items.forEach(item => {
      if(item._meta.id === 'custom') return;

      item.isSelected = payload._id === item._meta.id;
      item.payload.isSelected = payload._id === item._meta.id;
    });
  }

  onCompareCheckboxChange(payload){
    this._compareActive = payload;

    // trigger close
    if(!payload){
      const checkbox = document.getElementsByClassName('iwt-dropdown__inner-input')[0];
      checkbox['checked'] = false;
    }
  }

  isCompareActive(){
    return this._compareActive;
  }
}
