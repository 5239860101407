import { LayoutDataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';
import { map, takeUntil } from 'rxjs/operators';
import { Apollo } from '@app/providers';
import { Subject } from 'rxjs';
import { helpers } from '@app/helpers';

export class ManageAlertsLayoutDS extends LayoutDataSource {
  private apollo: Apollo;
  private translate: any;
  private route: any;
  public destroy$: Subject<any> = new Subject();
  public loading: boolean = false;

  private closeAlertTimeout = null;
  private alertCloseTimeOutTime:number = 3500;

  public idAlertToDelete:string = null;
  public deleteAlertModalMessage: string = null;
  public hasData = true;;

  private labels = null;
  protected preloadedLabels = {
    'model': 'i18n.model',
    'headers': 'i18n.headers',
    'manage_alerts': 'i18n.manage_alerts',
    'negative': 'i18n.negative',
    'delete_confirmation_title': 'i18n.layouts.manage_alerts_layout.delete_confirmation_title',
    'delete_confirmation_message': 'i18n.layouts.manage_alerts_layout.delete_confirmation_message',
    "delete_alert_message_success": 'i18n.layouts.manage_alerts_layout.delete_alert_message_success',
    "delete_alert_message_error": 'i18n.layouts.manage_alerts_layout.delete_alert_message_error',
  };

  onInit(payload){
    this.apollo = payload.apollo;
    this.translate = payload.translate;
    this.route = payload.route;

    this._loadTranslatedLabels();

    // listen to router changes
    this._listenRouteChanges();
  }


  onDeleteClick(payload){

    this.idAlertToDelete = payload.id;

    var modalData = {
      isVisible: true,
      header: {
        label: (this.labels ? this.labels.delete_confirmation_title : '' ),
        closeButton: {
          payload: 'dismiss'
        }
      },
      footer: {
        actions: [{
          label: 'i18n.cancel',
          payload: 'dismiss'
        }, {
          isDisabled: false,
          buttonClasses: 'n7dash-btn-ok',
          label: 'i18n.save',
          payload: 'submit'
        }]
      }
    };

    // Note: here you can change the deleteAlertModalMessage based on the
    // selected alert (possible but currently not implemented)
    this.deleteAlertModalMessage = ( this.labels ? this.labels.delete_confirmation_message : '');

    this.one('manage-alerts-delete-confirmation-modal').update(modalData);
  }


  closeDeleteConfirmationModal(){
    this.idAlertToDelete = null;
    this.one('manage-alerts-delete-confirmation-modal').update(null);
  }

  onDeleteSubmitClick() {
    const apolloRequest$ = this.apollo.request$('deleteAlertSettings', { "alertSettingIds": [this.idAlertToDelete] } );
    apolloRequest$.subscribe(response => {
      this._handleDeleteRequest(response);
    });
  }

  onEdit ( payload ) {
    // API edit request
    console.log ( "Request to edit entry " + payload.id );
    // After the request update view with _handleDataRequest
  }

  onAlertSettingsUpdated(){
    this._listenRouteChanges();
    helpers.scrollToDomElement(document.getElementById('main-layout-alert'));
  }

  private _handleDeleteRequest(response){
    if(!response || response.length<1){
      // Note: here you can change the errorMessage based on the
      // selected alert (possible but currently not implemented)
      let errorMessage = ( this.labels ? this.labels.delete_alert_message_error : '' );
      this.showAlert(false,errorMessage);
    } else {
      console.log(`alert ${response[0].id} deleted!`);
      // Note: here you can change the successMessage based on the
      // selected alert (possible but currently not implemented)
      let successMessage = ( this.labels ? this.labels.delete_alert_message_success : '' );
      this.showAlert(true,successMessage);
      this._listenRouteChanges();
    }
    this.closeDeleteConfirmationModal();
  }

  protected _loadTranslatedLabels(){
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe(labels => {
      // update widgets that need preloaded labels
      this.one('manage-alerts').updateOptions({ labels });

      // fake update trigger
      this.one('manage-alerts').update({});

      this.labels = labels;
    });
  }

  private _listenRouteChanges(){
    this.route.params.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      const page = params.page ? +params.page : 1,
        // limit = Config.get('paginationLimit'),
        // offset = page === 1 ? 1 : (page * limit) + 1;
        // no pagination!
        limit = 10000,
        offset = 1;
      
      const apolloRequest$ = this.apollo.request$('getAlertSettings', {
        pagination: { limit, offset }
      });
      apolloRequest$.subscribe(response => this._handleDataRequest(response));
  
      // set pagination path
      this.one('pagination').updateOptions({ basePath: 'manage-alerts/', current: page });
    });
  }

  private _handleDataRequest({ totalCount, items }){
    const limit = Config.get('paginationLimit');
    this.hasData = !!totalCount;

    // has items
    if (Array.isArray(items) && items.length) {
      // no pagination
      /* if (items.length > limit) {
        this.one('pagination').update({ total: totalCount });
      } */
      this.one('manage-alerts').update(items);
    
    // no items
    } else {
      this.hasData = false;
    }
    this.loading = false;
  }


  showAlert(success:boolean, message:any){
    helpers.scrollToDomElement(document.getElementById('manageAlertsAlert'));
    var data;
    if(success){
      data = {
        text: ( message ? message : '' ),
        hasCloseButton: true,
        payload: "close",
        classes: "is-success"
      }
    } else {
      data = {
        text: ( message ? message : '' ),
        hasCloseButton: true,
        payload: "close",
        classes: "is-error"
      };
    }
    this.one('manage-alerts-alert').update(data);
    if(this.closeAlertTimeout!=null){
      clearTimeout(this.closeAlertTimeout);
      this.closeAlertTimeout=null;
    }
    this.closeAlertTimeout = setTimeout(() => {
      this.closeAlert();
      this.closeAlertTimeout = null;
    },this.alertCloseTimeOutTime);
  }

  closeAlert(){
    this.one('manage-alerts-alert').update(null);
  }

}
