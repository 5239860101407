import { EventHandler } from '@n7-frontend/core';

export class DataAnalysisUploadAlertEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'data-analysis-upload-alert.click':
            if(event.payload=="close")
              this.emitOuter('close');
            break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      // TODO
    });
  }
}
