export const GET_TITRIMETO_INPUT_AVAILABLE_FIELDS_MOCK = {
  "data": {
    "getTitrimeterCurrentData": {
      "date": new Date().toISOString,
      "id": "2ixc-titri-f4za",
      "sections": [
        {
          "id": "ingresso_industriale",
          "label": "Ingresso industriale",
          "fields": [
            {
              "id": "azoto_ammoniacale",
              "label": "Azoto ammoniacale",
              "value": null,
              "unit": {
                "id": "MILLIGRAM_LITER",
                "label": "mg/l"
              }
            },
            {
              "id": "azoto_totale_filtrato",
              "label": "Azoto totale filtrato",
              "value": null,
              "unit": {
                "id": "MILLIGRAM_LITER",
                "label": "mg/l"
              }
            }
          ]
        },
        {
          "id": "ingresso_civile",
          "label": "Ingresso civile",
          "fields": [
            {
              "id": "azoto_ammoniacale",
              "label": "Azoto ammoniacale",
              "value": null,
              "unit": {
                "id": "MILLIGRAM_LITER",
                "label": "mg/l"
              }
            },
            {
              "id": "azoto_totale_filtrato",
              "label": "Azoto totale filtrato",
              "value": null,
              "unit": {
                "id": "MILLIGRAM_LITER",
                "label": "mg/l"
              }
            }
          ]
        }
      ]
    }
  }
};