export const GET_PLANT_SECTION_DATA_MOCK = {
  status: 'ok',
  values: _getValues()
}

function _getValues(){
  return [
    "ph", 
    "sed", 
    "ss105",
    "ssv",
    "svi",
    "cod_tq",
    "cod_filtr",
    "azoto_totale_tq",
    "n_tot_filtr",
    "n_org",
    "n_nh4",
    "n_no3",
    "n_no2",
    "solfuri",
    "cloruri",
    "solfati"
  ];
}