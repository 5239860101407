import { LayoutDataSource } from '@n7-frontend/core';
import { AuthService } from '@app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

export class LoginLayoutDS extends LayoutDataSource {
  private auth: AuthService;
  private translate: TranslateService;
  private errors = {
    email: null,
    password: null,
    submit: null
  };
  private preloadedLabels = {
    'login': 'i18n.layouts.login_layout'
  };

  onInit({ auth, translate }){
    this.auth = auth;
    this.translate = translate;

    // translations
    this._loadTranslatedLabels();
  }

  onSubmit(formState){
    const { email, password } = formState.inputs;
    return this.auth.login(email, password);
  }

  getAuthError = () => this.auth.error;

  protected _loadTranslatedLabels(){
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe((labels: any) => {
      this.one('signup').updateOptions({ labels: labels.login });

      // trigger update
      this.one('signup').update({});
    });
  }
}
