import { DataSource } from '@n7-frontend/core';
import { IChartLegendData } from '@app/components/chart-legend/chart-legend';
import { Config } from '@app/constants';

export class ChartLegendDS extends DataSource {

  private _iconShow = 'n7-icon-eye';
  private _iconHide = 'n7-icon-eye-slash';
  private _iconOnFront = 'n7-icon-circle-full';
  private _iconNotOnFront = 'n7-icon-circle';
  private _currentMode: string;

  protected transform(data): IChartLegendData {
    if(!data) return;
    
    const { values, chartId } = data,
      { isDashboardChart } = this.options,
      elementsConfig = Config.get('elements'),
      sectionsConfig = Config.get('sections'),
      elements = values[0] ? values[0].data.map(({key}) => key) : [];

    let items = [];

    elements.forEach((key, index) => {
      const el = elementsConfig[key], 
        unit = el.unit,
        sectionLabel = sectionsConfig[el.section.id].label,
        color = `rgba(${el.colorRgb.join(',')}, 1)`;

      let label = `${el.label} ${unit}`;
      if(this.options.hasSectionLabel){
        label = `${sectionLabel} &rsaquo; ` + label;
      }

      items.push({
        label,
        classes: index === 0 ? 'is-current' : '',
        left: {
          icon: index === 0 ? this._iconOnFront : this._iconNotOnFront,
          color: color,
        },
        right: !isDashboardChart ? {
          icon: this._iconShow,
          color: '',
          tooltip: 'i18n.chart_legend.show_parameter',
        } : null,
        payload: {
          id: key
        },
        _meta: {
          id: key,
          front: index === 0 ? true : false,
          hidden: false,
          chartId,
          isDashboardChart
        },
      });
    });

    return { items };
  }

  public onClick({type, payload}){
    const { items } = this.output,
      { id } = payload;

    let state = {};

    if(type.indexOf('right') !== -1){
      items.forEach(item => {
        if(item._meta.id === id){
          if(item._meta.hidden === true){
            item.right.icon = this._iconShow;
            item._meta.hidden = false;
          } else {
            item.right.icon = this._iconHide;
            item._meta.hidden = true;
          }
        }
      });
    } else {
      items.forEach(item => {
        // reset
        item.left.icon = this._iconNotOnFront;
        item._meta.front = false;
        item.classes = '';

        if(item._meta.id === id){
          item.left.icon = this._iconOnFront;
          item._meta.front = true;
          item.classes = 'is-current';
        }
      });
    }

    // force hidden to false when element is on front
    const itemOnFront = items.filter(item => item._meta.front)[0];
    itemOnFront._meta.hidden = false;
    if(itemOnFront.right) {
      itemOnFront.right.icon = this._iconShow;
    }

    // update state
    items.forEach(item => {
      state[item._meta.id] = item._meta;
    });

    return state;
  }

  onChartRequest({ type, payload }){
    if(Array.isArray(payload.values) && payload.values.length){
      if(this._currentMode !== payload.mode) {
        this._currentMode = payload.mode;
        this.update(payload);
      }
    } else {
      this._currentMode = null;
      this.update(payload);
    }
  }
}
