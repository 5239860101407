var values = [];

function generateRandomFakeRawDataAlert(){
  var value = Math.floor(Math.random()*50) + ( Math.floor(Math.random()*50) / 100 );
  return {
    time: new Date(+(new Date()) - (35555555555) - Math.floor(Math.random()*10000000000)).getTime(),
    value: value,
  };
}

for(var i=0;i<5;i++)
  values.push(generateRandomFakeRawDataAlert());

values.sort((a, b) => b.time - a.time);

export const GET_CHART_ALERTS_DATA_MOCK =  { status: 'ok', values };