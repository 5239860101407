import { EventHandler } from '@n7-frontend/core';

export class ChartMenuEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({type, payload}) => {
      if(type === 'chart-menu.click'){
        this.emitOuter('click', payload);
      }
    });
  }
}