import { environment as env } from '@src/environments/environment';

export class Config {
  private static defaults = {
    langs: ['it', 'en'],
    useMock: <boolean> false,
    assetsPath: env.assetsPath,
    // apiUrl: 'https://i-swat-apollo.piotrowicz.now.sh/',
    apiUrl: 'https://api.dataviz-demo-iswat.netseven.it/',
    // apiUrl: 'http://localhost:4000/',
    apiRestUrl: 'https://iswat-express.netseven.it/',
    dateStringFormat: 'YYYY-MM-DD',
    dateStringFormatReverseShort: 'DD MMM YYYY',
    dateStringFormatReverseLong: 'DD MMMM YYYY',
    dateStringFormatReverseTabular: 'D/M/YYYY - h:m',
    selectedElementsLimit: 10,
    paginationLimit: 10,
    popoverAlertsLimit: 5,
    dateRangeLimit: 31, // days
  }

  public static get = (key) => Config.defaults[key];
  public static set = (key, value) => Config.defaults[key] = value;
}
