import { DataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';

export class ModalAlarmCreateDS extends DataSource {
  public modalDescription: string;

  protected transform(data) {
    if(!data || !this.options || !this.options.labels) return;

    const { labels } = this.options,
      { elementId, isUpdate } = data,
      modalLabels = isUpdate ? labels['alarm_update'] : labels['alarm_create'],
      elementConfig = Config.get('elements')[elementId],
      title = `${modalLabels['title']} ${elementConfig.label}`;
      
    this.modalDescription = modalLabels['description'].replace('__NAME__', elementConfig.label);

    return {
      isVisible: false,
      classes: 'iwt-layout-chart__modal',
      header: {
        label: title,
        closeButton: {
          payload: {
            source: 'dismiss',
            isUpdate
          }
        }
      },
      footer: {
        actions: [{
          label: 'i18n.cancel',
          payload: {
            source: 'dismiss',
            isUpdate
          }
        }, {
          isDisabled: false,
          buttonClasses: 'n7dash-btn-ok',
          label: modalLabels['save'],
          payload: {
            source: 'save',
            isUpdate
          }
        }]
      }
    };
  }

  onOpen(){
    this.output.isVisible = true;
  }

  onDismiss(){
    this.output.isVisible = false;
  }

  updateActions(){
    this.output.footer.actions = [{
      label: 'i18n.close',
      payload: 'dismiss'
    }];
  }
}
