import { DataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';

const ELEMENT_FIELD_DEFAULT_VALUE = 0;
const COMPARISON_FIELD_DEFAULT_VALUE = 'GREATER';

export class FormAlarmCreateDS extends DataSource {
  private _formState: any = {};
  
  protected transform(data) {
    if(!data) return;

    const { elementId, val, operator, isModel } = data,
      elementConfig = Config.get('elements')[elementId];

    let options = ['GREATER', 'LESS'];
    if(!isModel){
      options.push('EQUAL');
    }

    this._formState['id_field'] = elementConfig.id;
    this._formState['element_field'] = val || ELEMENT_FIELD_DEFAULT_VALUE;
    this._formState['comparison_field'] = operator || COMPARISON_FIELD_DEFAULT_VALUE;

    return { 
      element: {
        payload: 'element_field',
        value: this._formState['element_field']
      },
      comparison: {
        payload: 'comparison_field',
        options: options.map((key) => ({
          value: key,
          label: !isModel ? `i18n.form_alarm_create.options.${key}` : `i18n.form_alarm_create.model_options.${key}`,
          selected: this._formState['comparison_field'] === key
        }))
      },
      unit: elementConfig.unit
    };
  }

  onChange({ payload, value }){
    this._formState[payload] = value;
  }

  getFormState(){
    return this._formState;
  }

  getWidgetInput = () => this.input;

  reset(){
    this._formState = { comparison_field: 'GREATER' };
    this.update(this.input);
  }
}
