export const DOWNLOAD_MODEL_SIMUL_START_FORM_DATA_MOCK = {
  "data": {
    "getDownloadModelParameters": {
      "efflu_prim_label": "Effluente primario",
      "efflu_prim": [
        {
          "id": "effpr_tmp",
          "label": "Temperatura",
          "color": "#8f469b",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": []
        },
        {
          "id": "effpr_ossv",
          "label": "Ossigeno disciolto in vasca",
          "color": "#918174",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": []
        },
        {
          "id": "effpr_vpri",
          "label": "Variazione portata di ricircolo interno",
          "color": "#8cc279",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": []
        },
        {
          "id": "effpr_vpre",
          "label": "Variazione portata di ricircolo esterno",
          "color": "#fc0ef9",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": []
        },
        {
          "id": "effpr_vprefc",
          "label": "Variazione portata refluo civile",
          "color": "#50e56c",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": []
        },
        {
          "id": "effpr_vprefi",
          "label": "Variazione portata refluo industriale",
          "color": "#2b6516",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": []
        },
        {
          "id": "effpr_vcod",
          "label": "Variazione COD",
          "color": "#696b27",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": [
            "effpr_vamm",
            "effpr_solid"
          ]
        },
        {
          "id": "effpr_vamm",
          "label": "Variazione Ammonio",
          "color": "#abc64e",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": [
            "effpr_vcod",
            "effpr_solid"
          ]
        },
        {
          "id": "effpr_solid",
          "label": "Solidi",
          "color": "#9dc284",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": [
            "effpr_vamm",
            "effpr_vcod"
          ]
        }
      ],
      "refluo_civ_label": "Refluo civile",
      "refluo_civ": [
        {
          "id": "reflciv_port",
          "label": "Portata",
          "color": "#ab69e1",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": []
        },
        {
          "id": "reflciv_amm",
          "label": "Ammonio",
          "color": "#e9e41e",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": []
        },
        {
          "id": "reflciv_azsol",
          "label": "Azoto solubile",
          "color": "#184a6c",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": []
        },
        {
          "id": "reflciv_solidi",
          "label": "Solidi interti",
          "color": "#45aa95",
          "unit": {
            "id": "MILLIGRAM_LITER",
            "label": "mg/l"
          },
          "conflictIds": []
        }
      ]
    }
  }
}