import { Component, Input } from '@angular/core';

@Component({
  selector: 'iwt-form-alarm-create',
  templateUrl: './form-alarm-create.html'
})
export class FormAlarmCreateComponent {
  @Input() data: any;
  @Input() emit: any;

  onChange(payload, value) {
    if (!this.emit) return;
    this.emit('change', { payload, value });
  }
}