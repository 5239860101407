import { Injectable, Inject } from '@angular/core';
import { Apollo } from '@app/providers';
import { Config } from '@app/constants';
import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Injectable()
export class Configuration {
  private modes = {
    COMPARE: {
      color: '#F5A624',
      label: 'compare',
    }, 
    MODEL: {
      color: '#925DFF',
      label: 'model',
    }
  };
  constructor(
    private apollo: Apollo
  ) { }

  load(): Promise<any> {
    const paramsRequest$ = this.apollo.request$('getParameters'),
      sectionsRequest$ = this.apollo.request$('getSections');

    const source$ = forkJoin(
      paramsRequest$,
      sectionsRequest$
    ).pipe(
      tap(response => this._handleConfigResponse(response))
    );

    return source$.toPromise();
  }

  private _handleConfigResponse([parametersConfig, sectionsConfig]) {
    if (parametersConfig && sectionsConfig) {
      const headTag = document.querySelector('head'),
        styleElement = document.createElement('style');

      // adding global styles
      let elements = {};
      let sections = {};
      let styles = [];

      // adding model & compare to each element
      parametersConfig.forEach(el => {
        const unit = el.unit ? el.unit : '';
        elements[el.id] = { 
          ...el,
          unit
        };
        Object.keys(this.modes).forEach(modeKey => {
          const mode = this.modes[modeKey],
            modeId = `${el.id}-${modeKey}`;
          elements[modeId] = {
            ...elements[el.id],
            id: modeId,
            label: `${el.label} (${mode.label})`,
            color: mode.color,
            mode: modeKey
          };
        });
      });

      // sections
      sectionsConfig.forEach(section => {
        sections[section.id] = section;
      });

      Object.keys(elements).forEach(key => {
        const element = elements[key];
        // add rgb to config
        element.colorRgb = this._hexToRgb(element.color);

        // add css class
        styles.push(`.element-color-${element.id} { color: ${element.color}; }`);
        styles.push(`.element-bgcolor-${element.id} { background-color: ${element.color}; }`);
      });

      // saving to config
      Config.set('elements', elements);
      Config.set('sections', sections);

      styleElement.appendChild(document.createTextNode(styles.join(' ')));
      headTag.appendChild(styleElement);
    }
  }

  private _hexToRgb(hex) {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16))
  };
}