import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private name: string | null = null;
  private avatar: string | null = null;

  public setName = (name: string | null) => this.name = name;
  public setAvatar = (avatar: string | null) => this.avatar = avatar;
  public getName = () => this.name;
  public getAvatar = () => this.avatar;

  public destroy() {
    this.setName(null);
    this.setAvatar(null);
  }

  public toString(){
    return JSON.stringify({
      name: this.name,
      avatar: this.avatar
    });
  }
}
