import { Component, Input, AfterContentChecked } from '@angular/core';
import flatpickr from 'flatpickr';

@Component({
  selector: 'iwt-datepicker',
  templateUrl: './datepicker.html'
})
export class Datepicker implements AfterContentChecked {
  @Input() data: any;
  @Input() emit: any;
  private _loaded: boolean = false;

  ngAfterContentChecked(){
    if(!this.data || this._loaded) return;

    this._loaded = true;

    setTimeout(() => {
      const { _elementId, options } = this.data;
      const datepicker = flatpickr(`#${_elementId}`, 
        {
          ...options,
          onChange: (selectedDates, dateStr, instance) => {
            if(this.emit){
              this.emit('change', { selectedDates, dateStr, instance });
            }
          }
        }
      );

      // setting datepicker on parent data source
      this.data._setDatepicker(datepicker);
    });
  }
}