import { DataSource } from '@n7-frontend/core';
import { dateHelpers } from '@app/helpers';

export class ChartDatepickerCompareDS extends DataSource {
  private _datepicker: any = null;
  private _daysDiff: any = null;

  protected transform(data) {
    if(!data) return;

    const { startDate, endDate } = data;

    this._daysDiff = dateHelpers.diff(endDate, startDate, 'days');

    return {
      // to use on chart updates
      _setDatepicker: (datepicker) => this._datepicker = datepicker,
      _elementId: 'datepicker-compare',
      options: {
        dateFormat: 'Y-m-d',
        // defaultDate: [data.start_date, data.end_date],
        mode: "range"
      },
    };
  }

  open(){
    setTimeout(() => this._datepicker.open());
  }

  onChange(payload){
    const startDate = payload.dateStr,
      endDate = dateHelpers.toString(dateHelpers.add(startDate, this._daysDiff, 'days')),
      dates = [startDate, endDate];
    this._datepicker.setDate(dates, false);
    this._datepicker.close();

    return dates;
  }

 }
