import { EventHandler } from '@n7-frontend/core';

export class ModalAlarmCreateEH extends EventHandler {
  public listen(){
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch(type){
        case 'modal-alarm-create.click':
          if(payload.source === 'dismiss'){
            this.dataSource.onDismiss();
          }
          this.emitOuter('click', payload);
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'main-layout.openAlertModal':
          this.dataSource.onOpen();
          break;

        case 'main-layout.closeAlertModal':
          this.dataSource.onDismiss();
          this.emitOuter('click', 'dismiss');
          break;

        default:
          break;
      }
    });
  }
}