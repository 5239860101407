import { Component, Input } from '@angular/core';

@Component({
  selector: 'iwt-wrapper',
  templateUrl: './wrapper.html'
})
export class WrapperComponent {
  @Input() data: any;
  @Input() emit: any;

  constructor() {}

  onClick(payload){
    if(!this.emit) return;
    this.emit('click', payload);
  }
}