import { Component, Input } from '@angular/core';

@Component({
  selector: 'iwt-elements-accordion',
  templateUrl: './elements-accordion.html'
})
export class ElementsAccordionComponent {
  @Input() data: any;
  @Input() emit: any;

  onClick(payload){
    if(!this.emit) return;
    this.emit('click', payload);
  }

  elementClick(type, payload){
    if(!this.emit) return;
    this.emit('element-click', payload);
  }
}