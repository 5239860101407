import { DataSource } from '@n7-frontend/core';
import { helpers, dateHelpers } from '@app/helpers';
import { Config } from '@app/constants';

export class TitrimetroInputFormDS extends DataSource {

  formData = {};

  protected transform(data){
    if(!data) return;

    var dateFormat = Config.get('dateStringFormatReverseShort');
    data.dateStr = dateHelpers.toString(new Date(data.date),dateFormat);
    data.sections.forEach(section => {
      section.name = section.label;
      delete section.label;
      this.formData[section.id] = {};
      section.fields.forEach(field => {
        field.unit = field.unit;
        this.formData[section.id][field.id] = {'wasEdited':false};
        if( helpers.isNumeric(field.value) ){
          this.formData[section.id][field.id]['value'] = field.value;
          this.formData[section.id][field.id]['originalValue'] = field.value;
        }
        field['placeholder']=this.options.labels.placeholderPrefix + ' ' + field.unit;
        field['payload'] = {
          sectionId: section.id,
          fieldId: field.id
        };
      });
    });
    return data;
  }

  onInputChange(obj){
    var sectionId = obj.sectionId;
    var fieldId = obj.fieldId;
    var value = obj.value;
    if(helpers.isNumeric(value))
      this.formData[sectionId][fieldId]['value'] = parseFloat(value);
    this.formData[sectionId][fieldId]['wasEdited'] = true;
    console.log('current formData ',this.formData);
  }

  createSubmitData(): any {
    // TO DO : filter the formatData based on the API
    return this.formData;
  }
}
