import { EventHandler } from '@n7-frontend/core';
import { HomeLayoutDS } from './home-layout.ds';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class HomeLayoutEH extends EventHandler {
  private destroyed$: Subject<any> = new Subject();
  
  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch(type){
        case 'home-layout.init':
          this.dataSource.onInit(payload);
          break;

        case 'chart-layout.destroy':
          this.destroyed$.next();
          break;

        case 'home-layout.destroy':
            HomeLayoutDS.newChartId = null;
            break;

        case 'home-layout.action-click':
          this.dataSource.onActionClick(payload);
          break;

        case 'home-layout.input-submit':
          this.dataSource.onInputSubmit(payload);
          break;

        default: 
          break;
      }
    });
    
    this.outerEvents$.subscribe(({ type, payload }) => {
      if(type.indexOf('legend.change') !== -1){
        this.emitOuter('chart-legend.change', payload);
      }

      switch(type){
        case 'chart-menu.click': {
          if(payload !== 'remove'){
            this.dataSource.onChartMenuClick(payload);
          } else {
            const chartId = this.dataSource.getSelectedChartId();
            this.dataSource.toggleChartMenu(chartId);
            this.dataSource.setModalDeleteDescription(chartId);
            this.emitOuter('open-modal-delete-widget', { chartId });
          }
          break;
        }

        case 'modal-delete-widget.remove-click': 
          // fake remove trigger
          this.dataSource.onRemoveChart(payload.chartId);
          break;

        case 'modal-reorder-widgets.reorder-click': 
          this.emitOuter('get-current-widgets-order');
          break;

        case 'sortable-widgets.current-widgets-order':
          const req$ = this.dataSource.saveWidgetsOrderRequest$(payload);
          req$.subscribe(response => {
            this.emitOuter('close-reorder-widgets-modal');

            setTimeout(() => {
              document.location.reload();
            }, 500);
            /* if(response){
              this.dataSource.reorderCharts(response);
            } */
          });
          break;

        default: 
          break;
      }
    });

    EventHandler.globalEvents$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(({ type, payload }) => {
      switch(type){
        case 'global.tools-click':
          if(payload.source === 'open-reorder-widgets-modal'){
            this.emitOuter(payload.source);
            this.dataSource.onOpenReorderWidgetsModal();
          }
          break;

        default:
          break;
      }
    });
  }

}