import { EventHandler } from '@n7-frontend/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export class ElementsListLayoutEH extends EventHandler {
  private destroyed$: Subject<any> = new Subject();

  public listen() {
    // listen to inner (layout) events
    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'elements-list-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        case 'elements-list-layout.destroy':
            this.destroyed$.next();
            break;
            
        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'parameter-cards.click':
          const routePaths = this.dataSource.getRoutePaths();
          this.emitGlobal('loadChart', [...routePaths, event.payload]);
          break;

        default:
          break;
      }
    });

    EventHandler.globalEvents$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(({ type, payload }) => {
      if(type === 'global.routerChange') {
        this.dataSource.onRouterChange(payload);
      }
    });

  }

}