import { Component, Input } from '@angular/core';

export interface IModelSimulationsListItem {
  header: {
    cells: string[];
  };
  content: {
    // TODO
  };
  payload?: any;
}

export interface IModelSimulationsListData {
  items: IModelSimulationsListItem[];
  classes?: string;
}

@Component({
  selector: 'iwt-model-simulations-list',
  templateUrl: './model-simulations-list.html'
})
export class IWTModelSimulationsList {
  @Input() data: IModelSimulationsListData;
  @Input() emit: any;

  onClick(payload) {
    if(!this.emit) return;
    this.emit('click', payload);
  }
}