import { Component, Input } from "@angular/core";

export interface IWTMiniCardItemData {
  id: string;
  label: string;
  color: string;
  icon: string;
  classes?: string;
  payload?: any;
  _meta?: any;
}

@Component({
  selector: "iwt-mini-card",
  templateUrl: "./mini-card.html"
})
export class IWTMiniCard {
  @Input() data: IWTMiniCardItemData;
  @Input() emit: any;

  onClick(payload: any) {
    if (!this.emit) return;
    this.emit("click", payload);
  }
}
