import { EventHandler } from '@n7-frontend/core';

export class TitrimetroInputOldInputsEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'titrimetro-input-old-inputs.click':
          this.emitOuter('click', event.payload);
          break;

        case 'titrimetro-input-old-inputs.iconClick':
          this.emitOuter('iconClick', this.dataSource.getData(event.payload.id));
          break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      // TODO
    });
  }
}
