import { DataSource } from '@n7-frontend/core';


export class ModelSimulationDownloadParamsSelectionDS extends DataSource {

  data: any;

  makeElement(id: string, label:string, color: string,payload: any,classes: string){
    return {  id,
              label,
              icon: "n7-icon-circle-full",
              color,
              url: 0,
              payload,
              classes
    };
  }

  protected transform(state){
    if(!state) return;

    let data = state['param-sections'];

    this.data = {...data};

    let sectionId = data['current-section-id'];
    this.data['elements'] = [];

    if(data[sectionId]['params'])
      Object.keys(data[sectionId]['params']).forEach( key => {
        let p = data[sectionId]['params'][key];
        this.data['elements'].push( this.makeElement(p.id,p.label,p.color,p.payload,p.classes) );
      });

    return this.data;
  }

  generateSubmitData(): any{
    // TO DO : filter this.data
    return { 'data' : this.data };
  }

}
