import { LayoutDataSource } from '@n7-frontend/core';
import { switchMap, tap } from 'rxjs/operators';
import tippy from 'tippy.js';

import { Config } from '@app/constants';
import { Observable, of } from 'rxjs';
import { dateHelpers, helpers } from '@app/helpers';

export class ChartLayoutDS extends LayoutDataSource {
  static customChartData: any;

  private apollo: any;
  private labels: any;
  private elementId: string;
  private chartId: string;
  private alertsTable: any;
  private alertsTableOpen: boolean = false;
  public chartPeriod: string = 'LAST_N_DAYS'; // with defaults
  public source: string;
  public numOfDays: number = 7; // with defaults
  private _selectedDateStart: string;
  private _selectedDateEnd: string;
  private _currentDateStart: string = dateHelpers.toString(dateHelpers.subtract(new Date(), this.numOfDays, 'days'));
  private _currentDateEnd: string = dateHelpers.toString(new Date());
  private _currentCompareDateStart: string;
  private _currentCompareDateEnd: string;
  private _overviewChartId: string;

  private _selectedCompareDateStart: string;
  private _selectedCompareDateEnd: string;
  private _lastChartResponse: any;

  public chartSelectLabel: string = '';
  public compareSelectLabel: string = '';
  public datepickerIsOpen: boolean = false;
  public datepickerCompareIsOpen: boolean = false;
  public compareSelectIsVisible: boolean = false;
  public hasCompare: boolean = false;
  private _hasAlerts: boolean = false;
  public elementConfig: any;
  public chartMode: string;
  public chartNotFound: boolean = false;
  public chartEmptyText: string;
  public loading: boolean = false;

  public overviewParameterIds = [
    "ing-ph",
    "ing-ss105",
    "ing-ssv",
    "ing-n_org",
    "ing-n_nh4",
    "civ-cod_tq",
    "civ-n_nh4"
  ];

  onInit(payload){
    this.apollo = payload.apollo;
    this.labels = payload.labels;
    this.elementId = payload.elementId;
    this.chartId = payload.chartId;
    this.source = payload.source;
    this.hasCompare = payload.source === 'plantSection';

    const elementsConfig = Config.get('elements');
    if(this.elementId){
      this.elementConfig = elementsConfig[this.elementId];
    }

    this.some([
      'chart',
      'chart-actions',
      'chart-alerts-table',
    ]).updateOptions({ labels: this.labels, elementConfig: this.elementConfig });

    // update actions
    this.one('chart-actions').update({ hasAlerts: false });

    if(this.elementConfig){
      // update alerts
      this._loadChartAlerts();

      if(this.source === 'model'){
        this._loadModelChart();
      } else if(this.source === 'plantSection'){
        this._loadNormalChart();
      }
    // overview
    } else if(this.source === 'overview') {
      this._loadOverviewChart();

    // custom
    } else if(this.source === 'custom_chart') {
      this._loadCustomChart();
    }

    // update select label
    this.updateChartSelectLabel();

    this.one('chart-legend').updateOptions({
      labels: this.labels,
      elementConfig: this.elementConfig,
      hasSectionLabel: ['custom_chart', 'overview'].indexOf(this.source) !== 1
    });
  }


  onCompareCheckboxChange(payload){
    const ds = this.widgets['compare-select-filter'].ds,
      selectedPayload = ds.getSelectedPayload();

    this.compareSelectIsVisible = payload;
    this.datepickerCompareIsOpen = false;
    return selectedPayload;
  }

  chartRequest({ startDate, endDate, numOfDays }): Observable<any> {
    this.loading = true;
    this._currentDateEnd = endDate;
    this._currentDateStart = startDate;

    // update numOfDays
    this.numOfDays = this.chartPeriod === 'LAST_N_DAYS' ? numOfDays : null;

    // config
    let params = { startDate, endDate }, method;
    // custom chart
    if(this.source === 'custom_chart'){
      params['parameterIds'] = ChartLayoutDS.customChartData.parameters.map(p => p.id);
      method = 'getChartWithValues';
    // overview chart
    } else if(this.source === 'overview'){
      // method = 'getOverviewChartWithValues';
      params['parameterIds'] = this.overviewParameterIds;
      method = 'getChartWithValues';
    // plantSection chart
    } else if(this.source === 'plantSection'){
      params['parameterIds'] = [this.elementId];
      method = 'getChartWithValues';
    // model chart
    } else if(this.source === 'model'){
      params['parameterId'] = this.elementId;
      method = 'getModelChartWithValues';
    }

    // update select label
    this.updateChartSelectLabel();

    return this.apollo.request$(method, params).pipe(
      tap((response: any) => {
        this.chartMode = response.mode;
        this._lastChartResponse = response;
      }),
      switchMap(response => of(ChartLayoutDS.normalizeChartData(response))
    ));
  }

  compareChartRequest({ start, end }): Observable<any> {
    this.loading = true;
    this._currentCompareDateEnd = end;
    this._currentCompareDateStart = start;

    // update compare select label
    this.updateCompareSelectLabel()

    // chart request
    const params = {
      parameterIds: [this.elementId],
      startDate: start,
      endDate: end,
    };

    return this.apollo.request$('getChartWithValues', params).pipe(
      tap((response: any) => this.chartMode = response.mode),
      switchMap((response: any) => {
        const mergedResponse = {
          ...this._lastChartResponse,
          compareStartDate: response.startDate,
          compareEndDate: response.endDate,
          compareParamValues: response.paramValues,
          parameters: response.parameters,
          mode: 'COMPARE'
        };
        return of(mergedResponse);
      }),
      switchMap(response => of(ChartLayoutDS.normalizeChartData(response))
    ));
  }

  addToDashboardRequest(config): Observable<any> {
    return this.apollo.request$('addChartToDashboard', config);
  }

  updateChartSelectLabel(){
    const dateFormat = 'D MMM YYYY',
      startDateStr = dateHelpers.toString(this._currentDateStart, dateFormat),
      endDateStr = dateHelpers.toString(this._currentDateEnd, dateFormat);

    if(this.chartPeriod === 'LAST_N_DAYS' && this.numOfDays){
      this.chartSelectLabel = `i18n.chart_select.options.day${this.numOfDays}`;
    } else {
      this.chartSelectLabel = `${startDateStr} - ${endDateStr}`;
    }
  }

  updateCompareSelectLabel(){
    const dateFormat = 'D MMM YYYY',
      startDate = dateHelpers.toString(this._currentCompareDateStart, dateFormat),
      endDate = dateHelpers.toString(this._currentCompareDateEnd, dateFormat);

    this.compareSelectLabel = `${startDate} - ${endDate}`;
  }

  onDatepickerChange({type, payload}){
    const { selectedDates, dateStr, instance } = payload;

    const [from, to] = dateStr.split(' to '),
      fromDate = from ? new Date(from) : null,
      toDate = to ? new Date(to) : null;

    let isDiff = false;
    if(fromDate && toDate){
      const timeDiff = Math.abs(toDate.getTime() - fromDate.getTime()),
        diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if(diffDays > (Config.get('dateRangeLimit') - 1)){
        instance.setDate([fromDate, fromDate]);
        isDiff = true;
      }
    }
    if(Array.isArray(selectedDates) && selectedDates.length === 2){
      const [dateStart, dateEnd] = selectedDates;
      this._selectedDateStart = dateHelpers.toString(dateStart);
      this._selectedDateEnd = isDiff ? dateHelpers.toString(dateStart) : dateHelpers.toString(dateEnd);

      if(isDiff){
        instance.open();
      }
    }
  }

  onCompareDatepickerChange({type, payload}){
    const [ startDate, endDate ] = payload;
    this._selectedCompareDateStart = dateHelpers.toString(startDate);
    this._selectedCompareDateEnd = dateHelpers.toString(endDate);
  }

  openDatepicker(){
    // reset dates
    this._selectedDateStart = null;
    this._selectedDateEnd = null;

    this.datepickerIsOpen = true;
  }

  openCompareDatepicker(){
    // reset dates
    this._selectedCompareDateStart = null;
    this._selectedCompareDateEnd = null;

    this.datepickerCompareIsOpen = true;
  }

  datesSelected(){
    return this._selectedDateStart && this._selectedDateEnd;
  }

  compareDatesSelected(){
    return this._selectedCompareDateStart && this._selectedCompareDateEnd;
  }

  onDatepickerSubmit(){
    // close datepicker
    this.datepickerIsOpen = false;

    return {
      startDate: this._selectedDateStart,
      endDate: this._selectedDateEnd
    };
  }

  getCurrentDates(){
    return {
      startDate: dateHelpers.toString(this._currentDateStart),
      endDate: dateHelpers.toString(this._currentDateEnd)
    };
  }

  getCurrentCompareDates(){
    return {
      startDate: this._currentCompareDateStart,
      endDate: this._currentCompareDateEnd
    };
  }

  onCompareDatepickerSubmit(){
    // close datepicker
    this.datepickerCompareIsOpen = false;

    return {
      start: this._selectedCompareDateStart,
      end: this._selectedCompareDateEnd
    };
  }

  hasAlerts(){
    return this._hasAlerts;
  }

  onLoadAlertModal(){
    this.alertsTable.hide();
  }

  deleteCustomChart$(id){
    return this.apollo.request$('deleteCustomChart');
  }

  getElementId = () => this.elementId;

  getChartId = () => this.chartId || this._overviewChartId;

  isModel = () => this.source === 'model';

  isCompare = () => this.compareSelectIsVisible;

  updateEmptyText(response) {
    if(Array.isArray(response.values) && response.values.length){
      this.chartEmptyText = null;
    } else {
      this.chartEmptyText = this.source === 'overview' ? 'i18n.layouts.chart.no_values_alt' : 'i18n.layouts.chart.no_values';
    }
  }

  private _handleChartRequest(response){
    this._currentDateStart = response.startDate;
    this._currentDateEnd = response.endDate;

    this.some([
      'chart-datepicker',
      'compare-select-filter',
      'chart-datepicker-compare'
    ]).update(response);

    // chart select
    this.one('chart-select-filter').update({});

    // update empty text
    this.updateEmptyText(response);

    if(Array.isArray(response.values) && response.values.length){
      this.some([
        'chart',
        'chart-legend'
      ]).update(response);
    }
  }

  private _handleChartAlertsRequest({ totalCount, items }){
    this._hasAlerts = !!(Array.isArray(items) && items.length);

    if(this._hasAlerts){
      this.one('chart-alerts-table').update({ values: items });
    }

    // update chart actions
    this.one('chart-actions').update({ hasAlerts: this._hasAlerts });
  }

  public toggleAlertsTablePopover(){
    if(!this.alertsTable){
      const template = document.getElementById('alerts-popover');
      template.style.display = 'block';

      this.alertsTable = tippy('#chart-alerts-action', {
        content: template,
        trigger: 'manual',
        interactive: true,
        arrow: true,
        theme: 'light-border no-padding',
        placement: 'bottom-end',
        onHidden: () => this.alertsTableOpen = false,
      })[0];
    }

    if(this.alertsTableOpen){
      this.alertsTable.hide();
    } else {
      this.alertsTable.show();
    }

    this.alertsTableOpen = !this.alertsTableOpen;
  }

  private _loadModelChart(){
    this._loadChart('getModelChartWithValues', {
      parameterId: this.elementId,
      startDate: this._currentDateStart,
      endDate: this._currentDateEnd
    });
  }

  private _loadNormalChart(){
    this._loadChart('getChartWithValues', {
      parameterIds: [this.elementId],
      startDate: this._currentDateStart,
      endDate: this._currentDateEnd
    });
  }

  private _loadOverviewChart(){
    let source$: Observable<any>;
    if(!this._overviewChartId){
      source$ = this.apollo.request$('getOverviewChartID');
    } else {
      source$ = of(this._overviewChartId);
    }

    source$.subscribe((id) => {
      this._overviewChartId = id;
      this._loadChart('getChartWithValues', {
        parameterIds: this.overviewParameterIds,
        startDate: this._currentDateStart,
        endDate: this._currentDateEnd
      });
    });
  }

  private _loadCustomChart(){
    const source$ = ChartLayoutDS.customChartData
      ? of(ChartLayoutDS.customChartData)
      : this.apollo.request$('getCustomChart');

    source$.subscribe(chartData => {
      if(chartData) {
        const filteredParameters = chartData.parameters.map(({ id, section }) => ({
          section,
          id: id.indexOf('\\') !== -1 ? id.replace('\\\\', '\\') : id
        }));
        ChartLayoutDS.customChartData = {
          ...chartData,
          parameters: filteredParameters
        };
        this._handleChartRequest(ChartLayoutDS.normalizeChartData(ChartLayoutDS.customChartData));
      } else {
        this.chartNotFound = true;
        console.log('chart not found!');
      }
    });
  }

  private _loadChart(method, params){
    this.loading = true;
    this.apollo.request$(method, params).subscribe(response => {
      this.loading = false;
      if(response){
        this.chartMode = response.mode;
        this._lastChartResponse = response;
        this._handleChartRequest(ChartLayoutDS.normalizeChartData(response));
      } else {
        this.chartNotFound = true;
        console.log('chart not found!');
      }
    });
  }

  // normalize for chart format
  static normalizeChartData(data){
    const {
      id,
      uiPosition,
      paramValues,
      startDate,
      endDate,
      compareStartDate,
      compareEndDate,
      chartPeriod,
      numOfDays,
      name,
      mode
    } = data;

    let { parameters } = data;

    // filter parameters
    parameters = parameters.map(param => {
      if(param.id.indexOf('\\') !== -1){
        param.id = param.id.replace('\\', '\\\\');
      }
      return param;
    });

    let elements = data["parameters"].map(p => p.id);
    let modeElements, modeParamValues;
    if(['COMPARE', 'MODEL'].indexOf(mode) !== -1){
      modeElements = elements.map(id => `${id}-${mode}`);
      modeParamValues = data[`${mode.toLowerCase()}ParamValues`];
    }

    let values = [];
    paramValues.forEach((paramValue, paramIndex) => {
      let dayData = [];
      elements.forEach((el, index) => {
        dayData.push({
          key: el,
          value: helpers.isNumeric(paramValue.values[index]) ? paramValue.values[index].toFixed(2) : paramValue.values[index]
        });
      })

      if(modeElements && modeParamValues){
        modeElements.forEach((el, index) => {
          const modeParamValue = modeParamValues[paramIndex];
          dayData.push({
            key: el,
            value: (modeParamValue && helpers.isNumeric(modeParamValue.values[index])) ? modeParamValue.values[index].toFixed(2) : null
          });
        })
      }

      values.push({
        x: dateHelpers.toString(paramValue.date),
        data: dayData
      })
    });

    return {
      id,
      uiPosition,
      values,
      startDate,
      endDate,
      compareStartDate,
      compareEndDate,
      parameters,
      mode,
      chartPeriod,
      numOfDays,
      name
    };
  }

  private _loadChartAlerts(){
    const apolloRequest$ = this.apollo.request$('getAlertNotifications', {
      pagination: {
        limit: Config.get('popoverAlertsLimit'),
        offset: 1,
      },
      parameterIds: [this.elementId]
    });
    apolloRequest$.subscribe(response => this._handleChartAlertsRequest(response));
  }
}
