import { Component, Input } from '@angular/core';

export interface ChartSelectFilterItem {
  label: string;
  payload: any;
}

export interface ChartSelectFilterData {
  items: ChartSelectFilterItem[]
}

@Component({
  selector: 'iwt-chart-select-filter',
  templateUrl: './chart-select-filter.html'
})
export class ChartSelectFilterComponent {
  @Input() data: ChartSelectFilterData;
  @Input() emit: any;

  onClick(payload){
    if(!this.emit) return;
    this.emit('click', payload);
  }
}