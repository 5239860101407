import { EventHandler } from '@n7-frontend/core';

export class ModelSimulationUploadFormEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'model-simulation-upload-form.click':
          this.emitOuter('click', event.payload);
          break;

        case 'model-simulation-upload-form.submit':
          var submitData = this.dataSource.createDataToSubmit();
          this.emitOuter('submit', submitData);
          break;

        case 'model-simulation-upload-form.fileChange':
          this.dataSource.chosenFileToUpload(event.payload);
          break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      // TODO
    });
  }
}
