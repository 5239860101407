import { Injectable } from '@angular/core';
import { Utils } from './utils.provider';
import { APOLLO_REQUESTS_CONFIG as CONFIG } from './apollo.config';
import { Config } from '@app/constants';
import { catchError, switchMap } from 'rxjs/operators';
import { empty, of, Subject } from 'rxjs';


@Injectable()
export class Apollo {
  public token: string;
  public tokenError$: Subject<boolean> = new Subject();
  constructor(
    private utils: Utils
  ) { }

  request$(type, params?: any, onError?: any){
    let query = CONFIG[type];
    if(params){
      // fix dates
      if(params.startDate) params.startDate += 'T00:00:00.000Z';
      if(params.endDate) params.endDate += 'T23:59:59.000Z';

      let paramsStr = this.makeParamsStr(params);
      query = query.replace('__PARAMS__', paramsStr);
    } else {
      query = query.replace('(__PARAMS__)', '');
    }

    let request$ = this.utils.request$('POST', Config.get('apiUrl'), { query });
    // FIXME: togliere commento
    if(this.token){
      request$ = this.utils.request$('POST', Config.get('apiUrl'), { query }, { 'Authorization': this.token });
    }

    return request$.pipe(
      catchError((error) => {
        if (onError) {
          onError(error);
        }
        this._handleError(error)
        return empty();
      }),
      switchMap(response => of(response.data[type]))
    );
  }

  public setToken = (token) => this.token = token;

  private _handleError(e){
    // unauthorized
    if(e && e.status === 400 && e.error){
      if(Array.isArray(e.error.errors) && e.error.errors.length){
        const extensionError = e.error.errors[0].extensions;
        if(extensionError.code === 401){
          this.tokenError$.next();
        }
      }
    }
  }

  private makeParamsStr(params){
    let paramsStr = [];
    Object.keys(params).forEach(key => {
      if(Array.isArray(params[key])){
        let arrStr = [];
        params[key].forEach( val => {
          if(typeof(val)==='object'){
            let subParamsStr = this.makeParamsStr(val);
            arrStr.push(`{ ${subParamsStr} }`);
          } else {
            if(!isNaN(val)) arrStr.push(`${val}`);
            else arrStr.push(`"${val}"`)
          }
        });
        paramsStr.push(`${key}: [${arrStr.join(',')}]`);
      } else if( typeof(params[key])==='object' && params[key] ){
          let subParamsStr = this.makeParamsStr(params[key]);
          paramsStr.push(`${key}: { ${subParamsStr} }`);
      } else if( typeof(params[key])==='string' && key.indexOf('$') === 0){
        paramsStr.push(`${key.replace('$', '')}: ${params[key]}`);
      } else {
        if(!isNaN(params[key])) paramsStr.push(`${key}: ${params[key]}`);
        else paramsStr.push(`${key}: "${params[key]}"`);
      }
    });
    return paramsStr.join(' ');
  }

}
