import { LayoutDataSource } from '@n7-frontend/core';
import { map } from 'rxjs/operators';
import { helpers, dateHelpers } from '@app/helpers';
import { Apollo } from '@app/providers';
import { Config } from '@app/constants';

export class DownloadModelSimulationLayoutDS extends LayoutDataSource {
  private _currentStepCount:number = 0;
  private apollo: Apollo;

  state = {
    'setup-form-step':{
      'inputs': {
        input_num_of_days:{
          classes: '',
          payload:{
            id: "num_of_days_input",
            value: 7, // the default value is 7
            lastCorrectValue: 7
          }
        },
        radio_last_n_days:{
          baseLabel: null,
          label: null,
          payload:{
            id: "radio_last_n_days",
            checked: true
          }
        },
        radio_same_period_last_year:{
          payload:{
            id: "radio_same_period_last_year",
            checked: false
          }
        },
        radio_n_days_from:{
          baseLabel: null,
          label: null,
          payload:{
            id: "radio_n_days_from",
            checked: false
          }
        },
        currentSelectedDate: new Date(),
        checkbox_simu_predit:{
          payload:{
            id: "checkbox_simu_predit",
            checked: false
          }
        },
        input_simu_duration:{
          classes: "",
          payload:{
            id: "input_simu_duration",
            value: 7 // the default value is 7 ?(to do: ask giulio)
          },
          isCurrentInputValid: true
        },
        checkbox_efflu_prim:{
          payload:{
            id: "checkbox_efflu_prim",
            checked: false
          }
        },
        checkbox_refluo_civ:{
          payload:{
            id: "checkbox_refluo_civ",
            checked: false
          }
        },
      },
      'errors':{
        // put here eventual errors
      },
      'options':{
        'simu_predit_div_class':"iwt-download-model-simulations-disabled-div"
      }
    },
    'param-sections':{
      "current-section-id":"efflu_prim",
      "efflu_prim":{},
      "refluo_civ":{}
    }
  }


  public buttonsState = {
    back: {
      hidden: true,
      disabled : false,
      label: null
    },
    forward: {
      hidden: true,
      disabled : false,
      label: null
    }
  }

  public totalStepCount = 0;
  public currentStepIdx = 'setup-form';
  public typeOfSimulation: string;
  public showCurrentStepIndicator = false;


  private labels: any;
  private utils: any;
  private translate: any;
  protected preloadedLabels = {
    forward: 'i18n.forward',
    back: 'i18n.back',
    start: 'i18n.start',
    download_file: 'i18n.download_file',
    last_n_days_base_label : 'i18n.layouts.download_model_simulation.setup_form.last_n_days',
    n_days_from_base_label : 'i18n.layouts.download_model_simulation.setup_form.n_days_from',
    day : 'i18n.layouts.download_model_simulation.params_setting.day'
  };


  onInit(payload){
    this.utils = payload.utils;
    this.apollo = payload.apollo;
    this.translate = payload.translate;
    this._loadTranslatedLabels();

    const apolloRequest$ = this.apollo.request$('getDownloadModelParameters');
    apolloRequest$.subscribe(response => this._handleDataRequest(response));
  }



  private _handleDataRequest(response){
    console.log('datasource init received',response);

    if(response){
      ["efflu_prim","refluo_civ"].forEach( (sectionParams) => {
        if(response[sectionParams]){
          let currentSection = this.state["param-sections"][sectionParams];
          currentSection.id = sectionParams;
          currentSection.label = response[sectionParams+"_label"];
          currentSection.typeOfSimulation = 'static';
          currentSection.params = {};
          response[sectionParams].forEach(param => {
            currentSection.params[param.id] = {...param};
            currentSection.params[param.id].selected = false;
            currentSection.params[param.id].disabled = false;
            currentSection.params[param.id].classes = "";
            currentSection.params[param.id].value = null;
            currentSection.params[param.id].values = [];
            currentSection.params[param.id].payload = {
              id: param.id,
              sectionId:sectionParams,
              conflictIds: param.conflictIds
            }
          });
        }
      });
    }
    this.all().update(this.state);
    this.UpdateButtonsState();
  }



  protected _loadTranslatedLabels(){
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe(labels => {
      this.labels = labels;
      this.state['setup-form-step'].inputs.radio_last_n_days.baseLabel = labels.last_n_days_base_label;
      this.state['setup-form-step'].inputs.radio_n_days_from.baseLabel = labels.n_days_from_base_label;
      let numOfDays = this.state['setup-form-step'].inputs.input_num_of_days.payload.lastCorrectValue;
      this.updateNdaysBasedLabels(numOfDays);
      this.one('model-simulation-download-setup-form').update(this.state);
      this.one('model-simulation-download-static-simulation-form').updateOptions({labels});
      this.one('model-simulation-download-dynamic-simulation-table').updateOptions({labels});
      this.UpdateButtonsState();
    });
  }



  onParamElementClick(payload){
    let param = this.state['param-sections'][payload.sectionId].params[payload.id];
    param.selected = !param.selected;
    if(param.selected){
      param.classes = "is-selected";
      if(payload.conflictIds){
        payload.conflictIds.forEach(cId => {
          let cParam = this.state['param-sections'][payload.sectionId].params[cId];
          cParam.selected = false;
          cParam.classes = "iwt-download-model-simulations-disabled-div";
        });
      }
    } else {
      param.classes = "";
      if(payload.conflictIds){
        payload.conflictIds.forEach(cId => {
          let cParam = this.state['param-sections'][payload.sectionId].params[cId];
          cParam.selected = false;
          cParam.classes = "";
        });
      }
    }
    this.one('model-simulation-download-params-selection').update(this.state);
    this.UpdateButtonsState();
  }



  onInputChange(payload){
    alert('implement me if necessary!'); // FIX ME: if not used remove!
  }


  updateShowCurrentStepIndicator(){
    if(this.currentStepIdx=='setup-form'){
      this.showCurrentStepIndicator = false;
      return;
    }
    if(this.currentStepIdx=='loading'){
      this.showCurrentStepIndicator = false;
      return;
    }
    if(!this.state["setup-form-step"].inputs.checkbox_simu_predit.payload.checked){
      this.showCurrentStepIndicator = false;
      return;
    }
    this.showCurrentStepIndicator = true;
  }


  updateNdaysBasedLabels(numOfDays:number){
    if(this.state['setup-form-step'].inputs.radio_last_n_days.baseLabel)
      this.state['setup-form-step'].inputs.radio_last_n_days.label = this.state['setup-form-step'].inputs.radio_last_n_days.baseLabel.replace('__NUMBER__',numOfDays);
    if(this.state['setup-form-step'].inputs.radio_n_days_from.baseLabel)
      this.state['setup-form-step'].inputs.radio_n_days_from.label = this.state['setup-form-step'].inputs.radio_n_days_from.baseLabel.replace('__NUMBER__',numOfDays);
  }



  onSetupFormInputChange(payload){
    if(!payload) return;

    if(payload.id=="num_of_days_input"){
      let numOfDays = payload.value;
      this.state['setup-form-step'].inputs.input_num_of_days.payload.value=numOfDays;
      if(helpers.isPositiveInteger(numOfDays)){
        this.state['setup-form-step'].inputs.input_num_of_days.payload.lastCorrectValue=numOfDays;
        this.updateNdaysBasedLabels(numOfDays);
        this.state['setup-form-step'].inputs.input_num_of_days.classes="";
      } else {
        this.state['setup-form-step'].inputs.input_num_of_days.classes=" iwt-download-model-simulations-days-input-invalid ";
      }
      this.one('model-simulation-download-setup-form').update(this.state);
      this.UpdateButtonsState();
      return;
    }

    if(payload.id=="input_simu_duration"){
      let numOfDays = payload.value;
      if(helpers.isPositiveInteger(numOfDays)){
        this.state['setup-form-step'].inputs.input_simu_duration.classes ="";
        this.state['setup-form-step'].inputs.input_simu_duration.payload.value=parseInt(numOfDays);
        this.state['setup-form-step'].inputs.input_simu_duration.isCurrentInputValid = true;
      } else {
        this.state['setup-form-step'].inputs.input_simu_duration.classes =" iwt-download-model-simulations-days-input-invalid ";
        this.state['setup-form-step'].inputs.input_simu_duration.isCurrentInputValid = false;
      }
      this.one('model-simulation-download-setup-form').update(this.state);
      this.UpdateButtonsState();
      return;
    }

    if(payload.id.startsWith('checkbox_')){
      this.state["setup-form-step"].inputs[payload.id].payload.checked=!this.state["setup-form-step"].inputs[payload.id].payload.checked;
      if(payload.id.endsWith('simu_predit')){
        if(this.state["setup-form-step"].inputs[payload.id].payload.checked){
          this.state['setup-form-step'].options.simu_predit_div_class = "";
        } else {
          this.state['setup-form-step'].options.simu_predit_div_class = "iwt-download-model-simulations-disabled-div";
        }
      }
      this.one('model-simulation-download-setup-form').update(this.state);
      this.UpdateButtonsState();
      return;
    }

    if (payload.id.startsWith('radio_')) {
      Object.keys(this.state['setup-form-step'].inputs)
        .filter(inputId => inputId.indexOf('radio_') !== -1)
        .forEach(inputId => {
          this.state['setup-form-step'].inputs[inputId].payload.checked = payload.id === inputId;
        });
      return;
    }

    if(payload.id=='currentSelectedDate'){
      this.state['setup-form-step'].inputs['currentSelectedDate'] = payload.selectedDates[0];
      return;
    }

  }



  isSetupFormValid():boolean {
    var numOfDays = this.state["setup-form-step"].inputs.input_num_of_days.payload.value;
    if(!helpers.isPositiveInteger(numOfDays)) return false;

    if(!this.state["setup-form-step"].inputs.checkbox_simu_predit.payload.checked)
      return true;

    if(!this.state['setup-form-step'].inputs.input_simu_duration.isCurrentInputValid)
      return false;

    if(this.state["setup-form-step"].inputs.checkbox_simu_predit.payload.checked){
      let numOfParamsChecked=0;
      if(this.state["setup-form-step"].inputs.checkbox_efflu_prim.payload.checked) numOfParamsChecked++;
      if(this.state["setup-form-step"].inputs.checkbox_refluo_civ.payload.checked) numOfParamsChecked++;
      if(numOfParamsChecked<1) return false;
    }

    return true;
  }




  finalSubmitValidation(){
    // TO DO: implement here an eventual check
    //        for all the necessary data
    return false;
  }


  allCurrentParamsValuesAreSet(): boolean {
    let currentSectionIdx = this.state['param-sections']["current-section-id"];
    let currentSection = this.state['param-sections'][currentSectionIdx];
    if(currentSection.typeOfSimulation == 'static'){
      if(currentSection.params)
       for( var key in currentSection.params ){
          if(currentSection.params[key].selected)
            if(!currentSection.params[key].value) return false;
       }
    } else {
      if(currentSection.params){
        const nDays = this.state['setup-form-step'].inputs['input_simu_duration'].payload.value;
        for( var key in currentSection.params ){
          if(currentSection.params[key].selected){
            let vals = currentSection.params[key].values;
            if(!vals || vals.length<(nDays+1)) return false;
            for(var i=1;i<vals.length; i++){
              if(isNaN(vals[i]) || vals[i]=="") return false;
            }
          }
        }
      }
    }
    return true;
  }


  UpdateButtonsState(){
    switch(this.currentStepIdx){
      case 'setup-form':
          this.buttonsState.back.hidden = true;
          this.buttonsState.forward.hidden = false;
          this.buttonsState.forward.disabled = !this.isSetupFormValid();
          this.buttonsState.forward.label = ( (this.labels && this.labels.start) ? this.labels.start : '' );
          break;
      case 'params-selection':
          this.buttonsState.back.hidden = false;
          this.buttonsState.back.disabled = false;
          this.buttonsState.back.label = ( (this.labels && this.labels.back) ? this.labels.back : '' );
          this.buttonsState.forward.hidden = false;
          this.buttonsState.forward.disabled = (this.numOfCurrentSelectedParameters()<1);
          this.buttonsState.forward.label = ( (this.labels && this.labels.forward) ? this.labels.forward : '' );
          break;
      case 'params-setting':
          this.buttonsState.back.hidden = false;
          this.buttonsState.back.disabled = false;
          this.buttonsState.back.label = ( (this.labels && this.labels.back) ? this.labels.back : '' );
          this.buttonsState.forward.hidden = false;
          this.buttonsState.forward.disabled = (!this.allCurrentParamsValuesAreSet());
          this.buttonsState.forward.label = ( (this.labels && this.labels.forward) ? this.labels.forward : '' );
          break;
      case 'submit':
          this.buttonsState.back.hidden = false;
          this.buttonsState.back.disabled = false;
          this.buttonsState.back.label = ( (this.labels && this.labels.back) ? this.labels.back : '' );
          this.buttonsState.forward.hidden = false;
          this.buttonsState.forward.disabled = this.finalSubmitValidation();
          this.buttonsState.forward.label = ( (this.labels && this.labels.download_file) ? this.labels.download_file : '' );
          break;
      case 'loading':
          this.buttonsState.back.hidden = true;
          this.buttonsState.forward.hidden = true;
          break;
      default:
          break;
    }
  }



  onBackClick(){
    if(this.currentStepIdx=='params-selection'){
      let currentSection = this.state['param-sections']["current-section-id"];
      let effluChecked = this.state["setup-form-step"].inputs.checkbox_efflu_prim.payload.checked;
      if(currentSection=="refluo_civ" && effluChecked){
        this.state['param-sections']["current-section-id"]="efflu_prim";
        this.one('model-simulation-download-static-simulation-form').update(this.state);
        this.one('model-simulation-download-dynamic-simulation-table').update(this.state);
        this.one('model-simulation-download-params-selection').update(this.state);
        this.currentStepIdx='params-setting';
        this.decrementCurrentStepsIndication();
      } else {
        this.currentStepIdx='setup-form';
        this.decrementCurrentStepsIndication();
      }
    } else if(this.currentStepIdx=='params-setting'){
      this.currentStepIdx='params-selection';
      let currentSection = this.state['param-sections']["current-section-id"];
      this.decrementCurrentStepsIndication();
    } else if(this.currentStepIdx=='submit'){
      if(this.state["setup-form-step"].inputs.checkbox_simu_predit.payload.checked){
        this.currentStepIdx='params-setting';
        this.decrementCurrentStepsIndication();
        this.one('model-simulation-download-static-simulation-form').update(this.state);
        this.one('model-simulation-download-dynamic-simulation-table').update(this.state);
      } else {
        this.currentStepIdx='setup-form';
      }
    }
    this.UpdateButtonsState();
    this.updateShowCurrentStepIndicator();
  }

  getTotalStepCount(){
    let numOfParamsChecked=0;
    if(this.state["setup-form-step"].inputs.checkbox_efflu_prim.payload.checked) numOfParamsChecked++;
    if(this.state["setup-form-step"].inputs.checkbox_refluo_civ.payload.checked) numOfParamsChecked++;
    return (numOfParamsChecked*2)+1;
  }


  private async onSubmitSetupForm(){
    if(!this.isSetupFormValid()) return;

    if(this.state["setup-form-step"].inputs.checkbox_simu_predit.payload.checked){
      this.currentStepIdx='params-selection';
      this.incrementCurrentStepsIndication();

      if(this.state["setup-form-step"].inputs.checkbox_efflu_prim.payload.checked)
        this.state['param-sections']["current-section-id"]="efflu_prim";
      else
        this.state['param-sections']["current-section-id"]="refluo_civ";
      this.one('model-simulation-download-params-selection').update(this.state);
    } else {
      this.currentStepIdx='submit';
    }
    this.UpdateButtonsState();
  }



  private incrementCurrentStepsIndication(){
    this._currentStepCount += 1;
    this.one('model-simulation-download-steps').update({
      totalSteps: this.getTotalStepCount(),
      currentStep: this._currentStepCount
    });
  }


  private decrementCurrentStepsIndication(){
    this._currentStepCount -= 1;
    this.one('model-simulation-download-steps').update({
      totalSteps: this.getTotalStepCount(),
      currentStep: this._currentStepCount
    });
  }


  private numOfCurrentSelectedParameters():number{
    let numOfSelectParams = 0;
    let currentSectionId = this.state['param-sections']["current-section-id"];
    let params = this.state['param-sections'][currentSectionId]['params'];
    if(params)
      Object.keys(params).forEach( key => {
        if(params[key].selected) numOfSelectParams++;
      });
    return numOfSelectParams;
  }



  private onSubmitSelectedParameters(){
    if(this.numOfCurrentSelectedParameters()<1) return;
    this.currentStepIdx='params-setting';
    this.incrementCurrentStepsIndication();
    this.one('model-simulation-download-static-simulation-form').update(this.state);
    this.one('model-simulation-download-dynamic-simulation-table').update(this.state);
    this.UpdateButtonsState();
  }



  private onSubmitValuesForParameters(){
    let currentSection = this.state['param-sections']["current-section-id"];
    let refluo_civ_checked = this.state["setup-form-step"].inputs.checkbox_refluo_civ.payload.checked;
    if(!refluo_civ_checked || currentSection=="refluo_civ"){
      this.currentStepIdx='submit';
      this.incrementCurrentStepsIndication();
    } else {
      this.currentStepIdx='params-selection';
      this.state['param-sections']["current-section-id"]="refluo_civ";
      this.incrementCurrentStepsIndication();
      this.one('model-simulation-download-params-selection').update(this.state);
    }
    this.UpdateButtonsState();
  }

  private onDownloadRequestSubmit() {
    console.log('state', this.state);
    this.currentStepIdx = 'loading';
    this.UpdateButtonsState();

    const { parameterIds, values } = this.getSelectedParams();
    const { startDate, endDate } = this.getSelectedDates();
    const simulationLengthInDays = parameterIds.length ? this.state['numOfDays'] : null;
    const filename = `model-simulation-${dateHelpers.toString(new Date())}`;

    // download request
    console.log('POSTING to express endpoint', {
      parameterIds,
      values,
      simulationLengthInDays,
      startDate,
      endDate,
      filename
    });
    const options =  {
      responseType: 'blob' as 'json'
    };
    this.utils.post$(`${Config.get('apiRestUrl')}download-model`,
      {
        startDate,
        endDate,
        parameterIds,
        values,
        filename,
        simulationLengthInDays,
      }, options).subscribe(response => {
        console.log('test', response);
        const file = new Blob([response], {type: 'application/zip'});
        console.log('buffer', file)
        console.log('buffer size', file.size, file.type)
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.download = `${filename}.zip`;
        a.href = fileURL;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  getSelectedParams() {
    const parameterIds = [],
      values = [];

    ['efflu_prim', 'refluo_civ'].forEach(key => {
      const currentGroup = this.state['param-sections'][key] || {};
      Object.keys(currentGroup.params)
        .map(paramId => currentGroup.params[paramId])
        .filter(({ selected }) => selected)
        .forEach(({id, value, values: paramValues}) => {
          parameterIds.push(id);
          values.push(Array.isArray(paramValues) && paramValues.length ? paramValues.filter(v => v) : [value]);
        });
    });
    return { parameterIds, values };
  }

  getSelectedDates() {
    const {
      radio_last_n_days,
      radio_same_period_last_year,
      radio_n_days_from,
      currentSelectedDate,
      input_num_of_days
    } = this.state['setup-form-step'].inputs;
    const numOfDays = input_num_of_days.payload.value;
    const daysMargin = numOfDays - 1;

    let startDate, endDate;

    if (radio_last_n_days.payload.checked){
      endDate = dateHelpers.subtract(new Date(), 1, 'days');
      startDate = dateHelpers.subtract(endDate, daysMargin, 'days');
    } else if (radio_same_period_last_year.payload.checked) {
      endDate = dateHelpers
        .subtract(new Date(), 1, 'days')
        .subtract(1, 'years');
      startDate = dateHelpers.subtract(endDate, daysMargin, 'days');
    } else if (radio_n_days_from.payload.checked) {
      startDate = currentSelectedDate;
      endDate = dateHelpers.add(currentSelectedDate, daysMargin, 'days');
    }

    // normalize & format
    startDate = dateHelpers.toString(startDate) + 'T00:00:00.000Z';
    endDate = dateHelpers.toString(endDate) + 'T23:59:59.000Z';

    return { startDate, endDate };
  }

  onForwardClick() {
    switch(this.currentStepIdx){
      case 'setup-form': 
          this.onSubmitSetupForm();
          break;
      case 'params-selection': 
          this.onSubmitSelectedParameters();
          break;
      case 'params-setting':
          this.onSubmitValuesForParameters();
          break;
      case 'submit':
          this.onDownloadRequestSubmit();
          break;
      default:
          break;
    }
    this.updateShowCurrentStepIndicator();
  }



  onRadioButtonChange(payload){
    if(!payload) return;

    if(payload.id.startsWith('typeofsimulation_')){
      if(payload.id.endsWith('static')){
        this.state['param-sections'][this.state['param-sections']['current-section-id']].typeOfSimulation='static';
        this.one('model-simulation-download-static-simulation-form').update(this.state);
      } else if(payload.id.endsWith('dynamic')){
        this.state['param-sections'][this.state['param-sections']['current-section-id']].typeOfSimulation='dynamic';
        this.one('model-simulation-download-dynamic-simulation-table').update(this.state);
      }
      this.UpdateButtonsState();
    }
  }




  onStaticFormInputChange(payload){
    if(!payload) return;
    if(!payload.event || !payload.event.target) return;
    let value = payload.event.target.value;
    if( isNaN(value) ){
      this.state['param-sections'][payload.sectionId].params[payload.id].value = null;
      return; // TO DO: add en error or something?
    } else {
      this.state['param-sections'][payload.sectionId].params[payload.id].value = value;
    }
    this.UpdateButtonsState();
  }




  onDynamicTableInputChange(payload){
    let cellPayload = payload.cell_payload;
    let value = payload.value;
    if(isNaN(value)) return;
    var paramValues = this.state['param-sections'][cellPayload.sectionId].params[cellPayload.id].values;
    if(!paramValues) paramValues = [];
    paramValues[cellPayload.rowIdx] = value;
    this.UpdateButtonsState();
  }




}
