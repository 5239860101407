import { EventHandler } from '@n7-frontend/core';

export class TitrimetroInputFormEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'titrimetro-input-form.click':
          this.emitOuter('click', event.payload);
          break;

        case 'titrimetro-input-form.submit':
          this.emitOuter('submit', this.dataSource.createSubmitData() );
          break;

        case 'titrimetro-input-form.inputChange':
          this.dataSource.onInputChange(event.payload);
          break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      // TODO
    });
  }
}
