export const APOLLO_REQUESTS_CONFIG: any = {
  'getParameters': `
    {
      getParameters {
        id
        label
        color
        hasModel
        unit
        section {
          id
          label
        }
      }
    }
  `,
  'getSections': `
    {
      getSections {
        id
        label
      }
    }
  `,
  'getSimulations': `
    {
      getSimulations(__PARAMS__){
        totalCount
        items {
          id
          name
          description
          uploadDate
          sections {
            id
            parameters {
              id
            }
          }
        }
      }
    }
  `,

  'getAlertNotifications':`
    {
      getAlertNotifications(__PARAMS__){
        totalCount
        items {
          date
          value
          alert {
            id
            vsModel
            parameter {
              id
              label
              section {
                id
              }
            }
          }
        }
      }
    }
  `,

  'getChartWithValues': `
    {
      getChartWithValues(__PARAMS__){
        id
        name
        mode
        chartPeriod
        numOfDays
        startDate
        endDate
        parameters {
          id
          section {
            id
          }
        }
        paramValues {
          date
          values
        }
      }
    }
  `,

  'getCompareChartWithValues': `
    {
      getCompareChartWithValues(__PARAMS__){
        id
        name
        mode
        chartPeriod
        numOfDays
        startDate
        endDate
        compareStartDate
        compareEndDate
        parameters {
          id
          section {
            id
          }
        }
        paramValues {
          date
          values
        }
        compareParamValues {
          date
          values
        }
      }
    }
  `,

  'getModelChartWithValues': `
    {
      getModelChartWithValues(__PARAMS__){
        id
        name
        mode
        chartPeriod
        numOfDays
        startDate
        endDate
        parameters {
          id
          section {
            id
          }
        }
        paramValues {
          date
          values
        }
        modelParamValues {
          date
          values
        }
      }
    }
  `,

  'getOverviewChartWithValues': `
    {
      getOverviewChartWithValues(__PARAMS__){
        id
        name
        mode
        chartPeriod
        numOfDays
        startDate
        endDate
        parameters {
          id
          section {
            id
          }
        }
        paramValues {
          date
          values
        }
      }
    }
  `,

  'getDashboardCharts': `
    {
      getDashboardCharts(__PARAMS__){
        id
        name
        uiPosition
        mode
        chartPeriod
        numOfDays
        startDate
        endDate
        parameters {
          id
          section {
            id
          }
        }
        paramValues {
          date
          values
        }
        compareParamValues {
          date
          values
        }
        modelParamValues {
          date
          values
        }
      }
    }
  `,

  'getAlertSettings':`
    {
      getAlertSettings(__PARAMS__) {
        totalCount
        items {
          id,
          parameter {
            id
            section {
              id
            }
          }
          condition
          threshold,
          vsModel
        }
      }
    }
  `,

  'getOldTitrimeterData':`
    {
      getOldTitrimeterData(__PARAMS__){
        totalCount
        items {
          id
          date
          author
          sections {
            id
            label
            fields {
              id
              label
              value
              unit
            }
          }
        }
      }
    }
  `,

  'getTitrimeterCurrentData':`
    {
      getTitrimeterCurrentData {
        id
        date
        sections {
          id
          label
          fields {
            id
            label
            value
            unit
          }
        }
      }
    }
  `,

  'getOldAnalysisUploadData':`
    {
      getOldAnalysisUploadData(__PARAMS__){
        totalCount
        items {
          date
          fileType
          fileName
        }
      }
    }
  `,

  'addTitrimeter':`
    mutation {
      addTitrimeter(__PARAMS__){
        id
        date
        author
        sections {
          id
          fields {
            id
            value
          }
        }
        errorMessage
      }
    }
  `,


  'editTitrimeter':`
    mutation {
      editTitrimeter(__PARAMS__){
        id
        date
        author
        sections {
          id
          fields {
            id
            value
          }
        }
      }
    }
  `,

  'deleteAlertSettings':`
    mutation {
      deleteAlertSettings(__PARAMS__){
        id
      }
    }
  `,

  'addAlertSetting': `
    mutation {
      addAlertSetting (__PARAMS__) {
        id
        parameter {
          label
          section {
            label
          }
        }
        condition
        threshold
      }
    }
  `,

  'editAlertSetting': `
    mutation {
      editAlertSetting (__PARAMS__) {
        id
        parameter {
          label
          section {
            label
          }
        }
        condition
        threshold
      }
    }
  `,

  'addChartToDashboard':`
    mutation {
      addChartToDashboard(__PARAMS__) {
        id
      }
    }
  `,

  'removeChartFromDashboard':`
    mutation {
      removeChartFromDashboard(__PARAMS__) {
        id
      }
    }
  `,

  'renameDashboardChart':`
    mutation {
      renameDashboardChart(__PARAMS__) {
        id
        name
      }
    }
  `,

  'createCustomChart':`
    mutation {
      createCustomChart(__PARAMS__) {
        id
      }
    }
  `,

  'getOverviewChartID':`
    {
      getOverviewChartID
    }
  `,

  'getCustomChart':`
    {
      getCustomChart {
        id
        name
        mode
        chartPeriod
        numOfDays
        startDate
        endDate
        parameters {
          id
          section {
            id
          }
        }
        paramValues {
          date
          values
        }
      }
    }
  `,

  'deleteCustomChart':`
    mutation {
      deleteCustomChart
    }
  `,

  'getDownloadModelParameters':`
  {
    getDownloadModelParameters {
      efflu_prim_label
      efflu_prim {
        id
        label
        color
        unit
        conflictIds
      }
      refluo_civ_label
      refluo_civ {
        id
        label
        color
        unit
        conflictIds
      }
    }
  }
  `,

  'updateDashboardChartsUiPositions':`
    mutation {
      updateDashboardChartsUiPositions(__PARAMS__){
        id
        uiPosition
      }
    }
  `,

  'uploadExcelAnalysisFile': `
    mutation {
      uploadExcelAnalysisFile(__PARAMS__)
    }
  `,
  'uploadModelSimulationFile': `
    mutation {
      uploadModelSimulationFile(__PARAMS__)
    }
  `,

  'login': `
    {
      login(__PARAMS__) {
        user {
          name
          avatar
        }
        error
        token
      }
    }
  `,

  'logout': `
    {
      logout
    }
  `
};
