import { Component, Input } from '@angular/core';

export interface IChartActionsData {
  items: Array<{ icon: string, payload: any, id?: string }>,
  classes?: string;
}

@Component({
  selector: 'iwt-chart-actions',
  templateUrl: './chart-actions.html'
})
export class ChartActions {
  @Input() data: IChartActionsData;
  @Input() emit: any;

  onClick(payload){
    if(!this.emit) return;
    this.emit('click', payload);
  }
}