import { Component , OnInit } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { UploadDataAnalysisLayoutDS } from './upload-data-analysis-layout.ds';
import { UploadDataAnalysisLayoutEH } from './upload-data-analysis-layout.eh';

import { Utils, Apollo } from '@app/providers';

@Component({
  selector: 'upload-data-analysis-layout',
  templateUrl: './upload-data-analysis-layout.html'
})
export class UploadDataAnalysisLayoutComponent implements OnInit {

  public lb = new LayoutBuilder('upload-data-analysis-layout');
  private widgets = [
    { id: 'data-analysis-upload-alert' },
    { id: 'data-analysis-upload-form' , hasStaticData: true },
    { id: 'list-old-data-analysis-uploads' },
    { id: 'pagination' },
  ];

  constructor(
    private utils: Utils,
    private apollo: Apollo,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ){}

  ngOnInit(){
    // on ready
    this.lb.ready$.subscribe(() => {
      // and utils to make api calls
      this.lb.eventHandler.emitInner('init', {
        utils: this.utils,
        apollo: this.apollo,
        translate: this.translate,
        route: this.route,
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new UploadDataAnalysisLayoutDS(),
      eventHandler: new UploadDataAnalysisLayoutEH()
    });

  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }

}