import { Component, Input } from "@angular/core";

@Component({
  selector: "iwt-setup-download-model-simulation-form",
  templateUrl: "./setup-download-model-simulation-form.html"
})
export class IWTSetupDownloadModelSimulationForm {
  @Input() data: any;
  @Input() emit: any;

  private typedNumOfDays: number = 7;

  //public last_n_days_default = 'Ultimi __NUMBER__ giorni';
  //public last_n_days = 'Ultimi __NUMBER__ giorni';

  onClick(payload: any) {
    if (!this.emit) return;
    this.emit("click", payload);
  }

  onSubmit() {
    if (!this.emit) return;
    this.emit('submit');
  }

  onCancel() {
    if (!this.emit) return;
    this.emit('cancel');
  }

  datePickerEmit(type,payload){
    this.emit('datePicker.'+type , payload);
  }

  onInputChange(payload,value) {
      if (!this.emit) return;
      this.emit("inputChange", {...payload,value});
  }

}
