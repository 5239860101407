
import { Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/auth.guard';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout';
import { CustomizedViewLayoutComponent } from './layouts/customized-view-layout/customized-view-layout';
import { ManageAlertsLayoutComponent } from './layouts/manage-alerts-layout/manage-alerts-layout';
import { ListAlertsLayoutComponent } from './layouts/list-alerts-layout/list-alerts-layout';
// import { TitrimetroInputLayoutComponent } from './layouts/titrimetro-input-layout/titrimetro-input-layout';
// import { UploadDataAnalysisLayoutComponent } from './layouts/upload-data-analysis-layout/upload-data-analysis-layout';
import { ModelSimulationsLayoutComponent } from './layouts/model-simulations-layout/model-simulations-layout';
// import { UploadModelSimulationLayoutComponent } from './layouts/upload-model-simulation-layout/upload-model-simulation-layout';
// import { DownloadModelSimulationLayoutComponent } from './layouts/download-model-simulation-layout/download-model-simulation-layout';
import { ElementsListLayoutComponent } from './layouts/elements-list-layout/elements-list-layout';
import { ChartLayoutComponent } from './layouts/chart-layout/chart-layout';
import { Page404LayoutComponent } from './layouts/page-404-layout/page-404-layout';

export const APP_ROUTES: Routes = [{ 
    path: 'home', 
    component: HomeLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.home', source: 'dashboard' }
  }, { 
    path: 'login', 
    component: LoginLayoutComponent,
    data: { source: 'login' }
  }, { 
    path: 'customized-view', 
    component: CustomizedViewLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.custom_view' }
  }, { 
    path: 'manage-alerts', 
    component: ManageAlertsLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.manage_alerts' }
  }, { 
    path: 'manage-alerts/:page', 
    component: ManageAlertsLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.manage_alerts' }
  }, { 
    path: 'list-alerts', 
    component: ListAlertsLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.list_alerts' }
  }, { 
    path: 'list-alerts/:page', 
    component: ListAlertsLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.list_alerts' }
  }, /* { 
    path: 'titrimetro-input', 
    component: TitrimetroInputLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.titrimetro_input' }
  }, { 
    path: 'titrimetro-input/:page', 
    component: TitrimetroInputLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.titrimetro_input' }
  }, { 
    path: 'upload-data-analysis', 
    component: UploadDataAnalysisLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.upload_data_analysis' }
  }, { 
    path: 'upload-data-analysis/:page', 
    component: UploadDataAnalysisLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.upload_data_analysis' }
  }, */ { 
    path: 'model-simulations', 
    component: ModelSimulationsLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.model_simulations' }
  }, { 
    path: 'model-simulations/:page', 
    component: ModelSimulationsLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.model_simulations' }
  }, /* { 
    path: 'upload-model-simulation', 
    component: UploadModelSimulationLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.upload_model_simulation' }
  }, { 
    path: 'download-model-simulation', 
    component: DownloadModelSimulationLayoutComponent, 
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.download_model_simulation' }
  }, */ {
    path: 'plant-section/:sectionId',
    component: ElementsListLayoutComponent,
    canActivate: [AuthGuard],
    data: { source: 'plantSection' }
  }, {
    path: 'model/:sectionId',
    component: ElementsListLayoutComponent,
    canActivate: [AuthGuard],
    data: { source: 'model' }
  }, {
    path: 'chart/overview',
    component: ChartLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.overview', source: 'overview' }
  }, {
    path: 'custom-chart/:chartId',
    component: ChartLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'i18n.top_headers.custom_view', source: 'custom_chart' }
  }, {
    path: 'plant-section/:sectionId/:elementId',
    component: ChartLayoutComponent,
    canActivate: [AuthGuard],
    data: { source: 'plantSection', }
  }, {
    path: 'model/:sectionId/:elementId',
    component: ChartLayoutComponent,
    canActivate: [AuthGuard],
    data: { source: 'model' }
  }, {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }, { 
    path: '**', 
    component: Page404LayoutComponent 
  }
];
