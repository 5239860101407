export const GET_SECTIONS_MOCK = {
  "data": {
    "getSections": [
      {
        "id": "ingresso_industriale",
        "label": "Ingresso industriale"
      },
      {
        "id": "ingresso_civile",
        "label": "Ingresso civile"
      },
      {
        "id": "preaccumulo",
        "label": "Preaccumulo"
      },
      {
        "id": "sedimentazione_primaria",
        "label": "Sedimentazione primaria"
      },
      {
        "id": "denitrificazione",
        "label": "Denitrificazione"
      },
      {
        "id": "sedimentazione_biologica",
        "label": "Sedimentazione biologica"
      }
    ]
  }
};