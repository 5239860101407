import { EventHandler } from '@n7-frontend/core';

export class SubnavEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({type, payload}) => {
      if(type === 'subnav.click'){
        const parentId = this.dataSource.getParentId();
        this.emitOuter('click', [ parentId, payload ] );
      }
    });

    EventHandler.globalEvents$.subscribe(({ type, payload }) => {
      if(type === 'global.routerChange') {
        this.dataSource.onRouterChange(payload);
      }
    });
  }
}