import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Config } from './constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('it');
    /* const browserLang = this.translateService.getBrowserLang();

    if (Config.get('langs').indexOf(browserLang) !== -1) {
      this.translateService.use(this.translateService.getBrowserLang());
    } else {
      this.translateService.use('en');
    } */
  }
}
