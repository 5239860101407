import { DataSource } from '@n7-frontend/core';
import { IModalData } from '@app/components/modal/modal';

export class ModalDeleteWidgetDS extends DataSource {
  private _chartId: string;

  protected transform(data): IModalData {
    // if(!data) return;

    return {
      isVisible: false,
      classes: 'iwt-layout-home__modal-delete-widget',
      header: {
        label: 'i18n.modal_delete_widget.title',
        closeButton: {
          payload: 'dismiss'
        }
      },
      footer: {
        actions: [{
          label: 'i18n.cancel',
          payload: 'dismiss'
        }, {
          buttonClasses: 'n7dash-btn-ok',
          label: 'i18n.modal_delete_widget.title',
          payload: 'remove'
        }]
      }
    };
  }

  getChartId = () => this._chartId;

  onOpen({ chartId }){
    this._chartId = chartId;
    this.output.isVisible = true;
  }

  onDismiss(){
    this.output.isVisible = false;
  }
}
