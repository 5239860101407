import { Component, Input, ModuleWithComponentFactories } from "@angular/core";
import { helpers, dateHelpers } from "@app/helpers";

@Component({
  selector: "iwt-titrimetro-input-form",
  templateUrl: "./titrimetro-input-form.html"
})
export class IWTTitrimetroInputForm {
  @Input() data: any;
  @Input() emit: any;

  onClick(payload: any) {
    if (!this.emit) return;
    this.emit("click", payload);
  }

  onSubmit() {
    if (!this.emit) return;
    this.emit('submit');
  }

  onCancel() {
    if (!this.emit) return;
    this.emit('cancel');
  }

  onInputChange(payload,value) {
    if (!this.emit) return;
    payload.value = value;
    this.emit("inputChange", payload);
  }

}
