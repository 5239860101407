import { DataSource } from '@n7-frontend/core';
import { IWTMiniCardItemData } from '@app/components/mini-card/mini-card';

export class ParameterCardsDS extends DataSource {
  protected transform(data): IWTMiniCardItemData[] {
    if (!data) {
      return;
    }

    return data.map(el => ({
      ...el,
      icon: 'n7-icon-circle-full',
      payload: el.id,
      _meta: {
        hidden: false
      }
    }));
  }

  onParameterFilterChange(payload: any) {
    this.output.forEach(elem => {
      const isInFilter = (elem[payload.key].toLowerCase().indexOf(payload.value.toLowerCase()) === -1);
      elem._meta.hidden = isInFilter;
    });
  }
}
