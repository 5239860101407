import * as moment from "moment-timezone";
import { Config } from "@app/constants";

export const dateHelpers = {
  toString(str, format?){
    str = (typeof str === 'string' && str.indexOf('Z') !== -1) ? str.replace('Z', '') : str;
    format = format || Config.get('dateStringFormat');
    return moment(str).format(format);
  },
  toDate(date, format?){
    format = format || Config.get('dateStringFormat');
    return moment(date, format);
  },
  getTimezoneDate(timeZone, format?){
    // if not found set default
    if(!moment.tz.zone(timeZone)){
      timeZone = 'Europe/Rome';
    }
    format = format || Config.get('dateStringFormat');
    return moment().tz(timeZone).format(format);
  },
  add(date, value, unit){
    return moment(date).add(value, unit);
  },
  subtract(date, value, unit){
    return moment(date).subtract(value, unit);
  },
  diff(date1, date2, unit){
    return moment(date1).diff(moment(date2), unit);
  }
}