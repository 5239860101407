import { DataSource } from '@n7-frontend/core';
import { dateHelpers } from '@app/helpers';
import { Config } from '@app/constants';

export class TitrimetroInputOldInputsDS extends DataSource {
  data: any;

  protected transform(data){
    if(!data) return;


    var dateFormat = Config.get('dateStringFormatReverseShort');
    data.forEach(d => {
      d.dateStr = dateHelpers.toString(new Date(d.date),dateFormat);
      d.sections.forEach(section => {
        section.name = section.label;
        delete section.label;
        section.data = [];
        section.fields.forEach(field => {
          section.data.push({
            id: field.id,
            field: field.label,
            value: parseFloat(field.value).toFixed(4),
            unit: field.unit
          });
        });
      });
      d.icon = 'n7-icon-edit';
      d.payload = { 'id': d.id };
    });
    this.data = data;
    return data;
  }

  getData(elementId){
    return this.data.find( (d) => d.payload.id == elementId );
  }
}

