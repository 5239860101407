import { Component, Input } from '@angular/core';

export interface StepsIndicatorData {
  totalSteps: number,
  currentStep: number,
  classes?: string;
  payload?: any;
  _meta?: any;
}

@Component({
  selector: 'iwt-steps-indicator',
  templateUrl: './steps-indicator.html'
})
export class IWTStepsIndicator {
  @Input() data: StepsIndicatorData;
  @Input() emit: any;

  constructor() {}

  onClick(type, payload){
    if(!this.emit) return;
    this.emit('click', payload);
  }
}