import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppComponent } from '@app/app.component';
import { APP_IMPORTS } from '@app/app.imports';

import { Configuration } from './providers/configuration.provider';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { Apollo, Utils } from './providers';

// components
import { WrapperComponent } from '@app/components/wrapper/wrapper';
import { IWTManageAlerts } from '@app/components/manage-alerts/manage-alerts';
import { IWTModal } from '@app/components/modal/modal';
import { Chart } from '@app/components/chart/chart';
import { ChartLegend } from '@app/components/chart-legend/chart-legend';
import { IWTMiniCard } from '@app/components/mini-card/mini-card';
import { DropdownComponent } from '@app/components/dropdown/dropdown';
import { ChartSelectFilterComponent } from '@app/components/chart-select-filter/chart-select-filter';
import { Datepicker } from '@app/components/datepicker/datepicker';
import { FooterComponent } from '@app/components/footer/footer';
import { ElementsAccordionComponent } from '@app/components/elements-accordion/elements-accordion';
import { IWTModelSimulationsList } from '@app/components/model-simulations-list/model-simulations-list';
import { IWTTitrimetroInputForm } from '@app/components/titrimetro-input-form/titrimetro-input-form';
import { IWTTitrimetroInputOldSettings } from '@app/components/titrimetro-input-old-inputs/titrimetro-input-old-inputs';
import { IWTDataFileUploadForm } from '@app/components/datafile-upload-form/datafile-upload-form';
import { PopoverMenuComponent } from '@app/components/popover-menu/popover-menu';
import { IWTSetupDownloadModelSimulationForm } from '@app/components/setup-download-model-simulation-form/setup-download-model-simulation-form';
import { IWTGenericDownloadModelSimulationForm } from '@app/components/generic-download-model-simulation-form/generic-download-model-simulation-form';
import { IWTDownloadModelSimulationTableForm } from '@app/components/download-model-simulation-table-form/download-model-simulation-table-form';
import { IWTStepsIndicator } from '@app/components/steps-indicator/steps-indicator';
import { IWTParametersPick } from '@app/components/parameters-pick/paramaters-pick';
import { ChartActions } from '@app/components/chart-actions/chart-actions';
import { FormAlarmCreateComponent } from '@app/components/form-alarm-create/form-alarm-create';
import { SortableList } from '@app/components/sortable-grid/sortable-grid';

// layouts
import { LoginLayoutComponent } from '@app/layouts/login-layout/login-layout';
import { MainLayoutComponent } from '@app/layouts/main-layout/main-layout';
import { HomeLayoutComponent } from '@app/layouts/home-layout/home-layout';
import { ChartLayoutComponent } from '@app/layouts/chart-layout/chart-layout';
import { CustomizedViewLayoutComponent } from '@app/layouts/customized-view-layout/customized-view-layout';
import { ManageAlertsLayoutComponent } from '@app/layouts/manage-alerts-layout/manage-alerts-layout';
import { ElementsListLayoutComponent } from '@app/layouts/elements-list-layout/elements-list-layout';
import { Page404LayoutComponent } from '@app/layouts/page-404-layout/page-404-layout';
import { ModelSimulationsLayoutComponent } from '@app/layouts/model-simulations-layout/model-simulations-layout';
import { ListAlertsLayoutComponent } from '@app/layouts/list-alerts-layout/list-alerts-layout';
import { TitrimetroInputLayoutComponent } from '@app/layouts/titrimetro-input-layout/titrimetro-input-layout';
import { UploadDataAnalysisLayoutComponent } from '@app/layouts/upload-data-analysis-layout/upload-data-analysis-layout';
import { UploadModelSimulationLayoutComponent } from '@app/layouts/upload-model-simulation-layout/upload-model-simulation-layout';
import { DownloadModelSimulationLayoutComponent } from '@app/layouts/download-model-simulation-layout/download-model-simulation-layout';


@NgModule({
  declarations: [
    // components
    AppComponent,
    WrapperComponent,
    IWTManageAlerts,
    IWTModal,
    Chart,
    ChartLegend,
    IWTMiniCard,
    DropdownComponent,
    ChartSelectFilterComponent,
    Datepicker,
    FooterComponent,
    ElementsAccordionComponent,
    IWTModelSimulationsList,
    IWTTitrimetroInputForm,
    IWTTitrimetroInputOldSettings,
    IWTDataFileUploadForm,
    PopoverMenuComponent,
    IWTSetupDownloadModelSimulationForm,
    IWTGenericDownloadModelSimulationForm,
    IWTDownloadModelSimulationTableForm,
    IWTStepsIndicator,
    IWTParametersPick,
    ChartActions,
    FormAlarmCreateComponent,
    SortableList,
    
    // layouts
    LoginLayoutComponent,
    MainLayoutComponent,
    HomeLayoutComponent,
    ChartLayoutComponent,
    CustomizedViewLayoutComponent,
    ManageAlertsLayoutComponent,
    ElementsListLayoutComponent,
    Page404LayoutComponent,
    ModelSimulationsLayoutComponent,
    ListAlertsLayoutComponent,
    TitrimetroInputLayoutComponent,
    UploadDataAnalysisLayoutComponent,
    UploadModelSimulationLayoutComponent,
    DownloadModelSimulationLayoutComponent
  ],
  imports: APP_IMPORTS,
  providers: [
    Apollo,
    Utils,
    AuthGuard,
    AuthService,
    UserService,
    Configuration,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: Configuration) => () => configService.load(),
      deps: [Configuration],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
