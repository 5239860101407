import { DataSource } from '@n7-frontend/core';
import { dateHelpers } from '@app/helpers';
import { Config } from '@app/constants';


export class ListOldDataAnalysisUploadsDS extends DataSource {

  private dateFormatTabular = Config.get('dateStringFormatReverseTabular');

  protected transform(data){
    if(!data) return;

    var transformedOldUploads = [];

    data.forEach(d => {
      transformedOldUploads.push(this.transformSingleOldUplad(d));
    });

    return {
      classes: 'test-layout-table',
      head: this.makeOldUploadsListTableHead(),
      body: transformedOldUploads
    };
  }

  makeOldUploadsListTableHead(){
    return [
      {
        classes: 'HEADER',
          cells: [
              {
                  classes: 'CONTENT',
                  content: this.options.labels.header_date
              },
              {
                  classes: 'CONTENT',
                  content: this.options.labels.header_file_type
              },
              {
                  classes: 'CONTENT',
                  content: this.options.labels.header_file_name
              }
          ],
      }
    ];
  }

  transformSingleOldUplad(rawUpload){
    var timeStr:string = this.convertEpochTimeDateToString(rawUpload.date);
    var fileType:string = rawUpload.fileType;
    var fileName:string = rawUpload.fileName;

    return {
      cells: [
        {
          content: timeStr,
          type: 'html',
          payload: 'old data analysis - CELL'
        },
        {
          content: fileType,
          type: 'html',
          payload: 'old data analysis - CELL'
        },
        {
          content: fileName,
          type: 'html',
          payload: 'old data analysis - CELL'
        }
      ]
    };
  }


  convertEpochTimeDateToString(time: number): string{
    var date = new Date(time);
    return dateHelpers.toString(new Date(date),this.dateFormatTabular);
  }

}




