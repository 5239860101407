import { DataSource } from '@n7-frontend/core';

export class ChartActionsDS extends DataSource {
  protected transform(data) {
    if(!this.options || !this.options.labels) return;

    const { labels, elementConfig } = this.options,
      { hasAlerts } = data;
    let items = [];

    if(elementConfig){
      items.push({
        icon: hasAlerts ? 'n7-icon-bell' : 'n7-icon-bell-add',
        payload: 'alarm',
        tooltip: !hasAlerts ? `${labels.alarm_text} ${elementConfig.label}` : null,
        id: 'chart-alerts-action',
        badge: hasAlerts
      });
    }

    items = items.concat([{
      icon: 'n7-icon-dashboard-add',
      payload: 'dashboard',
      tooltip: 'i18n.chart_actions.add_to_dashboard',
    }]);

    return { items }
  }
}
