import { DataSource } from '@n7-frontend/core';

export class ModelSimulationUploadFormDS extends DataSource {

  private data;
  private file: any;
  datePickerConfig: { _setDatepicker: (datepicker: any) => any; _elementId: string; icon:string; options: { dateFormat: string; defaultDate: any; }; };
  _datepicker: any;

  protected transform(data){
    if(!data) return;

    data['fileFormat'] = ( data.fileFormat ? data.fileFormat : 'csv' );

    var title = this.options.labels.upload_form_title;
    title = title.replace('__FILE_FORMAT__',data.fileFormat);

    var choose_button_text = this.options.labels.upload_form_choose_button;
    choose_button_text = choose_button_text.replace('__FILE_FORMAT__',data.fileFormat);

    var submit_button_text = this.options.labels.upload_form_submit_button;
    submit_button_text = submit_button_text.replace('__FILE_FORMAT__',data.fileFormat);

     this.data = {
      fileFormat: data.fileFormat,
      icon: 'n7-icon-upload',
      title: title,
      fields: ( this.data ? this.data.fields : this.generateNewFormFields() ),
      chooseFileButtonText: choose_button_text,
      chosenFile: ( data.chosenFile ? data.chosenFile : null ),
      chosenFileName: ( ( data.chosenFile && data.chosenFile.name )
                         ? data.chosenFile.name : '' ),
      submitButtonText: submit_button_text,
      selectedDate: new Date()
    }
    return this.data;
  }

  fakeDatepickerEmit(type,payload){
    this.data.selectedDate = payload.selectedDates[0];
  }

  generateNewFormFields(){
    this.datePickerConfig = {
      _setDatepicker: (datepicker) => this._datepicker = datepicker,
      _elementId: 'datepicker',
      icon: 'n7-icon-angle-down',
      options: {
        dateFormat: 'd F Y',
        defaultDate: new Date()
      },
    };

    return [
      {
        payload: { id: 0 } ,
        label: this.options.labels.upload_form_field_name_label,
        placeholder: this.options.labels.upload_form_field_name_placeholder,
        type: "text"
      },
      {
        payload: { id: 1 } ,
        label: this.options.labels.upload_form_field_date_label,
        type: "datepicker",
        fakeData: this.datePickerConfig,
        fakeEmit: this.fakeDatepickerEmit
      }
      ,
      {
        payload: { id: 2 } ,
        label: this.options.labels.upload_form_field_description_label,
        placeholder:  this.options.labels.upload_form_field_description_placeholder,
        type: "multiline-text"
      }
    ];
  }

  chosenFileToUpload(payload){
    if(this.data){
      this.data.chosenFile = payload.target.files[0];
      // To Read the file's content :
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.file = reader.result;
      }
      // reader.readAsText(this.data.chosenFile);
      reader.readAsDataURL(this.data.chosenFile);
      // reader.readAsBinaryString(this.data.chosenFile);

      this.update(this.data);
    }
  }

  createDataToSubmit(){
    if(!this.data) return null;
    return { ...this.data, fileString: this.file };
  }

}
