import { DataSource } from '@n7-frontend/core';
import { IModalData } from '@app/components/modal/modal';

export class ModalCustomChartDS extends DataSource {
  protected transform(data): IModalData {
    // if(!data) return;

    return {
      isVisible: false,
      classes: 'iwt-layout-customized-view__modal',
      header: {
        label: 'i18n.custom_chart.title',
        closeButton: {
          payload: 'dismiss'
        }
      },
      footer: {
        actions: [{
          label: 'i18n.cancel',
          payload: 'dismiss'
        }, {
          isDisabled: false,
          buttonClasses: 'n7dash-btn-ok',
          label: 'i18n.save',
          payload: 'save'
        }]
      }
    };
  }

  onOpen(){
    this.output.isVisible = true;
  }

  onDismiss(){
    this.output.isVisible = false;
  }
}
