import { Component, Input, AfterContentChecked } from '@angular/core';
import Muuri from 'muuri';

@Component({
  selector: 'iwt-sortable-grid',
  templateUrl: './sortable-grid.html'
})
export class SortableList implements AfterContentChecked {
  @Input() data: any;
  @Input() emit: any;
  
  ngAfterContentChecked(){
    if(!this.data || this.data.loaded) return;

    this.data.loaded = true;

    setTimeout(() => {
      const { _elementId, options } = this.data,
        grid = new Muuri(`#${_elementId}`, options);
      // setting sortable list on parent data source
      this.data._setGrid(grid);
    });
  }
}