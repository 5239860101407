import { DataSource } from '@n7-frontend/core';

export class SignupDS extends DataSource {
  private formState: any = {
    inputs: {
      email: null,
      pwd: null
    },
    errors: {}
  };
  private labels: any;

  protected transform(data) {
    const { labels } = this.options;
    this.labels = labels;

    return {
      login: {
        email: {
          id: 'login-email',
          label: labels.email,
          inputType: 'email',
          payload: 'email'
        },
        password: {
          id: 'login-pwd',
          label: labels.password,
          inputType: 'password',
          show: {
            icon: 'far fa-eye',
            label: labels.password,
            payload: 'show-pwd',
          },
          payload: 'password'
        },
        submit: {
          label: labels.submit,
          payload: 'submit',
        }
      }
    };
  }

  onChange({ inputPayload, value }){
    this._setError('submit', null);
    this._setError(inputPayload, null);
    this._setValue(inputPayload, value);
  }

  onFocusout(field){
    if(field === 'email'){
      this._setError(field, this._validateEmail() === false ? this.labels.errors.email : null);
    }
  }

  onSubmit(){
    const { email, password } = this.formState.inputs;
    if(!email) this._setError('email', this.labels.errors.empty);
    if(!password) this._setError('password', this.labels.errors.empty );
  }

  hasErrors(){
    return !!Object.keys(this.formState.errors).filter(key => this.formState.errors[key]).length;
  }

  getFormState = () => this.formState;

  onLoginError() {
    this._setError('submit', this.labels.errors.credentials);
  }

  private _validateEmail(){
    const value = this.formState.inputs.email;
    if(!value) return;

    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  private _setValue(input, value){
    this.formState.inputs[input] = value;
  }

  private _setError(input, error){
    this.formState.errors[input] = error;
    this.output.login[input].error = error;
  }
}