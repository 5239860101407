import { LayoutDataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';

export class ElementsListLayoutDS extends LayoutDataSource {
  private utils: any;
  private sectionId: string | null;
  private sourcesMap: any = {
    plantSection: {
      apiPath: 'plant-section-data',
      title: 'i18n.layouts.plant_section',
      source: 'plant-section',
    },
    model: {
      apiPath: 'model-data',
      title: 'i18n.model',
      source: 'model',
    }
  };
  private currentSource: any;
  private routePaths: string[];
  

  onInit(payload){
    this.utils = payload.utils;
  }

  onRouterChange({ data, params, url }){
    if(params.sectionId && data.source){
      this.sectionId = params.sectionId;
      this.currentSource = this.sourcesMap[data.source];

      this.routePaths = url.map(segment => segment.path);

      // request
      this._loadElements();
    }
  }

  getRoutePaths = () => this.routePaths;

  private _loadElements(){
    const elementsConfig = Config.get('elements'),
      elements = Object.keys(elementsConfig).map(key => elementsConfig[key]);

    this.one('parameter-cards').update(elements.filter(el => {
      const isModel = this.currentSource.source === 'model' && el.hasModel; 
      return (
        (el.section.id === this.sectionId) && 
        (isModel || this.currentSource.source !== 'model') && 
        !el.mode
      ) 
    }));
  }
}
