import { EventHandler } from '@n7-frontend/core';

export class TitrimetroInputLayoutEH extends EventHandler {

  public listen() {

    this.innerEvents$.subscribe(event => {
      switch (event.type) {
        case 'titrimetro-input-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        case 'titrimetro-input-layout.destroy':
          this.dataSource.destroy$.next();
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch (event.type) {
        case 'titrimetro-input-form.click':
          console.log('input form click:', event.payload);
          break;

        case 'titrimetro-input-form.submit':
          console.log('input form submit:', event.payload);
          this.dataSource.onFormSubmit(event.payload);
          break;

        case 'titrimetro-input-old-inputs.click':
          console.log('old settings click:', event.payload);
          break;

        case 'titrimetro-input-old-inputs.iconClick':
          console.log('old settings iconclick:', event.payload);
          this.dataSource.showModal(event.payload);
          break;

        case 'titrimetro-input-datepicker.change':
          this.dataSource.onDatePickerChange(event.payload);
          break;

        case 'titrimetro-input-alert.click':
          this.dataSource.closeAlert();
          break;

        case 'titrimetro-input-modal.click':
          this.emitOuter(event.type, event.payload);
          break;

        case 'titrimetro-input-modal-edit-form.submit':
          this.dataSource.onEditFormSubmit(event.payload, true);
          break;

        case 'titrimetro-input-modal-edit-form.cancel':
          this.dataSource.hideModal({});
          break;

        case 'pagination.change':
          this.dataSource.loading = true;
          break;

        default:
          break;
      }
    });
  }

}