export const GET_MANAGE_ALERTS_DATA_MOCK = {
  "data": {
    "getAlertSettings": [
      {
        "id": "x6z8-alert-dd68",
        "parameter": {
          "id": "den-idrc",
          "section": {
            "id": "denitrificazione"
          }
        },
        "condition": "GREATER",
        "threshold": 2.3,
        "vsModel": false
      },
      {
        "id": "4phz-alert-b9b1",
        "parameter": {
          "id": "sedp-ss105",
          "section": {
            "id": "sedimentazione_primaria"
          }
        },
        "condition": "EQUAL",
        "threshold": 4.5,
        "vsModel": false
      },
      {
        "id": "sftv-alert-74d2",
        "parameter": {
          "id": "civ-fsf",
          "section": {
            "id": "ingresso_civile"
          }
        },
        "condition": "EQUAL",
        "threshold": 1.9,
        "vsModel": false
      },
      {
        "id": "ezgx-alert-pre9",
        "parameter": {
          "id": "civ-cr",
          "section": {
            "id": "ingresso_civile"
          }
        },
        "condition": "GREATER",
        "threshold": 6.9,
        "vsModel": true
      },
      {
        "id": "l9a8-alert-h363",
        "parameter": {
          "id": "den-ptot",
          "section": {
            "id": "denitrificazione"
          }
        },
        "condition": "EQUAL",
        "threshold": 6.4,
        "vsModel": false
      },
      {
        "id": "q6nl-alert-hkes",
        "parameter": {
          "id": "sedb-attq",
          "section": {
            "id": "sedimentazione_biologica"
          }
        },
        "condition": "LESS",
        "threshold": 7.4,
        "vsModel": false
      },
      {
        "id": "x247-alert-8cbj",
        "parameter": {
          "id": "sedb-fsf",
          "section": {
            "id": "sedimentazione_biologica"
          }
        },
        "condition": "LESS",
        "threshold": 8.2,
        "vsModel": false
      },
      {
        "id": "zcim-alert-vns0",
        "parameter": {
          "id": "den-ss105",
          "section": {
            "id": "denitrificazione"
          }
        },
        "condition": "GREATER",
        "threshold": 5,
        "vsModel": false
      },
      {
        "id": "1cq6-alert-dldr",
        "parameter": {
          "id": "civ-sed",
          "section": {
            "id": "ingresso_civile"
          }
        },
        "condition": "GREATER",
        "threshold": 3.8,
        "vsModel": false
      },
      {
        "id": "qw7k-alert-pzds",
        "parameter": {
          "id": "sedp-svi",
          "section": {
            "id": "sedimentazione_primaria"
          }
        },
        "condition": "EQUAL",
        "threshold": 2.5,
        "vsModel": false
      },
      {
        "id": "zhnh-alert-gp27",
        "parameter": {
          "id": "pre-idrc",
          "section": {
            "id": "preaccumulo"
          }
        },
        "condition": "EQUAL",
        "threshold": 6.9,
        "vsModel": true
      },
      {
        "id": "z0se-alert-50in",
        "parameter": {
          "id": "sedp-solvoa",
          "section": {
            "id": "sedimentazione_primaria"
          }
        },
        "condition": "EQUAL",
        "threshold": 5,
        "vsModel": false
      },
      {
        "id": "6hv3-alert-j7jc",
        "parameter": {
          "id": "sedb-attq",
          "section": {
            "id": "sedimentazione_biologica"
          }
        },
        "condition": "EQUAL",
        "threshold": 1.3,
        "vsModel": false
      },
      {
        "id": "y9zo-alert-czed",
        "parameter": {
          "id": "sedp-ph",
          "section": {
            "id": "sedimentazione_primaria"
          }
        },
        "condition": "EQUAL",
        "threshold": 6.7,
        "vsModel": true
      }
    ]
  }
};
