import { DataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';

export class BreadcrumbsDS extends DataSource {
  private _paths: string[];

  protected transform(data) {
    if(!this.options || !this.options.labels || !this._paths) return;

    const elementsConfig = Config.get('elements'),
      sectionsConfig = Config.get('sections'),
      firstSectionId = Object.keys(sectionsConfig)[0];

    let items = [];
    
    // allowed
    if(this._paths.length === 3 && (['model', 'plant-section'].indexOf(this._paths[0]) !== -1)){
      this._paths.forEach((path, index) => {
        let payload = null;
        if(index === 0){
          payload = [path, firstSectionId];
        } else if(index === 1){
          payload = [this._paths[0], this._paths[1]];
        }

        items.push({
          label: index === 2 ? elementsConfig[path].label : this.options.labels[path.replace('-', '_')],
          payload
        });
      });
    }
    return { items };
  }

  isVisible(){
    return this.output && Array.isArray(this.output.items) && this.output.items.length; 
  }

  onRouterChange(payload){
    this._paths = payload.url.map(segment => segment.path);

    // trigger update
    this.update();
  }
}
