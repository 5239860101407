import { DataSource } from '@n7-frontend/core';


export class ModelSimulationDownloadStepsDS extends DataSource {

  protected transform(data){
    if(!data) return;

    let wizardItems = [];

    for(var i=0;i<data.totalSteps;i++){
      let item = {
        number: i+1,
        payload: null
      };
      if(item.number==data.currentStep) item['classes'] = 'is-active';
      else item['classes'] = '';
      wizardItems.push(item);
    }
    return { items: wizardItems };
  }


}
