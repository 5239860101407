import { EventHandler } from '@n7-frontend/core';

export class BreadcrumbsEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({type, payload}) => {
      if(type === 'breadcrumbs.click'){
        this.emitGlobal('breadcrumbClick', payload);
      }
    });

    EventHandler.globalEvents$.subscribe(({ type, payload }) => {
      if(type === 'global.routerChange') {
        this.dataSource.onRouterChange(payload);
      }
    });
  }
}