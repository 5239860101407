import { DataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';

export class SubnavDS extends DataSource {
  private parentId: string | null;
  private activeId: string | null;
  public hidden: boolean = true;

  protected transform() {
    if(!this.parentId) return;
    
    const sections = Config.get('sections'),
      elements = Config.get('elements');

    let items = Object.keys(sections).filter(sectionId => {
        return !!Object.keys(elements).filter(key => {
          const el = elements[key];
          if(this.parentId === 'model'){
            return el.section.id === sectionId && el.hasModel;
          } else {
            return el.section.id === sectionId;
          }
        }).length;
      })
      .map(sectionId => {
        const section = sections[sectionId],
          path = section.id,
          _meta = { id: path };
        return {
          text: section.label, 
          payload: path,
          _meta
        }
      });

    // active control
    this._setActive(items);

    return {
      classes: 'iwt-nav',
      items 
    }
  }

  onRouterChange(payload){
    const paths = payload.url.map(segment => segment.path);
    this.hidden = true;

    if(['plant-section', 'model'].indexOf(paths[0]) !== -1){
      this.parentId = paths[0];
      this.activeId = paths[1];
      this.hidden = false;

      this.update();
    }

    if(this.output) this._setActive(this.output.items);
  }

  getParentId = () => this.parentId;
  isVisible = () => !this.hidden;

  private _setActive(items){
    items.forEach(item => {
      if(this.activeId === item._meta.id){
        item.classes = 'is-current';
      } else {
        item.classes = '';
      }
    });
  }
}
