import { DataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';
import { dateHelpers } from '@app/helpers';


export class ModelSimulationsListDS extends DataSource {
  protected transform(data) {
    if(!data) return;

    const elementsConfig = Config.get('elements');

    let header_date = '';
    let header_simulation_name = '';
    let header_simulation_num_of_params = '';

    if(this.options.labels){
      header_date = this.options.labels.header_date;
      header_simulation_name = this.options.labels.header_simulation_name;
      header_simulation_num_of_params = this.options.labels.header_simulation_num_of_params;
    }

    // first row
    let items: any[] = [{
      header: {
        cells: ['', header_date, header_simulation_name, header_simulation_num_of_params],
      },
      content: null
    }];

    // rows
    data.forEach((row, index) => {
      let groups = [];
      row.sections.forEach(group => {
        groups.push({
          label: `i18n.headers.${group.id}`,
          elements: group.parameters.map(p => ({
            id: p.id,
            color: elementsConfig[p.id].color,
            label: elementsConfig[p.id].label,
            payload: {
              source: 'element',
              id: p.id,
              group: group.id
            }
          }))
        });
      });

      var numOfParams: number = 0;
      row.sections.forEach(s => {
        numOfParams += s.parameters.length;
      });
      items.push({
        header: {
          cells: ['<span class="n7-icon-angle-down"></span>', dateHelpers.toString(row.uploadDate) , row.name, numOfParams],
        },
        content: {
          description: row.description,
          groups
        },
        payload: {
          source: 'header',
          id: index
        },
        _meta: { 
          id: index, 
          isOpen: false 
        },
      })
    })

    return { items };
  }

  onClick(payload){
    const { source, id } = payload;
    let { items } = this.output;
    items.forEach(item => {
      if(!item._meta) return;

      let icon = 'n7-icon-angle-up';
      // update / show
      if(item._meta.id === id && !item._meta.isOpen){
        item._meta.isOpen = true;
        item.classes = 'is-open';
      
      // reset / hide
      } else {
        item._meta.isOpen = false;
        item.classes = '';
        icon = 'n7-icon-angle-down';
      }

      item.header.cells[0] = `<span class="${icon}"></span>`;
    });
  }
}
