import { DataSource } from '@n7-frontend/core';
import { ChartSelectFilterData } from '@app/components/chart-select-filter/chart-select-filter';
import { dateHelpers } from '@app/helpers/date-helpers';

export class CompareSelectFilterDS extends DataSource {
  // last selected w/default
  private _lastSelected: string = 'prev';

  protected transform(data): ChartSelectFilterData {
    if(!data) return;

    const { startDate, endDate } = data,
      daysDiff = dateHelpers.diff(endDate, startDate, 'days');

    const payloads = {
      prev: {
        start: dateHelpers.toString(dateHelpers.subtract(startDate, daysDiff + 1, 'days')),
        end: dateHelpers.toString(dateHelpers.subtract(startDate, 1, 'days'))
      },
      month: {
        start: dateHelpers.toString(dateHelpers.subtract(startDate, 1, 'months')),
        end: dateHelpers.toString(dateHelpers.subtract(endDate, 1, 'months'))
      },
      year: {
        start: dateHelpers.toString(dateHelpers.subtract(startDate, 1, 'years')),
        end: dateHelpers.toString(dateHelpers.subtract(endDate, 1, 'years'))
      },
      custom: {
        start: '',
        end: ''
      }
    };

    let items: any[] = [];

    // options
    ['prev', 'month', 'year', 'custom'].map(key => {
      const isSelected = key === this._lastSelected;

      items.push({
        label: `i18n.chart_compare.select_options.${key}`,
        isSelected,
        payload: {
          _id: key,
          isSelected,
          ...payloads[key]
        },
        _meta: {
          id: key
        }
      })
    })

    return { items };
  }

  public onClick({ type, payload }){
    // set selected
    this.output.items.forEach(item => {
      if(item._meta.id === 'custom') return;

      item.isSelected = payload._id === item._meta.id;
      item.payload.isSelected = payload._id === item._meta.id;
    });
  }

  public getSelectedPayload(){
    return this.output.items.filter(item => item._meta.id === this._lastSelected)[0].payload;
  }
}
