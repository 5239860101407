import { EventHandler } from '@n7-frontend/core';

export class ModelSimulationsLayoutEH extends EventHandler {

  public listen() {
    // listen to inner (layout) events
    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'model-simulations-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        case 'model-simulations-layout.destroy':
          this.dataSource.destroy$.next();
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'pagination.change':
          this.dataSource.loading = true;
          break;

        default: 
          break;
      }
    });
  }

}
