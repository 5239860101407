//export const POST_TITRIMETRO_INPUT_FORM_MOCK = {
//  status: 'ok'
//}

//export const POST_TITRIMETRO_INPUT_FORM_MOCK = {
//  status: 'ko',
//  message: 'Errore, La data inserita presenta gia\' una registrazione'
//}

export const POST_TITRIMETRO_INPUT_FORM_MOCK = {
  "data": {
    "addTitrimeter": {
      "id": "tazb-titri-0ev7",
      "date": "2019-08-19T02:00:00.000Z",
      "author": "Giulio Andreini",
      "sections": [
        {
          "id": "ingresso_industriale",
          "fields": [
            {
              "id": "azoto_ammoniacale",
              "value": 9
            },
            {
              "id": "azoto_totale_filtrato",
              "value": 99
            }
          ]
        }
      ]
    }
  }
}