import { EventHandler } from '@n7-frontend/core';

export class LoginLayoutEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch(type){
        case 'login-layout.init':
          this.dataSource.onInit(payload);
          break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(({ type, payload }) => {
      switch(type){
        case 'signup.submit':
          this.dataSource.onSubmit(payload).subscribe(loggedIn => {
            if(loggedIn){
              this.emitGlobal('navigate', ['/']);
              this.emitGlobal('updateuserinfo');
            } else {
              this.emitOuter('loginerror', this.dataSource.getAuthError());
            }
          });
          break;

        default: 
          break;
      }
    });
  }
}