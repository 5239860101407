import { DataSource } from '@n7-frontend/core';

export class ModelSimulationDownloadStaticSimulationFormDS extends DataSource {

  protected transform(state){
    let data = {
      sections: [
        {
          type: "text_inputs",
          params: []
        }
      ]
    };

    let sectionId = state['param-sections']['current-section-id'];
    let sectionState = state['param-sections'][sectionId];
    if(sectionState['params'])
      Object.keys(sectionState['params']).forEach( key => {
        let p = sectionState['params'][key];
        if(p.selected){
          data.sections[0].params.push({
            id: p.id,
            label: p.label,
            type: 'number',
            value: ( p.value ? p.value :''),
            placeholder: p.unit,
            payload: {
              id: p.id,
              sectionId
            }
          });
        }
      });

    if(!data) return;
    return data;
  }


}
