import { EventHandler } from '@n7-frontend/core';

export class HeaderEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({type, payload}) => {
      if(type === 'header.click'){
        this.emitOuter('click', payload);
      }
    });

    this.outerEvents$.subscribe(({type, payload}) => {
      if(type === 'main-layout.updateuseravatar'){
        this.dataSource.updateUserAvatar(payload);
      }
    });

    EventHandler.globalEvents$.subscribe(({ type, payload }) => {
      if(type === 'global.routerChange') {
        this.dataSource.onRouterChange(payload);
      }
    });
  }
}