import { Component, Input, ModuleWithComponentFactories } from "@angular/core";

@Component({
  selector: "iwt-generic-download-model-simulation-form",
  templateUrl: "./generic-download-model-simulation-form.html"
})
export class IWTGenericDownloadModelSimulationForm {
  @Input() data: any;
  @Input() emit: any;

  onClick(payload: any) {
    if (!this.emit) return;
    this.emit("click", payload);
  }

  onSubmit() {
    if (!this.emit) return;
    this.emit('submit');
  }

  onCancel() {
    if (!this.emit) return;
    this.emit('cancel');
  }

  onInputChange(payload,event) {
    if (!this.emit) return;
    payload.event = event;
    this.emit("inputChange", payload);
  }


}
