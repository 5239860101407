import { Component, Input } from '@angular/core';

export interface AlertEntry {
  item: String[],
  classes?: string;
  payload?: any;
  _meta?: any;
}

@Component({
  selector: 'iwt-manage-alerts',
  templateUrl: './manage-alerts.html'
})
export class IWTManageAlerts {
  @Input() data: AlertEntry;
  @Input() emit: any;

  constructor() {}

  onClick(type, payload){
    if(!this.emit) return;
    this.emit('click', { source: type, payload });
  }
}