import { Component } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { LoginLayoutEH } from './login-layout.eh';
import { LoginLayoutDS } from './login-layout.ds';
import { AuthService } from '@app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'login-layout',
  templateUrl: './login-layout.html'
})
export class LoginLayoutComponent {
  public lb = new LayoutBuilder('login-layout');
  private widgets = [
    { id: 'signup' }
  ];

  constructor(
    private auth: AuthService,
    private translate: TranslateService
  ){}

  ngOnInit(){
    // on ready
    this.lb.ready$.subscribe(() => {
      this.lb.eventHandler.emitInner('init', {
        auth: this.auth,
        translate: this.translate,
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new LoginLayoutDS(),
      eventHandler: new LoginLayoutEH(),
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }
}
