import { DataSource } from '@n7-frontend/core';
import { dateHelpers, helpers } from '@app/helpers';

export class ChartAlertsTableDS extends DataSource {

  protected transform(data){
    if(!data || !this.options || !this.options.elementConfig) return;

    const { values } = data,
      { labels, elementConfig } = this.options;

    return {
      head: [{
        classes: '',
        cells: [{
          content: labels.alerts_table.date
        }, {
          content: elementConfig.label
        }]
      }],
      body: values.map(row => ({
        cells: [{
          content: dateHelpers.toString(row.date, 'YYYY-MM-DD HH:mm')
        }, {
          content: helpers.isNumeric(row.value) ? row.value.toFixed(2) : row.value
        }]
      }))
    };
  }
}