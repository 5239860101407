import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Apollo } from '@app/providers';
import { switchMap, delay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthService {
  public loggedIn: boolean = false;
  public error: boolean = false;

  constructor(
    private user: UserService,
    private apollo: Apollo
  ) {
    // local token control
    if(localStorage.getItem('auth_token')) {
      this._setUser(JSON.parse(localStorage.getItem('user')));
      this.apollo.setToken(localStorage.getItem('auth_token'));
      this.loggedIn = true;
    }

    // unauthorized control
    this.apollo.tokenError$.pipe(
      switchMap(() => this.logout())
    ).subscribe(() => location.reload());
  }

  login(email, password): Observable<boolean> {
    const source$ = this.apollo.request$('login', { email, password });

    return source$.pipe(
      switchMap(response => {
        if(response.token && !response.error){
          this.apollo.setToken(response.token);
          this._setUser(response.user);
          this.loggedIn = true;
          console.log('logged in!')
          this.saveState();
        } else if(response.error){
          this.error = response.error;
          this.loggedIn = false;
        } else {
          this.loggedIn = false;
        }
        return of(this.loggedIn);
      })
    )
  }

  logout(): Observable<boolean> {
    // const source$ = this.apollo.request$('logout');
    const source$ = of(true);
    return source$.pipe(
      switchMap(response => {
        this.destroy();
        this.user.destroy();

        if(response){
          return of(true);
        } else {
          // TODO: gestire errore logout
          return of(false);
        }
      })
    )
  }

  destroy() {
    this.apollo.setToken(null);
    this.loggedIn = false;
    this.error = null;
    this.deleteState();
  }

  saveState(){
    localStorage.setItem('auth_token', this.apollo.token);
    localStorage.setItem('user', this.user.toString());
  }

  deleteState(){
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user');
  }

  private _setUser(user) {
    this.user.setName(user.name);
    this.user.setAvatar(user.avatar);
  }
}
