import { LayoutDataSource } from '@n7-frontend/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Config } from '@app/constants';
import { dateHelpers, helpers } from '@app/helpers';

export class HomeLayoutDS extends LayoutDataSource {
  static newChartId: string | null;

  private apollo: any;
  private translate: any;
  private tippy: any;
  private response: any;
  private labels: any;
  protected preloadedLabels = {
    'model': 'i18n.model',
    'diff': 'i18n.diff',
    'modal_delete_widget': 'i18n.modal_delete_widget',
  };
  private chartMenus: any = {};
  private currentChartIdsOrder: string[];

  public charts = [];
  public modalDeleteDescription: string = '';
  public addedChartId: string;
  public dragAreaTooltip = 'i18n.layouts.home.drag_area_tooltip';

  public placeholdersToShow: number = 2;
  public emptyCharts: any = {};

  onInit(payload){
    this.apollo = payload.apollo;
    this.translate = payload.translate;
    this.tippy = payload.tippy;
    this.response = payload.response;

    this.response.charts
      .sort((a, b) => a.uiPosition - b.uiPosition)
      .forEach((chartData, index) => this.charts.push({ 
      elId: `chart-${index}`, 
      data: chartData,
      inputEnabled: false
    }));

    // set current order
    this.currentChartIdsOrder = this.charts.map(({ data }) => data.id);
    
    this.updatePlaceholdersToShow();

    // highlight new chart
    this._highlightNewChart();

    // preloaded translations
    this._loadTranslatedLabels();
  }

  onActionClick(payload){
    this.toggleChartMenu(payload);
  }

  onChartMenuClick(payload){
    const selectedChartId = this.getSelectedChartId();
    this.toggleChartMenu(selectedChartId);

    if(payload === 'refresh'){
      this._handleChartRefresh(selectedChartId);
    } else if(payload === 'rename'){
      this._handleChartRename(selectedChartId);
    }
  }

  updatePlaceholdersToShow(){
    if( this.charts.length % 2 != 0 ){
      this.placeholdersToShow = 1;
    } else this.placeholdersToShow = 2;
  }

  onRemoveChart(chartId){
    this._handleChartRemove(chartId);
  }

  onInputSubmit({id, value: name}){
    this.apollo.request$('renameDashboardChart', { id, name }).subscribe(response => {
      this.charts.forEach(chart => {
        if(chart.data.id === response.id){
          chart.data.name = response.name;
          chart.inputEnabled = false;

          // update
          this.one(`${chart.elId}-title`).update(chart.data);
        }
      });
    });
  }

  onOpenReorderWidgetsModal(){
    // update sortable list
    this.one('sortable-widgets').update({ charts: this.charts });
  }

  getSelectedChartId(){
    let id: any = null;
    Object.keys(this.chartMenus).forEach(chartId => {
      if(this.chartMenus[chartId].open) id = chartId;
    });
    return id;
  }

  getChartDateText({ startDate, endDate, chartPeriod, numOfDays }){
    if(chartPeriod === 'LAST_N_DAYS' && numOfDays){
      return `i18n.chart_select.options.day${numOfDays}`
    } else {
      return [
        dateHelpers.toString(startDate, Config.get('dateStringFormatReverseShort')),
        ' - ',
        dateHelpers.toString(endDate, Config.get('dateStringFormatReverseShort')),
      ].join('');
    }
  }

  setModalDeleteDescription(chartId){
    let label: any = '';
    this.charts.forEach(({ data }) => {
      if(data.id === chartId){
        label = data.name;
        if(!label){
          const elementsConfig = Config.get('elements');
          label = elementsConfig[data.parameters[0].id].label;
        }
      }
    });
    this.modalDeleteDescription = this.labels.modal_delete_widget.description.replace('__NAME__', label);
  }
  
  toggleChartMenu(chartId){
    if(!this.chartMenus[chartId]){
      this.chartMenus[chartId] = { instance: null, open: false };

      const template = document.getElementById('chart-menu__' + chartId)
      template.style.display = 'block';
  
      this.chartMenus[chartId].instance = this.tippy('#chart_' + chartId, {
        content: template,
        trigger: 'manual',
        interactive: true,
        arrow: true,
        theme: 'light-border',
        placement: 'bottom',
        onHidden: () => this.chartMenus[chartId].open = false,
      })[0];
    }
    
    if(this.chartMenus[chartId].open){
      this.chartMenus[chartId].instance.hide();
    } else {
      this.chartMenus[chartId].instance.show();
    }

    this.chartMenus[chartId].open = !this.chartMenus[chartId].open;
  }

  getInputNamePlaceholder$(out$: Observable<any>): Observable<string>{
    return out$.pipe(
      map(({ items }) => {
        if(Array.isArray(items) && items.length){
          if(items.length === 1){
            return items[0].label;
          } else if(items.length === 2) {
            return items[1].label;
          }
        }
      })
    );
  }

  saveWidgetsOrderRequest$(chartIds){
    if(this.currentChartIdsOrder.toString() !== chartIds.toString()){
      return this.apollo.request$('updateDashboardChartsUiPositions', { chartIds });
    }
    return of(null);
  }

  reorderCharts(response){
    let uiPositions = {};
    response.forEach(item => uiPositions[item.id] = item.uiPosition);
    this.charts.forEach(chart => {
      chart.data.uiPosition = uiPositions[chart.data.id];
    });

    // update current order
    this.currentChartIdsOrder = response.map(item => item.id);

    // reorder
    this.charts = this.charts.sort((a, b) => a.data.uiPosition - b.data.uiPosition);
  }

  getNewChartId = () => HomeLayoutDS.newChartId;

  private _handleChartRefresh(id){
    // FIXME: da vedere con @giulio
    // se ha senso questa feature
    console.log('refresh', id);
  }
  private _handleChartRename(id){
    this.charts.forEach((chart, index) => {
      if(chart.data.id === id) chart.inputEnabled = !chart.inputEnabled;
    });
  }
  private _handleChartRemove(id){
    this.apollo.request$('removeChartFromDashboard', { id }).subscribe(response => {
      let indexToRemove;
      this.charts.forEach((chart, index) => {
        if(chart.data.id === response.id) indexToRemove = index;
      });
      this.charts.splice(indexToRemove, 1);
      this.updatePlaceholdersToShow();
    });
  }

  protected _loadTranslatedLabels(){
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe(labels => {
      this.labels = labels;
      this._loadCharts();
    });
  }

  private _loadCharts() {
    this.response.charts.forEach((chartData, index) => {
      const chartId = `chart-${index}`, 
        titleId = chartId + '-title',
        legendId = chartId + '-legend';
      this.one(chartId).updateOptions({ labels: this.labels }); 
      this.some([titleId, legendId]).update({ 
        chartId: chartId, 
        ...chartData 
      });
      if(Array.isArray(chartData.values) && chartData.values.length){
        this.one(chartId).update({ 
          chartId: chartId, 
          ...chartData 
        });
      } else {
        this.emptyCharts[chartId] = true;
      }
    });
  }

  private _highlightNewChart(){
    setTimeout(() => {
        let highlighted = document.getElementsByClassName('is-highlighted')[0];
        helpers.scrollToDomElement(highlighted);
    },50);

    setTimeout(() => {
      HomeLayoutDS.newChartId = null;
    }, 10000);
  }
}