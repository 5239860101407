import { DataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';

/* export class FooterDS extends DataSource {
  protected transform(data): IFooterData {
    return { 
      logo: `${Config.get('assetsPath')}/img/Logo.png` 
    };
  }
} */

export class FooterDS extends DataSource {
  protected transform(data) {
    return {
      columns : [
        {
          classes: 'col-class',
          title: 'I-SWAT',
          text: 'Innovation in System for analysis and control in WAter Treatment<br><strong>Versione demo: i dati riportati sono solo a titolo esemplificativo.</strong>'
        },
        {
          images: [
            {
              url: `${Config.get('assetsPath')}/img/logo-porcreo.png`,
              alttext: 'PORCreO'
            },
            {
              url: `${Config.get('assetsPath')}/img/logo-unione.png`,
              alttext: 'Unione Europea'
            },
            {
              url: `${Config.get('assetsPath')}/img/logo-repubblica.png`,
              alttext: 'Repubblica Italiana'
            },
            {
              url: `${Config.get('assetsPath')}/img/logo-regione.png`,
              alttext: 'Regione Toscana'
            }
          ]
        }
      ]
    }
  }
}