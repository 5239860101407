import { EventHandler } from '@n7-frontend/core';

export class ListAlertsLayoutEH extends EventHandler {

  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'list-alerts-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        case 'list-alerts-layout.destroy':
          this.dataSource.destroy$.next();
          break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'list-alerts-table.cellClick':
          this.dataSource.onTableCellClick(event.payload);
          break;

        case 'list-alerts-table.cellDblClick':
          this.dataSource.onTableCellDoubleClick(event.payload);
          break;

        case 'pagination.change':
          this.dataSource.loading = true;
          break;

        default: 
          break;
      }
    });
  }

}