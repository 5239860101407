import { Component, Input } from '@angular/core';

@Component({
  selector: 'iwt-dropdown',
  templateUrl: './dropdown.html'
})
export class DropdownComponent {
  @Input() data: any;
  @Input() emit: any;

  constructor() {}

  onClick(payload){
    if(!this.emit) return;
    this.emit('click', payload);
  }
}