import { GET_ELEMENTS_LIST_MOCK } from './get-elements-list.mock';
// EDGAR: GET_ELEMENTS_LIST_MOCK mi sembra che lo usi anche da qualche
//         altra parte, ma comunque se poi non si usa piu' va' eliminato

export const GET_MODEL_SIMULATIONS_LIST_MOCK = {
  "data": {
    "getSimulations": [
      {
        "id": "c0dei-SIMULATION-ojuw8",
        "uploadDate": "2019-07-23T07:33:34.934Z",
        "name": "prova simulazione",
        "description": "Nell'ambito delle simulazioni, acquisisce notevole importanza la simulazione del funzionamento dei processi produttivi e logistici. Tali sistemi sono infatti caratterizzati da elevata complessità, numerose interrelazioni tra i diversi processi che li attraversano, guasti dei segmenti, indisponibilità, stocasticità dei parametri del sistema. Consideriamo, ad esempio, un impianto semplice per la produzione di un unico articolo, con solamente due macchine automatiche ed imballaggio manuale; in questo semplice sistema l'arrivo delle materie prime, la durata delle lavorazioni, il tempo necessario agli operatori per imballare sono tutte variabili stocastiche, in quanto il ritmo produttivo e di arrivo non è costante; inoltre, le macchine sono soggette a guasti e manutenzione, gli operatori possono non essere sempre disponibili etc.\n  Il progettista degli impianti industriali e il responsabile delle operations possono certamente avere interesse a valutare con anticipo l'effetto delle loro scelte su tali sistemi complessi, in termini, ad esempio, di capacità di produzione, tempo di attraversamento, scorte, blocchi. Possono inoltre avere dei problemi riguardo al dimensionamento di macchine, magazzini, flotta dei carrelli trasportatori e simili.\n  La simulazione, consentendo l'analisi della realtà a un elevato livello di dettaglio e padroneggiando facilmente la complessità del sistema, fa sì che alla fine sia possibile ottenere un gran numero di informazioni utili. Il prezzo da pagare per tale completezza è ovviamente il tempo; le operazioni di programmazione sono infatti assai lunghe, affinché si possano ottenere dei dati sufficientemente sensati e tali da dare la possibilità di ottenere un modello della realtà ad essa aderente.",
        "sections": [
          {
            "id": "ingresso_industriale",
            "parameters": [
              {
                "id": "ing-ph"
              },
              {
                "id": "ing-attq"
              },
              {
                "id": "ing-cdfr"
              },
              {
                "id": "ing-ntf"
              },
              {
                "id": "ing-frro"
              },
              {
                "id": "ing-idrc"
              },
              {
                "id": "ing-ss105"
              },
              {
                "id": "ing-uptk"
              },
              {
                "id": "ing-toc"
              },
              {
                "id": "ing-cr"
              },
              {
                "id": "ing-norg"
              }
            ]
          },
          {
            "id": "preaccumulo",
            "parameters": [
              {
                "id": "pre-nnh4"
              },
              {
                "id": "pre-oss"
              },
              {
                "id": "pre-nichl"
              },
              {
                "id": "pre-idrc"
              },
              {
                "id": "pre-ss105"
              },
              {
                "id": "pre-uptk"
              },
              {
                "id": "pre-toc"
              },
              {
                "id": "pre-br"
              },
              {
                "id": "pre-svi"
              },
              {
                "id": "pre-solvclor"
              }
            ]
          }
        ]
      },
      {
        "id": "cqekj-SIMULATION-xmga0",
        "uploadDate": "2019-07-20T14:42:59.374Z",
        "name": "Simulazione A",
        "description": "Note: controllo generale sui valori",
        "sections": [
          {
            "id": "ingresso_civile",
            "parameters": [
              {
                "id": "civ-attq"
              },
              {
                "id": "civ-ptot"
              },
              {
                "id": "civ-uptk"
              }
            ]
          },
          {
            "id": "denitrificazione",
            "parameters": [
              {
                "id": "den-oss"
              },
              {
                "id": "den-ph"
              },
              {
                "id": "den-rme"
              },
              {
                "id": "den-frro"
              },
              {
                "id": "den-pmb"
              },
              {
                "id": "den-idrc"
              },
              {
                "id": "den-ss105"
              },
              {
                "id": "den-ptot"
              },
              {
                "id": "den-uptk"
              },
              {
                "id": "den-toc"
              },
              {
                "id": "den-cdmio"
              },
              {
                "id": "den-fenli"
              }
            ]
          }
        ]
      },
      {
        "id": "2vrch-SIMULATION-s2akp",
        "uploadDate": "2019-06-24T05:32:43.336Z",
        "name": "test (dopo ferie)",
        "description": "Simulazione eseguita per prova",
        "sections": [
          {
            "id": "sedimentazione_primaria",
            "parameters": [
              {
                "id": "sedp-oss"
              },
              {
                "id": "sedp-nichl"
              },
              {
                "id": "sedp-idrc"
              },
              {
                "id": "sedp-ss105"
              },
              {
                "id": "sedp-ptot"
              },
              {
                "id": "sedp-fsf"
              },
              {
                "id": "sedp-clor"
              },
              {
                "id": "sedp-solvoa"
              },
              {
                "id": "sedp-cdmio"
              }
            ]
          },
          {
            "id": "denitrificazione",
            "parameters": [
              {
                "id": "den-ph"
              },
              {
                "id": "den-rme"
              },
              {
                "id": "den-frro"
              },
              {
                "id": "den-pmb"
              },
              {
                "id": "den-uptk"
              },
              {
                "id": "den-cdmio"
              }
            ]
          }
        ]
      },
      {
        "id": "zpjv6-SIMULATION-49pj6",
        "uploadDate": "2019-06-05T21:34:00.592Z",
        "name": "prova",
        "description": "Controllo per i valori specifici studiati nell' ultimo meeting",
        "sections": [
          {
            "id": "ingresso_industriale",
            "parameters": [
              {
                "id": "ing-sed"
              },
              {
                "id": "ing-nno3"
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "parameters": [
              {
                "id": "civ-sed"
              },
              {
                "id": "civ-attq"
              },
              {
                "id": "civ-cdfr"
              },
              {
                "id": "civ-nno2"
              },
              {
                "id": "civ-nichl"
              },
              {
                "id": "civ-ss105"
              },
              {
                "id": "civ-uptk"
              },
              {
                "id": "civ-toc"
              },
              {
                "id": "civ-br"
              },
              {
                "id": "civ-cr"
              },
              {
                "id": "civ-fsf"
              }
            ]
          },
          {
            "id": "preaccumulo",
            "parameters": [
              {
                "id": "pre-nno3"
              },
              {
                "id": "pre-frro"
              },
              {
                "id": "pre-nichl"
              },
              {
                "id": "pre-idrc"
              },
              {
                "id": "pre-cr"
              },
              {
                "id": "pre-solvoa"
              }
            ]
          },
          {
            "id": "denitrificazione",
            "parameters": [
              {
                "id": "den-oss"
              },
              {
                "id": "den-sed"
              },
              {
                "id": "den-attq"
              },
              {
                "id": "den-nichl"
              }
            ]
          },
          {
            "id": "sedimentazione_biologica",
            "parameters": [
              {
                "id": "sedb-oss"
              },
              {
                "id": "sedb-attq"
              },
              {
                "id": "sedb-nno2"
              }
            ]
          }
        ]
      },
      {
        "id": "b5fbr-SIMULATION-xmnn5",
        "uploadDate": "2019-05-28T21:01:26.131Z",
        "name": "Risulati Attuali, Negativi?",
        "description": "Controllo per i valori specifici studiati nell' ultimo meeting",
        "sections": [
          {
            "id": "ingresso_industriale",
            "parameters": [
              {
                "id": "ing-attq"
              },
              {
                "id": "ing-cdfr"
              },
              {
                "id": "ing-ntf"
              },
              {
                "id": "ing-nno2"
              },
              {
                "id": "ing-pmb"
              },
              {
                "id": "ing-idrc"
              },
              {
                "id": "ing-toc"
              },
              {
                "id": "ing-norg"
              }
            ]
          },
          {
            "id": "ingresso_civile",
            "parameters": [
              {
                "id": "civ-sed"
              },
              {
                "id": "civ-ntf"
              },
              {
                "id": "civ-nno3"
              },
              {
                "id": "civ-ss105"
              },
              {
                "id": "civ-ptot"
              },
              {
                "id": "civ-uptk"
              },
              {
                "id": "civ-toc"
              },
              {
                "id": "civ-br"
              },
              {
                "id": "civ-cr"
              },
              {
                "id": "civ-fsf"
              }
            ]
          },
          {
            "id": "preaccumulo",
            "parameters": [
              {
                "id": "pre-ph"
              },
              {
                "id": "pre-rme"
              },
              {
                "id": "pre-frro"
              },
              {
                "id": "pre-idrc"
              },
              {
                "id": "pre-toc"
              },
              {
                "id": "pre-br"
              },
              {
                "id": "pre-clor"
              },
              {
                "id": "pre-solvoa"
              },
              {
                "id": "pre-solvclor"
              }
            ]
          },
          {
            "id": "sedimentazione_primaria",
            "parameters": [
              {
                "id": "sedp-ph"
              },
              {
                "id": "sedp-sed"
              },
              {
                "id": "sedp-attq"
              },
              {
                "id": "sedp-cdfr"
              },
              {
                "id": "sedp-rme"
              },
              {
                "id": "sedp-frro"
              },
              {
                "id": "sedp-nichl"
              },
              {
                "id": "sedp-pmb"
              },
              {
                "id": "sedp-idrc"
              },
              {
                "id": "sedp-ss105"
              },
              {
                "id": "sedp-fsf"
              },
              {
                "id": "sedp-bod"
              },
              {
                "id": "sedp-ecol"
              },
              {
                "id": "sedp-solvoa"
              },
              {
                "id": "sedp-solvclor"
              }
            ]
          }
        ]
      },
      {
        "id": "3erx4-SIMULATION-2gnz8",
        "uploadDate": "2019-03-28T07:35:02.539Z",
        "name": "Simulazione I",
        "description": "Controllo per i valori specifici studiati nell' ultimo meeting",
        "sections": [
          {
            "id": "ingresso_civile",
            "parameters": [
              {
                "id": "civ-sed"
              },
              {
                "id": "civ-attq"
              },
              {
                "id": "civ-cdfr"
              },
              {
                "id": "civ-ntf"
              },
              {
                "id": "civ-nno2"
              },
              {
                "id": "civ-nno3"
              },
              {
                "id": "civ-rme"
              },
              {
                "id": "civ-nichl"
              },
              {
                "id": "civ-ss105"
              },
              {
                "id": "civ-uptk"
              },
              {
                "id": "civ-toc"
              },
              {
                "id": "civ-br"
              },
              {
                "id": "civ-cr"
              },
              {
                "id": "civ-fsf"
              }
            ]
          },
          {
            "id": "preaccumulo",
            "parameters": [
              {
                "id": "pre-frro"
              },
              {
                "id": "pre-pmb"
              }
            ]
          }
        ]
      },
      {
        "id": "glfqg-SIMULATION-auzjm",
        "uploadDate": "2019-03-08T17:43:00.788Z",
        "name": "Simualzione rateo di nitrificazione",
        "description": "Simulazione eseguita per prova",
        "sections": [
          {
            "id": "ingresso_industriale",
            "parameters": [
              {
                "id": "ing-svi"
              },
              {
                "id": "ing-norg"
              }
            ]
          },
          {
            "id": "preaccumulo",
            "parameters": [
              {
                "id": "pre-nnh4"
              },
              {
                "id": "pre-oss"
              },
              {
                "id": "pre-ph"
              },
              {
                "id": "pre-nno2"
              },
              {
                "id": "pre-nno3"
              },
              {
                "id": "pre-nichl"
              },
              {
                "id": "pre-pmb"
              },
              {
                "id": "pre-idrc"
              },
              {
                "id": "pre-ss105"
              },
              {
                "id": "pre-uptk"
              },
              {
                "id": "pre-toc"
              },
              {
                "id": "pre-br"
              },
              {
                "id": "pre-cr"
              },
              {
                "id": "pre-solvoa"
              },
              {
                "id": "pre-solvclor"
              }
            ]
          },
          {
            "id": "denitrificazione",
            "parameters": [
              {
                "id": "den-sed"
              },
              {
                "id": "den-pmb"
              }
            ]
          },
          {
            "id": "sedimentazione_biologica",
            "parameters": [
              {
                "id": "sedb-sed"
              },
              {
                "id": "sedb-attq"
              },
              {
                "id": "sedb-frro"
              },
              {
                "id": "sedb-bod"
              }
            ]
          }
        ]
      }
    ]
  }
};