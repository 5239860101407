import { Component , OnInit } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';
import { TranslateService } from '@ngx-translate/core';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { DownloadModelSimulationLayoutDS } from './download-model-simulation-layout.ds';
import { DownloadModelSimulationLayoutEH } from './download-model-simulation-layout.eh';

import { Utils } from '@app/providers';
import { Apollo } from '@app/providers';

@Component({
  selector: 'download-model-simulation-layout',
  templateUrl: './download-model-simulation-layout.html'
})
export class DownloadModelSimulationLayoutComponent implements OnInit {

  public lb = new LayoutBuilder('download-model-simulation-layout');
  private widgets = [
    { id: 'model-simulation-download-steps' },
    { id: 'model-simulation-download-setup-form' } ,
    { id: 'model-simulation-download-params-selection' },
    { id: 'model-simulation-download-static-simulation-form' },
    { id: 'model-simulation-download-dynamic-simulation-table'}
  ];

  constructor(
    private utils: Utils,
    private apollo: Apollo,
    private translate: TranslateService
  ){}

  ngOnInit(){
    // on ready
    this.lb.ready$.subscribe(() => {
      // and utils to make api calls
      this.lb.eventHandler.emitInner('init', {
        utils: this.utils,
        translate: this.translate,
        apollo: this.apollo
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new DownloadModelSimulationLayoutDS(),
      eventHandler: new DownloadModelSimulationLayoutEH()
    });
  }

}