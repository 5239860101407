//export const DELETE_ALERT_MOCK = {
//  status: 'ok',
//  id: 1
//}

export const DELETE_ALERT_MOCK = {
  "data": {
    "deleteAlertSettings": [
      {
        "id": "alertId1_staticMockNoApollo",
        "parameter": {
          "id": "ing-frro",
          "label": "Ferro",
          "section": {
            "label": "Ingresso industriale"
          }
        }
      }
    ]
  }
}