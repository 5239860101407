import { EventHandler } from '@n7-frontend/core';

export class ManageAlertsDeleteConfirmationModalEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'manage-alerts-delete-confirmation-modal.click':
          this.emitOuter('click', event.payload);
          break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      // TODO
    });
  }
}
