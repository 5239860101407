import { EventHandler } from '@n7-frontend/core';

export class PaginationEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'pagination.click':
          if(event.payload){
            const basePath = this.dataSource.getBasePath();
            // sending global signal
            this.emitGlobal('navigate', [basePath + event.payload]);
            // sending signal to layout
            this.emitOuter('change');
          }
          break;

        default:
          break;
      }
    });

  }
}