import { LayoutDataSource } from '@n7-frontend/core';
import { map } from 'rxjs/operators';
import { Config } from '@app/constants';
import { Observable, of, Subject } from 'rxjs';
import { UserService, AuthService } from '@app/services';

export class MainLayoutDS extends LayoutDataSource {
  private translate: any;
  private router: any;
  private user: UserService;
  private tippy: any;
  private apollo: any;
  private auth: AuthService;
  private userMenu: any;
  private userMenuOpen: boolean = false;
  private alertsMenu: any;
  private alertsMenuOpen: boolean = false;
  private sectionsConfig: any;
  private pageTitle: string = '';
  private labels: any;
  public elementConfig: any;
  public isLogin: boolean = true;
  public alertData$: Subject<any> = new Subject();

  private preloadedLabels = {
    'home': 'i18n.top_headers.home',
    'overview': 'i18n.top_headers.overview',
    'plant_section': 'i18n.top_headers.plant_section',
    'customized_view': 'i18n.top_headers.custom_view',
    'model': 'i18n.top_headers.model',
    'list_alerts': 'i18n.top_headers.list_alerts',
    'manage_alerts': 'i18n.top_headers.manage_alerts',
    'model_simulations': 'i18n.top_headers.model_simulations',
    'titrimetro_input': 'i18n.top_headers.titrimetro_input',
    'upload_data_analysis': 'i18n.top_headers.upload_data_analysis',
    'alerts_table': 'i18n.alerts_table',
    'alarm_create': 'i18n.alarm_create',
    'alarm_update': 'i18n.alarm_update'
  };

  public modalAlarmStatus: any = {
    error: null,
    success: null,
    loading: false
  };

  public pageTools: any[] | null;

  onInit(payload){
    this.translate = payload.translate;
    this.router = payload.router;
    this.tippy = payload.tippy;
    this.apollo = payload.apollo;
    this.user = payload.user;
    this.auth = payload.auth;

    // preloaded translations
    this._loadTranslatedLabels();

    // config
    this.sectionsConfig = Config.get('sections');
  }

  onSubnavClick(payload){
    this.router.navigate(payload);
  }

  onHeaderClick(payload){
    if(payload.source === 'nav'){
      this.router.navigate([payload.path]);
    } else if(payload.source === 'userButton'){
      this._toggleUserMenu();
    } else if(payload.source === 'alertsButton'){
      this._toggleAlertsMenu();
    }
  }

  onUserMenuClick(payload){
    if(!payload) return;
    
    if(payload === 'logout'){
      // this.auth.logout().subscribe(() => this.router.navigate(['/login']));
      this.auth.logout().subscribe(() => location.reload());
    } else {
      this.router.navigate([payload]);
    }
  }

  onLoadChart(payload){
    this.router.navigate(payload);
  }

  onBreadcrumbClick(payload){
    if(payload) this.router.navigate(payload);
  }

  onRouterChange({ data, params, url }){
    const paths = url.map(segment => segment.path);
    this.pageTitle = '';
    this.isLogin = (data.source === 'login' ? true : false);
    
    if(data && data.title){
      this.pageTitle = data.title;
    } else if(data && data.source && paths.length === 2){
      this.pageTitle = this.sectionsConfig[paths[1]].label;
    }

    this._updatePageTools({ data, params, url });

    // hide tooltips
    this.tippy.hideAll();

    // hide alert
    this.alertData$.next(null);
  }

  getPageTitle = () => this.pageTitle;

  onOpenAlertModal(payload){
    // reset 
    this.modalAlarmStatus = { success: null, error: null, loading: false };
    this.some([
      'modal-alarm-create',
      'form-alarm-create'
    ]).update(payload);
  }

  onModalAlarmClick(payload): Observable<any> {
    if(payload.source === 'save'){
      const ds = this.widgets['form-alarm-create'].ds,
        formState = ds.getFormState(),
        input = ds.getWidgetInput();
      return this._saveAlarm(formState, input);
    } else {
      of(null);
    }
  }

  onNavigate(payload){
    this.router.navigate(payload);
  }

  onAlarmCreated(response, payload){
    if(payload.isUpdate){
      this._updateAlertData({ text: this.labels.alarm_update.success_msg });
    } else {
      this._updateAlertData({ text: this.labels.alarm_create.success_msg });
    }

    // reset form
    this.widgets['form-alarm-create'].ds.reset();
  }

  closeAlert(){
    console.count('close-alert');
    this.alertData$.next(null);
  }

  getUserAvatar = () => this.user.getAvatar();

  private _updateAlertData(config){
    this.alertData$.next({
      text: config.text,
      hasCloseButton: typeof config.hasCloseButton === 'boolean' ? config.hasCloseButton : true,
      payload: "close",
      classes: config.classes || "is-success" 
    });
  }

  private _loadTranslatedLabels(){
    // add section labels
    const sections = Config.get('sections');
    Object.keys(Config.get('sections')).forEach(key => {
      const { id, label } = sections[key];
      this.preloadedLabels[id] = label;
    });
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe(labels => {
      this.labels = labels;

      // update widgets that need preloaded labels
      this.some(['header', 'subnav', 'breadcrumbs', 'modal-alarm-create']).updateOptions({ labels });
      this.some(['header', 'subnav', 'breadcrumbs']).update({});
      
      // custom update for alert popover
      this.one('list-alerts-table').updateOptions({ labels });
    });
  }

  private _toggleUserMenu(){
    if(!this.userMenu){
      const template = document.getElementById('user-menu')
      template.style.display = 'block';
  
      this.userMenu = this.tippy('.n7-header__user-img', {
        content: template,
        trigger: 'manual',
        interactive: true,
        arrow: true,
        theme: 'light-border',
        onHidden: () => this.userMenuOpen = false,
      })[0];
    }
    
    if(this.userMenuOpen){
      this.userMenu.hide();
    } else {
      this.userMenu.show();
    }

    this.userMenuOpen = !this.userMenuOpen;
  }

  private _toggleAlertsMenu(){
    if(!this.alertsMenu){
      const template = document.getElementById('alerts-menu-loading');
      template.style.display = 'block';
  
      this.alertsMenu = this.tippy('.header-action-alerts', {
        content: template,
        trigger: 'manual',
        interactive: true,
        arrow: true,
        theme: 'light-border',
        onHidden: () => this.alertsMenuOpen = false,
      })[0];

      // load alerts
      this._loadAlerts();
    }
    
    if(this.alertsMenuOpen){
      this.alertsMenu.hide();
    } else {
      this.alertsMenu.show();
    }

    this.alertsMenuOpen = !this.alertsMenuOpen;
  }

  private _loadAlerts(){
    const apolloRequest$ = this.apollo.request$('getAlertNotifications', {
      pagination: {
        limit: Config.get('popoverAlertsLimit'),
        offset: 1
      }
    });
    apolloRequest$.subscribe(response => this._handleAlertsRequest(response));
  }

  private _handleAlertsRequest({ totalCount, items }){
    this.one('list-alerts-table').update(items);

    // update tooltip
    this.alertsMenu.destroy();
    setTimeout(() => {
      const template = document.getElementById('alerts-menu-loaded');
      template.style.display = 'block';
  
      this.alertsMenu = this.tippy('.header-action-alerts', {
        content: template,
        trigger: 'manual',
        interactive: true,
        arrow: true,
        theme: 'light-border no-padding',
        placement: 'bottom-end',
        maxWidth: 500,
        onHidden: () => this.alertsMenuOpen = false,
      })[0];

      this.alertsMenu.show();

      this.alertsMenuOpen = !this.alertsMenuOpen;
    })
  }

  private _saveAlarm(formState, input): Observable<any> {
    let reqConfig: any = {
        alert: { 
          vsModel: !!input.isModel,
          parameterId: formState.id_field,
          "$condition": formState.comparison_field,  
          threshold: formState.element_field,
        }
      }, 
      method = 'addAlertSetting';

    if(input.isUpdate){
      reqConfig.alertId = input.id;
      method = 'editAlertSetting';
    }

    return this.apollo.request$(method, reqConfig);
  }

  private _updatePageTools({ data, params, url }){
    // reset
    this.pageTools = null;
    if(data.source === 'custom_chart'){
      this.pageTools = [{
        label: 'i18n.layouts.customized_view.create_new',
        payload: {
          source: 'create-custom-chart',
          id: params.chartId
        }
      }];
    } else if(data.source === 'dashboard'){
      this.pageTools = [{
        label: 'i18n.layouts.home.reorder',
        payload: {
          source: 'open-reorder-widgets-modal'
        }
      }];
    }
  }
}