import { Component, OnInit } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';
import { TranslateService } from '@ngx-translate/core';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { ModelSimulationsLayoutEH } from './model-simulations-layout.eh';
import { ModelSimulationsLayoutDS } from './model-simulations-layout.ds';
import { Utils, Apollo } from '@app/providers';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'model-simulations-layout',
  templateUrl: './model-simulations-layout.html'
})
export class ModelSimulationsLayoutComponent implements OnInit {
  public lb = new LayoutBuilder('model-simulations-layout');
  private widgets = [
    { id: 'model-simulations-list' },
    { id: 'pagination' },
  ];

  constructor(
    private utils: Utils,
    private translate: TranslateService,
    private apollo: Apollo,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(){
    // on ready
    this.lb.ready$.subscribe(() => {
      // and utils to make api calls
      this.lb.eventHandler.emitInner('init', {
        utils: this.utils,
        translate: this.translate,
        apollo: this.apollo,
        route: this.route,
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new ModelSimulationsLayoutDS(),
      eventHandler: new ModelSimulationsLayoutEH(),
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }
}
