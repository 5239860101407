import { EventHandler } from '@n7-frontend/core';

export class ChartActionsEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(({ type, payload }) => {
      switch(type){
        case 'chart-actions.click': 
          this.emitOuter('click', payload);
          break;

        default:
          break;
      }
    });
  }
}