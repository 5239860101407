import { Component , OnInit } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { ListAlertsLayoutDS } from './list-alerts-layout.ds';
import { ListAlertsLayoutEH } from './list-alerts-layout.eh';

import { Utils } from '@app/providers';

import { Apollo } from '@app/providers';

@Component({
  selector: 'list-alerts-layout',
  templateUrl: './list-alerts-layout.html'
})
export class ListAlertsLayoutComponent implements OnInit{
  public lb = new LayoutBuilder('list-alerts-layout');
  private widgets = [
    { id: 'list-alerts-table' },
    { id: 'pagination' },
  ];


  constructor(
    private utils: Utils,
    private translate: TranslateService,
    private apollo: Apollo,
    private route: ActivatedRoute,
  ){
  }


  ngOnInit(){
    // on ready
    this.lb.ready$.subscribe(() => {
      this.lb.eventHandler.emitInner('init', {
        utils: this.utils,
        translate: this.translate,
        apollo: this.apollo,
        route: this.route,
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new ListAlertsLayoutDS(),
      eventHandler: new ListAlertsLayoutEH(),
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }

}