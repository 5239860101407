import { LayoutDataSource } from '@n7-frontend/core';
import { map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

export class CustomizedViewLayoutDS extends LayoutDataSource {
  private apollo: any;
  private translate: any;
  protected preloadedLabels = {
    'multi': 'i18n.custom_chart.description_multi',
    'single': 'i18n.custom_chart.description_single',
    'noparams': 'i18n.custom_chart.description_noparams',
  };

  onInit(payload){
    this.apollo = payload.apollo;
    this.translate = payload.translate;
    
    // preloaded translations
    this._loadTranslatedLabels();
  }

  onElementsSave(payload): Observable<any> {
    const selectedElements = payload;
    if(selectedElements.length){
      return this.apollo.request$('createCustomChart', { 
        parameterIds: selectedElements.map(el => {
          if(el.indexOf('\\')){
            return el.replace('\\', '\\\\\\');
          } else {
            return el;
          }
        })
      });
    } else {
      return of(null);
    }
  }

  protected _loadTranslatedLabels(){
    const labelsKeys = Object.keys(this.preloadedLabels).map(e => this.preloadedLabels[e]);
    // load translations
    this.translate.get(labelsKeys)
    .pipe(
      map(labels => {
        let updatedLabels = {};
        Object.keys(this.preloadedLabels).forEach(key => {
          const i18nKey = this.preloadedLabels[key];
          updatedLabels[key] = labels[i18nKey];
        });
        return updatedLabels;
      })
    )
    .subscribe(labels => {
      // update widgets that need preloaded labels
      this.one('elements-accordion').updateOptions({ labels });
      
      // fake update
      this.one('elements-accordion').update({});
    });
  }
}
