import { DataSource } from '@n7-frontend/core';
import { Config } from '@app/constants';

export class ChartTitleDS extends DataSource {
  protected transform(data) {
    if(!data) return;

    let items = [];

    if(data.name){
      items.push({ label: data.name });
    } else {
      const elementsConfig = Config.get('elements'),
        sectionsConfig = Config.get('sections'),
        { id } = data.parameters[0],
        elementLabel = elementsConfig[id].label,
        elementSectionId = elementsConfig[id].section.id,
        sectionLabel = sectionsConfig[elementSectionId].label;

      items.push({ label: sectionLabel });
      items.push({ label: elementLabel });
    }

    return { items };
  }
}
