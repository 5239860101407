import { DataSource } from '@n7-frontend/core';
import { helpers, dateHelpers } from '@app/helpers';
import { Config } from '@app/constants';

export class ChartDS extends DataSource {
  private _chart: any = null;
  private _selectedKey: string;
  private _seriesConfig: any[] = [];
  private _elementsOrder: string[];
  private _mode: string;
  private _chartId: string;

  protected transform(data) {
    if(!data) return;

    // set chartId
    this._chartId = data.chartId;

    const chartConfig = this._getChartConfig(data);

    return {
      // to use on chart updates
      _setChart: (chart) => this._chart = chart,
      _elementId: data.chartId || 'chart',
      options: {
        chart: {
          height: 350,
          type: 'area',
          shadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: chartConfig.colors.rgb,
        fill: { 
          colors: chartConfig.colors.hex,
          gradient: {
            opacityFrom: 0.5,
            opacityTo: 0.1
          }
        },
        stroke: {
          curve: 'straight',
          width: chartConfig.strokeWidth,
        },
        series: chartConfig.series,
        grid: {
          borderColor: '#e7e7e7',
          strokeDashArray: 3,
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        markers: {
          size: 3,
          hover: {
            size: 6
          }
        },
        xaxis: {
          axisBorder: {
            show: true,
            color: '#f4f6fc'
          },
          labels: {
            formatter: (value, timestamp) => dateHelpers.toString(timestamp), 
          },
          type: 'datetime',
          tickAmount: 6,
        },
        yaxis: chartConfig.yaxis,
        legend: {
          show: false
        },
        tooltip: {
          custom: this._customTooltip.bind(this)
        },
        noData: {
          text: 'Non ci sono dati disponibili, prova a cambiare l\'intervallo di date',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: { color: '#292d2e', fontSize: '15px', fontFamily: 'Nunito Sans' }
        }
      },
    };
  }

  private _customTooltip({series, seriesIndex, dataPointIndex, w}){
    const config = this._seriesConfig,
      values = series.map(s => s[dataPointIndex]),
      hasDiff = ['MODEL', 'COMPARE'].indexOf(this.input.mode) !== -1 && values.every(v => helpers.isNumeric(v));

    let html = ['<div class="iwt-chart__tooltip">'];
    values.forEach((value, index) => {
      if(!helpers.isNumeric(value)) return;

      const selected = config[index].key === this._selectedKey,
        icon = selected ? 'n7-icon-circle-full' : 'n7-icon-circle',
        selectedClass = selected ? 'is-selected' : '';

      html.push(`
        <div class="iwt-chart__tooltip-element">
          <span class="iwt-chart__tooltip-element-icon ${icon}" style="color: ${config[index].color}"></span>
          <span class="iwt-chart__tooltip-element-label ${selectedClass}">${config[index].label}</span>
          <span class="iwt-chart__tooltip-element-value">${value}${config[index].unit}</span>
        </div>
      `);
    });

    // diff control
    if(hasDiff){
      // FIXME: modificare con la logica API definitiva per model / compare
      // firstIsCompareOrModelValue!!! wtf???
      const firstIsCompareOrModelValue = config[0].key.indexOf(this.input.mode) !== -1, 
        diffValue = firstIsCompareOrModelValue ? values[1] - values[0] : values[0] - values[1],
        diffIcon = diffValue < 0 ? 'n7-icon-caret-down' : 'n7-icon-caret-up',
        diffUnit = config[0].unit;

      html.push(`
        <div class="iwt-chart__tooltip-element is-diff">
          <span class="iwt-chart__tooltip-element-icon ${diffIcon}"></span>
          <span class="iwt-chart__tooltip-element-label">${this.options.labels.diff}</span>
          <span class="iwt-chart__tooltip-element-value">${diffValue.toFixed(2)}${diffUnit}</span>
        </div>
      `);
    }

    html.push('</div>');
    return html.join('');
  }

  private _getSeriesObj(data){
    const { values } = data;
    let seriesObj = {};

    values.forEach(value => {
      const { x, data } = value;
      data.forEach(({key, value}) => {
        seriesObj[key] = seriesObj[key] || [];
        seriesObj[key].push([x, value]);
      });
    });

    return seriesObj;
  }

  private _getChartConfig(data){
    const elementsConfig = Config.get('elements'),
      seriesObj = this._getSeriesObj(data);

    this._elementsOrder = this._elementsOrder || Object.keys(seriesObj);

    let chartConfig = {
      series: [],
      strokeWidth: [],
      yaxis: null,
      colors: { rgb: [], hex : [] },
    };

    // reset series config
    this._seriesConfig = [];

    // set selected key
    this._selectedKey = this._elementsOrder[0];

    this._elementsOrder.forEach((key, index) => {
      let config = elementsConfig[key];

      if(this._isComparison() && !config){
        config = elementsConfig[key.replace(`-${this._mode}`, '')];
      }

      const unit = config.unit,
        label = unit ? unit : config.label;

      let opacity = 1,
        colorHex = config.color;

      if(!this._isComparison() && index > 0){
        opacity = 0.3;
        colorHex = '#FFFFFF';
      }

      // colors
      chartConfig.colors.rgb.push(`rgba(${config.colorRgb.join(',')}, ${opacity})`);
      chartConfig.colors.hex.push(colorHex);

      // stroke width
      chartConfig.strokeWidth.push((index === 0) ? 2 : 1);

      // yaxis
      const yAxisConfig = {
        title: {
          text: label
        },
        axisBorder: {
          show: true,
          color: '#f4f6fc'
        },
        labels: {
          formatter: function (value) {
            if (helpers.isNumeric(value) && value % 1 !== 0) {
              return value.toFixed(2);
            }
            return value;
          }
        }
      };
      if(this._isComparison() && (index === 0)){
        chartConfig.yaxis = {
          ...yAxisConfig,
          show: true,
        };
      } else if(!this._isComparison()) {
        chartConfig.yaxis = chartConfig.yaxis || [];

        chartConfig.yaxis.push({
          ...yAxisConfig,
          show: index === 0,
        });
      }

      // series
      chartConfig.series.push({
        name: label,
        data: seriesObj[key] || []
      });

      // update series config
      this._seriesConfig.push({
        key: key,
        unit: unit,
        color: config.color,
        label: config.label
      });
    });

    return chartConfig;
  }

  public onLegendChange({ type, payload }){
    // dashboard charts control
    const { chartId, isDashboardChart } = payload[Object.keys(payload)[0]];
    if(isDashboardChart && chartId !== this._chartId) return;

    const seriesObj = this._getSeriesObj(this.input);
    let elementsOrder = [], hiddenElements = [], firstElement: string;

    // first element 
    Object.keys(payload).forEach(key => {
      const meta = payload[key];   
      if(meta.hidden){
        hiddenElements.push(key);
      } else if(meta.front){
        elementsOrder.push(key);
        firstElement = key;
      }
    });

    // the other elements
    Object.keys(seriesObj).forEach(key => {
      if(key !== firstElement && hiddenElements.indexOf(key) === -1) {
        elementsOrder.push(key);
      }
    });

    // update
    this._elementsOrder = elementsOrder;

    const chartConfig = this._getChartConfig(this.input);
    if(Array.isArray(chartConfig.series) && chartConfig.series.length){
      this._chart.updateOptions({
        series: chartConfig.series,
        colors: chartConfig.colors.rgb,
        fill: { colors: chartConfig.colors.hex },
        yaxis: chartConfig.yaxis,
        stroke: {
          curve: 'straight',
          width: chartConfig.strokeWidth,
        }
      });
    } else {
      this._chart.updateOptions({
        series: chartConfig.series
      });
    }
  }

  public onChartRequest({ type, payload }){
    if(payload.mode !== this._mode){
      this._mode = payload.mode;
      this._elementsOrder = null;
    } 

    if(!this._chart){
      this.update(payload);
    } else {
      // update input
      this.input = payload;
  
      const chartConfig = this._getChartConfig(payload);
      if(Array.isArray(chartConfig.series) && chartConfig.series.length){
        // fix container hidden
        setTimeout(() => {
          this._chart.updateOptions({
            series: chartConfig.series,
            colors: chartConfig.colors.rgb,
            fill: { colors: chartConfig.colors.hex },
            yaxis: chartConfig.yaxis,
            stroke: {
              curve: 'straight',
              width: chartConfig.strokeWidth,
            }
          });
        });
      } else {
        this._elementsOrder = null;
        this._chart.updateOptions({
          series: chartConfig.series 
        });
      }
    }
  }

  private _isComparison(){
    return ['COMPARE', 'MODEL'].indexOf(this.input.mode) !== -1
  }
}
