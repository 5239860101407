import { EventHandler } from '@n7-frontend/core';

export class TitrimetroInputDatepickerEH extends EventHandler {
  public listen() {


    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'titrimetro-input-datepicker.change':
          this.emitOuter('change', event.payload);
          break;

        default: 
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      // TODO
    });
  }
}
